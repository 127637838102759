import { createReducer } from '@reduxjs/toolkit';

import {
  getOCRRegistrationData,
  getOCRRegistrationDataSuccess,
  getOCRRegistrationDataFailure,
  updateRegistrationData,
  finishOcrStep,
} from 'src/apps/NewDriverApp/redux/actions';
import { getRegistrationDate } from 'src/apps/NewDriverApp/utils/getDate';

import { OCRReducerInterface } from '../types/Types';

export interface OcrReducer {
  loading: boolean;
  isOcrFinished: boolean;
  registrationData: OCRReducerInterface;
}

export const initialState: OcrReducer = {
  loading: false,
  isOcrFinished: false,
  registrationData: {
    driverAddress: '',
    driverCity: '',
    driverName: '',
    driverZip: '',
    driverPhone: '',
    driverEmail: '',
    carRegistrationCompany: '',
    firstRegistration: '',
    inspectionDate: '',
    licensePlate: '',
    vin: '',
    make: undefined,
    model: undefined,
  },
};

export const ocrReducer = createReducer(initialState, {
  [getOCRRegistrationData.type]: state => {
    state.loading = true;
  },
  [getOCRRegistrationDataSuccess.type]: (state, { payload }) => {
    state.isOcrFinished = false;
    state.registrationData = {
      ...state.registrationData,
      ...payload,
      firstRegistration: getRegistrationDate(payload.firstRegistration),
    };

    state.loading = false;
  },
  [getOCRRegistrationDataFailure.type]: state => {
    state.isOcrFinished = false;
    state.loading = false;
  },
  [updateRegistrationData.type]: (state, { payload }) => {
    state.registrationData = {
      ...state.registrationData,
      ...payload,
    };
  },
  [finishOcrStep.type]: state => {
    state.isOcrFinished = true;
  },
});
