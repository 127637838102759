import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { completeDamageReportSuccess } from 'src/apps/NewDriverApp/redux/actions';
import { currentProcessingReportReducer } from 'src/apps/NewDriverApp/redux/reducers/current-processing-report-reducer';
import { imagesReducer } from 'src/apps/NewDriverApp/redux/reducers/images-reducer';
import { scheduleAppointmentReducer } from 'src/apps/NewDriverApp/redux/reducers/schedule-appointment-reducer';

import { additionalInformationReducer } from './additional-information-reducer';
import { additionalServicesReducer } from './additional-services-reducer';
import { bodyTypesReducer } from './body-types-reducer';
import { carPartsReducer } from './car-parts-reducer';
import { damageReasonsReducer } from './damage-reasons-reducer';
import { damageReportCompletingStatusReducer } from './damage-report-completing-status-reducer';
import { damageReportHistoryReducer } from './damage-report-history-reducer';
import { damageTypeReducer } from './damage-type-reducer';
import { dateAndLocationReducer } from './data-and-location-reducer';
import { driverInformationReducer } from './driver-information-reducer';
import { infoReducer } from './info-reducer';
import { insuranceInformationReducer } from './insurance-information-reducer';
import { legalAgreementsReducer } from './legal-agreements-reducer';
import { ocrReducer } from './ocr-reducer';
import { otherAccidentPartyReducer } from './other-accident-party-reducer';
import { mechanicalProblemReducer } from '../../flows/fleet/mechanical/redux/reducers/mechanical-problem-reducer';

const reportReducerBase = combineReducers({
  additionalServices: additionalServicesReducer,
  images: imagesReducer,
  carParts: carPartsReducer,
  completingStatus: damageReportCompletingStatusReducer,
  damageReasons: damageReasonsReducer,
  info: infoReducer,
  ocr: ocrReducer,
  bodyTypes: bodyTypesReducer,
  additionalInformation: additionalInformationReducer,
  damageTypes: damageTypeReducer,
  driverInformation: driverInformationReducer,
  damageReportHistory: damageReportHistoryReducer,
  agreements: legalAgreementsReducer,
  scheduleAppointment: scheduleAppointmentReducer,
  mechanicalProblem: mechanicalProblemReducer,
  insuranceInformation: insuranceInformationReducer,
  dateAndLocation: dateAndLocationReducer,
  otherAccidentParty: otherAccidentPartyReducer,
  currentProcessingReport: currentProcessingReportReducer,
});

const handleResetState = (reducer: typeof reportReducerBase, resetStateAction: AnyAction) => {
  return (
    state: Parameters<typeof reportReducerBase>[0],
    action: Parameters<typeof reportReducerBase>[1],
  ) => {
    if (action.type === resetStateAction.type) {
      return reducer(undefined, action);
    }

    return reducer(state, action);
  };
};

export const reportReducer = handleResetState(reportReducerBase, completeDamageReportSuccess);

export type ReportReducer = ReturnType<typeof reportReducer>;
