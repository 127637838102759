import { createAction } from '@reduxjs/toolkit';

import {
  ChangePasswordReducerData,
  NewPasswordData,
  ResetPasswordData,
  ResetPasswordReducerData,
  UserReducer,
} from '../../../types';

export const setUserData = createAction<Partial<UserReducer>>('SET_USER_DATA');

export const setUserSelectedLanguage = createAction<boolean>('SET_USER_SELECTED_LANGUAGE');

export const setResetPasswordData =
  createAction<Partial<ResetPasswordReducerData>>('SET_RESET_PASSWORD_DATA');

export const resetEmailPassword = createAction<{ data: ResetPasswordData; captchaToken?: string }>(
  'RESET_EMAIL_PASSWORD',
);

export const setChangePasswordData = createAction<Partial<ChangePasswordReducerData>>(
  'SET_CHANGE_PASSWORD_DATA',
);

export const changeNewPassword = createAction<NewPasswordData>('CHANGE_NEW_PASSWORD');
