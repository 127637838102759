// !WARN: In order to propagate the configuration correctly, adjust .env file as well
type Config = {
  REACT_APP_BUILD_ID: string;
  REACT_APP_B2B_API_URL: string;
  REACT_APP_DEFAULT_LANG: string;
  REACT_APP_ROOT_DOMAIN: string;
  REACT_APP_RF_PATH: string;
  REACT_APP_B2B_API_URL_STAGING: string;
  REACT_APP_INSIGHTS_INSTRUMENTATION_KEY: string;
  REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING: string;
  REACT_APP_LOCIZE_VERSION: string;
  REACT_APP_LOCIZE_COMMON_PROJECT_ID: string;
  REACT_APP_LOCIZE_COMMON_API_KEY: string;
  REACT_APP_LOCIZE_MANAGER_PROJECT_ID: string;
  REACT_APP_LOCIZE_MANAGER_API_KEY: string;
  REACT_APP_LOCIZE_PRE_INSPECTION_PROJECT_ID: string;
  REACT_APP_LOCIZE_PRE_INSPECTION_API_KEY: string;
  REACT_APP_HEAP_FLEET_TRACKING_ID: string;
  REACT_APP_IS_COOKIE_BANNER_DISABLED: string;
  [key: `REACT_APP_LOCIZE_${string}_PROJECT_ID`]: string;

  // feature flags
  REACT_APP_FEATURE_FLAGS_SERVICE_PROVIDER: 'launchdarkly' | 'envflags';
  REACT_APP_LAUNCHDARKLY_CLIENT_ID: string;
  REACT_APP_ENV_FLAGS: string;

  // captcha
  REACT_APP_CAPTCHA_ENABLED: string;
  REACT_APP_CAPTCHA_SITE_KEY: string;
};

const { config = {} } = window as any;

// do not pollute global scope
delete (window as any).config;

export default (Object.freeze ? Object.freeze(config) : config) as Config;
