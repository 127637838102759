// eslint-disable no-param-reassign
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import set from 'lodash/set';

import {
  initAppSuccess as initDriverOverviewAppSuccess,
  initAppFailure as initDriverOverviewAppFailure,
  setAppConfiguration as setDriverOverviewAppConfiguration,
} from '../../../DriverOverviewApp/redux/actions';
import { SESSION_ID } from '../../constants';
import { AppConfiguration_appConfiguration } from '../../graphql/queries/__generated__/AppConfiguration';
import { ThemeOverrides } from '../../theme/ThemeInterface';
import {
  CallbackState,
  setAppConfiguration,
  setCallbackState,
  setInitParams,
  initAppFailure,
  initAppSuccess,
  initAppStart,
  displaySpinner,
  hideSpinner,
  setTheme,
} from '../actions';
import { InitState } from '../types/AppState';

const initialState: InitState = {
  initialized: false,
  theme: null,
  error: null,
  initParams: {},
  isShowSpinner: false,
  [SESSION_ID]: null,
};

export const appReducer = createReducer<InitState>(initialState, {
  [setAppConfiguration.type]: (
    state,
    action: PayloadAction<{
      configuration: AppConfiguration_appConfiguration;
      initParams?: Record<string, unknown>;
    }>,
  ) => {
    const config = action.payload.configuration;

    Object.assign(state, {
      configuration: config,
    });
  },
  [setTheme.type]: (state, action: PayloadAction<ThemeOverrides>) => {
    const theme = action.payload;

    Object.assign(state, {
      theme,
    });
  },
  [initAppSuccess.type]: state => {
    Object.assign(state, {
      initialized: true,
      error: null,
    });
  },
  [initAppStart.type]: state => {
    Object.assign(state, {
      initialized: false,
      error: null,
    });
  },
  [initAppFailure.type]: (state, action) => {
    Object.assign(state, {
      initialized: true,
      error: action.payload?.error,
    });
  },
  [setInitParams.type]: (state, action: PayloadAction<Record<string, unknown>>) => {
    set(state, 'initParams', action.payload);
  },
  [setCallbackState.type]: (state, action: PayloadAction<CallbackState>) => {
    const callback = (state.initParams?.callbackUrl as string) || action.payload.url;
    if (!callback) {
      return;
    }

    const cbUrl = new URL(callback);
    cbUrl.search = String(
      new URLSearchParams([
        ...Array.from(cbUrl.searchParams.entries()),
        ...Object.entries(action.payload),
      ]),
    );

    set(state, 'initParams.callbackUrl', String(cbUrl));
  },
  [setDriverOverviewAppConfiguration.type]: (
    state,
    action: PayloadAction<{
      configuration: AppConfiguration_appConfiguration;
      initParams?: Record<string, unknown>;
    }>,
  ) => {
    const config = action.payload.configuration;

    Object.assign(state, {
      configuration: config,
      initParams: action.payload.initParams,
    });
  },

  [initDriverOverviewAppSuccess.type]: state => {
    Object.assign(state, {
      initialized: true,
      error: null,
    });
  },
  [initDriverOverviewAppFailure.type]: (state, action) => {
    Object.assign(state, {
      initialized: true,
      error: action.payload?.error,
    });
  },
  [displaySpinner.type]: state => {
    state.isShowSpinner = true;
  },
  [hideSpinner.type]: state => {
    state.isShowSpinner = false;
  },
});
