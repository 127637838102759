import { SESSION_ID } from '../../constants';
import { AppConfiguration_appConfiguration } from '../../graphql/queries/__generated__/AppConfiguration';
import { ThemeOverrides } from '../../theme/ThemeInterface';
import { CarPartsReducer } from '../reducers/car-parts-reducer';

interface PhotoForUpload {
  data: string;
  type: string;
}

interface ReportState {
  carConditionPhotos: PhotoForUpload[];
  carParts: CarPartsReducer;
  damagePhotos: PhotoForUpload[];
}

export interface InitState {
  theme: ThemeOverrides | null;
  initialized: boolean;
  isShowSpinner: boolean;
  [SESSION_ID]: number | null;
  initParams?: Record<string, unknown>;
  configuration?: AppConfiguration_appConfiguration;
  error?: null | Error;
}

export interface AppState {
  init: InitState;
  report: ReportState;
}

export enum AppMode {
  EMBEDDED = 'embedded',
  STANDALONE = 'standalone',
}

export enum AppEmbeddedEvents {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  LANGUAGE_CHANGED = 'LANGUAGE_CHANGED',
  REPORT_STATE_CHANGED = 'REPORT_STATE_CHANGED',
}
