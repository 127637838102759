import React from 'react';

interface Props {
  className?: string;
}

const CrossOutEye: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3L6.64811 6.64811M21 21L17.3519 17.3519M17.3519 17.3519C15.7249 18.4507 13.941 19 12 19C8 19 4.667 16.667 2 12C3.37285 9.59764 4.92218 7.81372 6.64811 6.64811M17.3519 17.3519L13.4142 13.4142M13.4142 13.4142C13.0523 13.7761 12.5523 14 12 14C10.8954 14 10 13.1046 10 12C10 11.4477 10.2239 10.9477 10.5858 10.5858M13.4142 13.4142L10.5858 10.5858M10.5858 10.5858L6.64811 6.64811M19.915 15.0157C20.6501 14.1511 21.3451 13.1459 22 12C19.333 7.333 16 5 12 5C11.3748 5 10.7659 5.057 10.1732 5.171"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossOutEye;
