export const CAR_IDENTIFICATION_ROUTES = {
  TYPE: 'type',
  VIN_INPUT: 'vin',
  INPUT: 'input',
  OCR: 'ocr',
  CAMERA_RESTRICTED: 'camera-restricted',
  RESULT: 'result',
  ERROR: 'error',
  UNKNOWN_ERROR: 'unknown-error',
};

export const OCR_QUERY_MODE_KEY = 'ocrMode';
export const RECOGNIZED_VALUE_KEY = 'q';
export const NOT_FOUND_ERROR_KEY = 'notFound';

export const IDENTIFICATION_METHOD = {
  LICENSE_PLATE: 'licensePlate',
  VIN: 'vin',
} as const;
