import { createAction } from '@reduxjs/toolkit';

import { AppConfiguration_appConfiguration } from 'src/apps/NewDriverApp/graphql/queries/__generated__/AppConfiguration';

export type AppConfiguration = {
  configuration: AppConfiguration_appConfiguration;
  initParams?: Record<string, string>;
};

export const initApp = createAction('DRIVER_OVERVIEW_APP_INIT');

export const setAppConfiguration = createAction<AppConfiguration>(
  'DRIVER_OVERVIEW_SET_APP_CONFIGURATION',
);

export const initAppSuccess = createAction('DRIVER_OVERVIEW_APP_INIT_SUCCESS');
export const initAppFailure = createAction<{ error: Error }>('DRIVER_OVERVIEW_APP_INIT_FAILURE');
