import isMatch from 'date-fns/isMatch';
import parseISO from 'date-fns/parseISO';

import formatDate from './formatDate';

const REGISTRATION_DATE_PATTERN = 'dd.MM.yyyy';
const ISO_REGISTRATION_DATE_PATTERN = 'yyyy-MM-dd';

export const getRegistrationDate = (date: string | undefined) => {
  if (typeof date === 'string' && isMatch(date, ISO_REGISTRATION_DATE_PATTERN)) {
    return formatDate(parseISO(date), REGISTRATION_DATE_PATTERN);
  }

  return date;
};
