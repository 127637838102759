import { createAction } from '@reduxjs/toolkit';

import { CarPartType } from 'src/api/globalTypes';
import { ImageState } from 'src/apps/NewDriverApp/redux/types/Types';
import { DamagePhotosCameraContext } from 'src/apps/NewDriverApp/types';

import {
  DamageCategoryTypes,
  InteriorDriverInformationData,
  InteriorReportReducer,
  InteriorSavingStatus,
} from '../types';

// Start interior flow

export const startInteriorDamageFlow = createAction<{ damageCategory: DamageCategoryTypes }>(
  'START_INTERIOR_DAMAGE_FLOW',
);

// Interior report

export const updateInteriorReportData = createAction<Partial<InteriorReportReducer>>(
  'UPDATE_INTERIOR_REPORT_DATA',
);

export const updateInteriorReportSelectedLocation = createAction<Partial<CarPartType[]>>(
  'UPDATE_INTERIOR_REPORT_SELECTED_LOCATION',
);

export const setInteriorDriverInformationData = createAction<
  Partial<InteriorDriverInformationData>
>('SET_INTERIOR_DRIVER_INFORMATION_DATA');

export const resetInteriorReportData = createAction('RESET_INTERIOR_REPORT_DATA');

// Interior overview page
export const interiorOverviewPageCompleted = createAction('INTERIOR_OVERVIEW_PAGE_COMPLETED');

// Interior saving status
export const updateInteriorReportSavingStatus = createAction<{
  data: Partial<InteriorSavingStatus>;
}>('UPDATE_INTERIOR_REPORT_SAVING_STATUS');

// Update interior image state

export const updateInteriorImageState = createAction<{
  internalId: string;
  valuesToUpdate: Partial<ImageState<DamagePhotosCameraContext>>;
}>('UPDATE_INTERIOR_IMAGE_STATE');

export const updateInteriorImagesState = createAction<{
  images: ImageState<DamagePhotosCameraContext>[];
}>('UPDATE_INTERIOR_IMAGES_STATE');

export const removeInteriorImage = createAction<{ internalId: string }>('REMOVE_INTERIOR_IMAGE');
