import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { GetDamageReportHistory_damageReports } from 'src/apps/NewDriverApp/graphql/queries/__generated__/GetDamageReportHistory';

import { UpdateChecklistImageActionPayload } from '../../flows/fleet/checklist/redux/types';
import * as Actions from '../actions';
import { HistoryReducer } from '../types/Types';

const initialState: HistoryReducer = {
  loading: false,
  error: null,
  loaded: false,
  damageReports: [],
};

export const damageReportHistoryReducer = createReducer(initialState, {
  [Actions.fetchDamageReportHistory.type]: state => {
    state.loading = true;
    state.error = null;
    state.loaded = false;
  },
  [Actions.fetchDamageReportHistorySuccess.type]: (
    state,
    action: PayloadAction<{ damageReports: GetDamageReportHistory_damageReports[] }>,
  ) => {
    state.loading = false;
    state.error = null;
    state.loaded = true;
    state.damageReports = action.payload.damageReports;
  },
  [Actions.fetchDamageReportHistoryFailure.type]: (
    state,
    action: PayloadAction<{ error: Error }>,
  ) => {
    state.loading = false;
    state.loaded = false;
    state.damageReports = [];
    state.error = action.payload.error;
  },
  [Actions.addDamageReportToHistory.type]: (
    state,
    action: PayloadAction<GetDamageReportHistory_damageReports>,
  ) => {
    state.damageReports.push(action.payload);
  },
  [Actions.updateDamageReportInHistory.type]: (
    state,
    action: PayloadAction<GetDamageReportHistory_damageReports>,
  ) => {
    state.damageReports.map(damage => {
      if (damage.id === action.payload.id) {
        return action.payload;
      }

      return damage;
    });
  },
  [Actions.updateHistoryDamageReportImage.type]: (
    state,
    action: PayloadAction<{ damageReportId: string; data: UpdateChecklistImageActionPayload }>,
  ) => {
    const { damageReportId, data } = action.payload;
    const damageReportIndex = state.damageReports.findIndex(damage => damage.id === damageReportId);

    if (damageReportIndex === -1) {
      return;
    }

    const damageReport = state.damageReports[damageReportIndex];
    const damageReportImages = [...(damageReport.images || [])];
    const updateImageIndex = damageReportImages.findIndex(
      image => image.internalId === data.internalId,
    );

    if (updateImageIndex === -1) {
      return;
    }

    const mappedData = {
      id: data.id as string,
      fullUrl: data.fullUrl as string,
      type: get(data, 'context.imageType'),
    };

    damageReportImages[updateImageIndex] = {
      ...damageReportImages[updateImageIndex],
      ...mappedData,
    };

    const updatedDamageReport = {
      ...damageReport,
      images: damageReportImages,
    };

    const updatedReports = [...state.damageReports];
    updatedReports[damageReportIndex] = updatedDamageReport;

    state.damageReports = updatedReports;
  },
  [Actions.deleteDamageReportFromHistory.type]: (
    state,
    action: PayloadAction<{ damageReportId: string }>,
  ) => {
    state.damageReports = state.damageReports.filter(
      damage => damage.id !== action.payload.damageReportId,
    );
  },
});
