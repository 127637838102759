import { PayloadAction } from '@reduxjs/toolkit';
import { Location } from 'history';
import { parse, stringify } from 'qs';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import { closeCamera, openCamera } from 'src/apps/NewDriverApp/ui-components/Camera/redux/actions';
import { CameraReducerState } from 'src/apps/NewDriverApp/ui-components/Camera/redux/camera-reducer';
import { cameraSelector } from 'src/apps/NewDriverApp/ui-components/Camera/redux/selectors';
import { history } from 'src/services/history';

import { routeChanged } from '../../actions';
import { RouteChanged } from '../../types/Types';

export const OPEN_CAMERA_SEARCH_QUERY = 'cameraOpen=true';

const hasLocationCameraSearchQuery = (location: Location) => {
  return location.search.includes(OPEN_CAMERA_SEARCH_QUERY);
};

const isHasBeenNavigatedBackWithOpenedCamera = (
  action: PayloadAction<RouteChanged>,
  isCameraOpen: boolean,
) => {
  const { currentLocation, previousLocation } = action.payload;
  const wasCameraOpenedOnPreviousHistoryStep =
    !!previousLocation && hasLocationCameraSearchQuery(previousLocation);
  const isCameraOpenInSearchQuery = hasLocationCameraSearchQuery(currentLocation);
  return wasCameraOpenedOnPreviousHistoryStep && !isCameraOpenInSearchQuery && isCameraOpen;
};

function* openCameraChangeHistorySaga() {
  yield call(() => {
    const parsedSearchParams = parse(history.location.search, { ignoreQueryPrefix: true });
    const updatedSearchParams = stringify({ ...parsedSearchParams, cameraOpen: true });

    history.push(
      {
        pathname: history.location.pathname,
        search: updatedSearchParams,
      },
      history.location.state,
    );
  });
}

function* closeCameraBackNavigateSaga(action: PayloadAction<boolean | undefined>) {
  const isCameraClosingInitiatedByNavigationBackSaga = action.payload;

  const isCameraOpenInSearchQuery = hasLocationCameraSearchQuery({
    ...history.location,
    state: null,
  });

  yield call(() => {
    if (!isCameraClosingInitiatedByNavigationBackSaga && isCameraOpenInSearchQuery) {
      history.back();
    }
  });
}

function* closeCameraSaga(action: PayloadAction<RouteChanged>) {
  const { isCameraOpened }: CameraReducerState = yield select(cameraSelector);
  const isHasBeenNavigatedBack = isHasBeenNavigatedBackWithOpenedCamera(
    action,
    Boolean(isCameraOpened),
  );

  if (isHasBeenNavigatedBack) {
    yield put(closeCamera(true));
  }
}

function cleanOpenCameraSearchHistory() {
  const hasOpenCameraSearchHistory = hasLocationCameraSearchQuery(history.location);

  if (hasOpenCameraSearchHistory) {
    const parsedSearchParams = parse(history.location.search, { ignoreQueryPrefix: true });
    delete parsedSearchParams.cameraOpen;

    const updatedSearchParams = stringify(parsedSearchParams);

    history.replace(
      {
        pathname: history.location.pathname,
        search: updatedSearchParams,
      },
      history.location.state,
    );
  }
}

export function* cameraBackNavigationHandlerSaga() {
  yield call(cleanOpenCameraSearchHistory);

  yield all([
    takeEvery(openCamera.type, openCameraChangeHistorySaga),
    takeEvery(routeChanged.type, closeCameraSaga),
    takeEvery(closeCamera.type, closeCameraBackNavigateSaga),
  ]);
}
