import { ROUTE_UPDATE } from 'src/apps/NewDriverApp/pages/DamageReportFlow/constants';

export const CHECKLIST_CODE_STORAGE_KEY = 'new_app_checklist_code';

export const CHECKLIST_TEMPLATE_ID_ROUTE_PLACEHOLDER = ':checklistTemplateId';
export const CHECKLIST_FLOW_DOMAIN = 'checklist';
export const CHECK_TYPE_PLACEHOLDER = ':checkType';
const CHECKLIST_TEMPLATE_ROUTE_TEMPLATE = `${CHECKLIST_FLOW_DOMAIN}/${CHECKLIST_TEMPLATE_ID_ROUTE_PLACEHOLDER}`;
const CHECKLIST_DAMAGE_FLOW_DOMAIN = 'damage';
export const CHECKLIST_DAMAGE_HISTROY_DOMAIN = 'history';
export const CHECKLIST_DAMAGE_ID_ROUTE_PLACEHOLDER = ':checklistDamageTemplateId';
export const CHECKLIST_DAMAGE_STEP_ROUTE_PLACEHOLDER = ':step';
export const CHECKLIST_DAMAGE_TEMPLATE_ROUTE_TEMPLATE = `${CHECKLIST_DAMAGE_FLOW_DOMAIN}/${CHECKLIST_DAMAGE_ID_ROUTE_PLACEHOLDER}/${CHECKLIST_DAMAGE_STEP_ROUTE_PLACEHOLDER}`;
export const CHECKLIST_DAMAGE_CAR_AREA = ':areaName';
export const CHECKLIST_CAR_PART_TYPE = ':carPartType';

export const CHECK_PAGE_ROUTE_NAMES = {
  // SHARED
  COMPLETED: 'completed',
  OVERVIEW: 'overview',
  CAR_PARTS_OVERVIEW: 'car-parts-overview',
};

export const ROUTES = {
  CHECKLIST_CHECK_COMMON: `${CHECKLIST_TEMPLATE_ROUTE_TEMPLATE}/${CHECK_TYPE_PLACEHOLDER}`,
  CHECKLIST_CHECK_DAMAGE: `${CHECKLIST_TEMPLATE_ROUTE_TEMPLATE}/${CHECK_TYPE_PLACEHOLDER}/${CHECKLIST_DAMAGE_TEMPLATE_ROUTE_TEMPLATE}/${CHECKLIST_DAMAGE_CAR_AREA}`,
  CHECKLIST_CHECK_DAMAGE_HISTORY: `${CHECKLIST_DAMAGE_HISTROY_DOMAIN}/${CHECKLIST_CAR_PART_TYPE}`,
  CHECKLIST_DAMAGE_REPORT_FLOW_NEW_DAMAGE: `${CHECKLIST_TEMPLATE_ROUTE_TEMPLATE}/${CHECK_TYPE_PLACEHOLDER}/${ROUTE_UPDATE}`,
  CHECKLIST_COMPLETED: `${CHECKLIST_FLOW_DOMAIN}/${CHECK_PAGE_ROUTE_NAMES.COMPLETED}`,
};
