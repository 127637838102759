import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setAssetsData } from '../actions';
import { AssetsReducer } from '../types/Types';

const initialState: AssetsReducer = {
  isLoading: false,
  isContentLoading: false,
  isContentLoadingFailed: false,
};

export const assetsReducer = createReducer<AssetsReducer>(initialState, {
  [setAssetsData.type]: (state, action: PayloadAction<AssetsReducer>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});
