import { ManipulatedResourceName } from 'src/apps/NewDriverApp/hooks/useExtendedPermissions/types';

const mapExtendedPermissionsResourceToKeys: Record<string, ManipulatedResourceName> = {
  'damage-reports': 'damageReports',
  'damage-status': 'damageStatus',
  'damage-image': 'damageImage',
  'damage-image-visibility': 'damagePhotoVisibility',
  'damage-manager-comment': 'damageFleetComment',
  'damage-severity': 'damageMinority',
};

export const resolveKeyByExtendedPermissionsResource = (
  resource?: string,
): ManipulatedResourceName | false =>
  mapExtendedPermissionsResourceToKeys[
    resource as keyof typeof mapExtendedPermissionsResourceToKeys
  ] ?? false;
