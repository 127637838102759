import { createReducer } from '@reduxjs/toolkit';

import {
  getDamageReportInfo,
  getDamageReportInfoFailure,
  getDamageReportInfoSuccess,
  setDamageReportInfoLoaded,
} from '../actions';
import { InfoReducer } from '../types/Types';

export const initialInfoState: InfoReducer = {
  loading: false,
  loaded: false,
  error: null,
};

export const infoReducer = createReducer(initialInfoState, {
  [getDamageReportInfo.type]: state => {
    return {
      ...state,
      loading: true,
      loaded: false,
      error: null,
    };
  },
  [getDamageReportInfoSuccess.type]: (state, { payload }) => {
    return {
      ...state,
      // TODO map action payload to a Type
      ...payload.info,
      // TODO refactor after getting steps info from config
      // reportStepsAmount: payload.info.additionalServices.length ? 7 : 6,
      loading: false,
      loaded: true,
      error: null,
    };
  },
  [getDamageReportInfoFailure.type]: (state, { payload }) => {
    return {
      ...state,
      error: payload.error,
      loading: false,
      loaded: false,
    };
  },
  [setDamageReportInfoLoaded.type]: (state, { payload }) => {
    state.loaded = payload;
  },
});
