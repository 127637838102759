import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import * as actions from '../actions';
import { ImageState, ImageUploadStatus } from '../types/Types';

const initialState: ImageState[] = [];

export const imagesReducer = createReducer(initialState, {
  [actions.initImages.type]: (_state, action) => {
    return action.payload || [];
  },
  [actions.addImage.type]: (state, action) => {
    return [...state, action.payload];
  },
  [actions.removeImage.type]: (state, action: PayloadAction<{ internalId: string }>) => {
    return state.filter(image => image.internalId !== action.payload.internalId);
  },
  [actions.imageUploadStart.type]: (state, action: PayloadAction<{ internalId: string }>) => {
    const imageIndex = state.findIndex(item => item.internalId === action.payload.internalId);

    if (imageIndex !== -1) {
      state[imageIndex].uploadingStatus = ImageUploadStatus.STARTED;
    }
  },
  [actions.imageUploadProgressUpdate.type]: (
    state,
    action: PayloadAction<{ internalId: string; uploadProgress: number }>,
  ) => {
    const imageIndex = state.findIndex(item => item.internalId === action.payload.internalId);

    if (imageIndex !== -1) {
      state[imageIndex].uploadProgress = action.payload.uploadProgress;
    }
  },
  [actions.imageUploadSuccess.type]: (
    state,
    action: PayloadAction<{
      internalId: string;
      imageUrl: string;
      id: string;
      fullUrl: string;
      createdAt?: string;
      isDeletable?: boolean;
    }>,
  ) => {
    const imageIndex = state.findIndex(item => item.internalId === action.payload.internalId);

    if (imageIndex !== -1) {
      state[imageIndex] = {
        ...state[imageIndex],
        uploadingStatus: ImageUploadStatus.SUCCESS,
        id: action.payload.id,
        fullUrl: action.payload.imageUrl || action.payload.fullUrl,
        createdAt: action.payload.createdAt,
        isDeletable: action.payload.isDeletable,
      };
    }
  },
  [actions.imageUploadFailure.type]: (
    state,
    action: PayloadAction<{ internalId: string; error: Error }>,
  ) => {
    const imageIndex = state.findIndex(item => item.internalId === action.payload.internalId);

    if (imageIndex !== -1) {
      state[imageIndex].uploadingStatus = ImageUploadStatus.FAILURE;
      state[imageIndex].error = action.payload.error;
    }
  },
  [actions.imageVisibilityUpdate.type]: (
    state,
    action: PayloadAction<{ internalId: string; isVisibilityRestricted: boolean }>,
  ) => {
    const imageIndex = state.findIndex(item => item.internalId === action.payload.internalId);

    if (imageIndex !== -1) {
      state[imageIndex].isVisibilityRestricted = action.payload.isVisibilityRestricted;
    }
  },
});
