import React from 'react';

import styles from './InfiniteLoader.module.scss';

const InfiniteLoader: React.FC = () => {
  return (
    <div className={styles.loadWrapper}>
      <div className={styles.activity} />
    </div>
  );
};

export default InfiniteLoader;
