import { useEffect, useRef } from 'react';

import { Location } from 'history';
import { useDispatch } from 'react-redux';

import { history } from 'src/services/history';

import { routeChanged } from '../redux/actions';

const useRouteListener = () => {
  const dispatch = useDispatch();
  const prevLocation = useRef<Location | null>(null);

  useEffect(() => {
    history.listen(updateData => {
      dispatch(
        routeChanged({
          currentLocation: updateData.location,
          previousLocation: prevLocation.current,
        }),
      );
      prevLocation.current = updateData.location;
    });
  }, []);
};

export { useRouteListener };
