import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { AcceptLegalAgreement, LegalAgreementType } from 'src/api/globalTypes';
import {
  saveLegalAgreement,
  setInitialAgreements,
  setDriverCameraConsent,
} from 'src/apps/NewDriverApp/redux/actions';

import { DriverCameraAccessConsent } from '../types/Types';

export interface LegalAgreementsReducer {
  entity: AcceptLegalAgreement[];
  driverCameraAccessConsent: DriverCameraAccessConsent;
}

const initialState: LegalAgreementsReducer = {
  entity: [
    {
      type: LegalAgreementType.CANCELLATION_POLICY,
      isAccepted: false,
    },
    {
      type: LegalAgreementType.SERVICE_AGREEMENT,
      isAccepted: false,
    },
    {
      type: LegalAgreementType.TERMS_AND_CONDITIONS,
      isAccepted: false,
    },
  ],
  driverCameraAccessConsent: {},
};

export const legalAgreementsReducer = createReducer(initialState, {
  [setInitialAgreements.type]: (state, { payload }: PayloadAction<AcceptLegalAgreement[]>) => {
    return {
      ...state,
      entity: payload,
    };
  },
  [saveLegalAgreement.type]: (state, { payload }) => {
    const key = Object.keys(payload)[0];

    const entity = state.entity.map(e => {
      if (e.type === key) {
        e.isAccepted = payload[key];
      }

      return e;
    });
    state = { ...state, entity };
  },
  [setDriverCameraConsent.type]: (state, { payload }) => {
    return {
      ...state,
      driverCameraAccessConsent: {
        ...state.driverCameraAccessConsent,
        [payload.id]: {
          ...(state.driverCameraAccessConsent[payload.id] || {}),
          ...payload.data,
        },
      },
    };
  },
});
