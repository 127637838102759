import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { updateAccidentDriverInformationData, setAccidentDriverInformationData } from '../actions';
import { AccidentDriverInformationReducer } from '../types';

const initialState: AccidentDriverInformationReducer = {
  driverName: '',
  driverEmail: '',
  driverPhone: '',
  driverAddress: undefined,
  driverCity: undefined,
  driverZip: undefined,
  clientNumber: undefined,
  personalNumber: undefined,
  companyAffiliation: undefined,
};

export const accidentDriverInformationReducer = createReducer(initialState, {
  [updateAccidentDriverInformationData.type]: (_, { payload }) => {
    return payload.data;
  },
  [setAccidentDriverInformationData.type]: (
    state,
    { payload }: PayloadAction<Partial<AccidentDriverInformationReducer>>,
  ) => {
    return { ...state, ...payload };
  },
});
