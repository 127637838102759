import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Siema from 'siema';

import { ReactComponent as CalendarIcon } from 'src/apps/NewDriverApp/assets/icons/px-calendar-pic.svg';
import { useScreenResize } from 'src/apps/NewDriverApp/hooks';
import Text from 'src/apps/NewDriverApp/ui-components/Text';
import { ReactComponent as PxCloseLargeIcon } from 'src/img/other-icons/px-close-large.svg';

import styles from './ReportedDamageImagesModal.module.scss';
import { SlideItem } from './types';
import { SlideButton, DamageImageSlider } from '..';
import { SlideButtonType } from '../SlideButton/SlideButton';

interface Props {
  isOpen: boolean;
  index: number;
  slides: SlideItem[];
  onClose: () => void;
  createdDate?: string;
  damageCategoryNames?: string;
  isWithDescription?: boolean;
}

const ReportedDamageImagesModal: React.FC<Props> = ({
  isOpen,
  index,
  slides,
  onClose,
  createdDate,
  damageCategoryNames,
  isWithDescription = true,
}) => {
  const { t } = useTranslation('reportedDamage');
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [imageSliderKey, setImageSliderKey] = useState<number>(Date.now());
  const siemaRef = useRef<Siema>(null);
  const isRightButtonDisabled = slides.length - 1 === currentIndex;
  const isLeftButtonDisabled = currentIndex === 0;

  const imageDescription = t(
    `imageTypeDescriptions.${slides[currentIndex].type?.toLocaleLowerCase()}`,
  );

  const isDisplayFooter = damageCategoryNames || createdDate;

  const setNewKey = () => {
    setImageSliderKey(Date.now());
  };

  useScreenResize(setNewKey);

  useEffect(() => {
    setCurrentIndex(index);
  }, [index]);

  const nextSlide = useCallback(() => {
    if (siemaRef.current) {
      siemaRef.current.next();
    }
  }, []);

  const prevSlide = useCallback(() => {
    if (siemaRef.current) {
      siemaRef.current.prev();
    }
  }, []);

  return (
    <Modal isOpen={isOpen} overlayClassName={styles.modalOverlay} className={styles.modalContent}>
      <div className={styles.sliderContainer}>
        <div className={styles.sliderAligner}>
          <DamageImageSlider
            // the key is needed for appropriate slider reinitialization after the screen width was changed
            key={imageSliderKey}
            slides={slides}
            ref={siemaRef}
            index={index}
            onChange={setCurrentIndex}
          />
        </div>
      </div>
      <button className={styles.closeIcon} onClick={onClose}>
        <PxCloseLargeIcon />
      </button>
      {!!slides.length && (
        <>
          <SlideButton
            disabled={isLeftButtonDisabled}
            className={styles.buttonLeft}
            onClick={prevSlide}
          />
          <SlideButton
            type={SlideButtonType.RIGHT}
            className={styles.buttonRight}
            onClick={nextSlide}
            disabled={isRightButtonDisabled}
          />
        </>
      )}
      {isWithDescription && (
        <figcaption className={styles.imageDescription}>
          <Text
            type="body-middle"
            color="primary-background-base"
          >{`${currentIndex + 1}/${slides.length}`}</Text>
          <div className={styles.divider} />
          <Text type="body-middle" color="primary-background-base">
            {imageDescription}{' '}
            <Text type="body-middle" color="notification-error">
              *
            </Text>
          </Text>
        </figcaption>
      )}
      {isDisplayFooter && (
        <div className={styles.footerDescriptionContainer}>
          <div className={styles.dateRow}>
            {damageCategoryNames && <Text color="secondary-content">{t('damageType')}</Text>}
            {createdDate && (
              <div className={styles.dateContainer}>
                <CalendarIcon className={styles.calendarIcon} />
                <Text type="body-small" color="secondary-content">
                  {createdDate}
                </Text>
              </div>
            )}
          </div>
          {damageCategoryNames && (
            <Text color="primary-background-base">{damageCategoryNames}</Text>
          )}
        </div>
      )}
    </Modal>
  );
};

export default React.memo(ReportedDamageImagesModal);
