import React from 'react';

import cx from 'classnames';

import Text from 'src/apps/NewDriverApp/ui-components/Text';
import { ReactComponent as PxConfirmIcon } from 'src/img/other-icons/px-confirm.svg';
import { ReactComponent as LangIcon } from 'src/img/other-icons/px-language.svg';

import styles from './LanguageSwitcher.module.scss';
import Spinner from '../Spinner';

interface Props {
  name: string;
  isChecked: boolean;
  FlagIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onClick: () => void;
  className?: string;
  isLoading?: boolean;
}

const LanguageOptionItem: React.FC<Props> = ({
  name,
  isChecked,
  onClick,
  FlagIcon = LangIcon,
  className,
  isLoading,
}) => {
  return (
    <div className={cx(styles.listGroupItem, className)}>
      <button type="button" className={styles.listGroupItemAction} onClick={onClick}>
        <div className={styles.listGroupItemBody}>
          {FlagIcon && <FlagIcon width={24} height={24} />}
          <Text>{name}</Text>
        </div>
        {isChecked && (
          <div className={styles.isChecked}>
            <PxConfirmIcon width={14} height={12} />
          </div>
        )}
        {isLoading && <Spinner width="1px" style={{ height: '24px', width: '24px' }} />}
      </button>
    </div>
  );
};

export { LanguageOptionItem };
