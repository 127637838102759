import { createReducer } from '@reduxjs/toolkit';

import { updateInsuranceInformationData } from 'src/apps/NewDriverApp/redux/actions';
import { InsuranceInformationReducer } from 'src/apps/NewDriverApp/redux/types/Types';

const initialState: InsuranceInformationReducer = {
  insuranceCompany: '',
  policyNumber: '',
};

export const insuranceInformationReducer = createReducer(initialState, {
  [updateInsuranceInformationData.type]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
});
