import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppSegmentType } from 'src/api/globalTypes';
import { goCustomerSite, reportNewDamage } from 'src/apps/NewDriverApp/redux/actions';

import { goToCarFleetManagerMenu } from '../flows/fleet/fleet-manager/redux/actions';
import { appInitParams, getCarKey, getFlowKey, getManagerCode } from '../redux/selectors';

const useCompleteFlowFleet = (namespace = 'completed') => {
  const { t } = useTranslation(namespace);

  const { callbackUrl } = useSelector(appInitParams) || {};
  const manager_token = useSelector(getManagerCode);
  const flow = useSelector(getFlowKey);

  const continueLocaleStringKey = `${AppSegmentType.FLEETS}.buttonContinue`;
  const backToMenuLocaleStringKey = `${AppSegmentType.FLEETS}.buttonBackToMenu`;
  const carAuth = useSelector(getCarKey);

  let completeAction;
  if (manager_token) {
    completeAction = goToCarFleetManagerMenu;
  } else if (callbackUrl) {
    completeAction = goCustomerSite;
  } else {
    completeAction = reportNewDamage;
  }

  return {
    completeAction,
    isCompleteActionAvailable: flow || !carAuth ? Boolean(callbackUrl) : true,
    actionName: callbackUrl ? t(continueLocaleStringKey) : t(backToMenuLocaleStringKey),
  };
};

export default useCompleteFlowFleet;
