import React from 'react';

const lazyWithPreload = (
  factory: () => Promise<{
    default: React.ComponentType<any>;
  }>,
) => {
  const Component = React.lazy(factory);
  Object.assign(Component, { preload: factory });
  return Component;
};

export default lazyWithPreload;
