import { createAction } from '@reduxjs/toolkit';

import { CarPartType, UpdateChecklistReportInput, DamageCategoryType } from 'src/api/globalTypes';
import { ChecklistReport } from 'src/apps/NewDriverApp/graphql/fragments/__generated__/ChecklistReport';
import { ImageState } from 'src/apps/NewDriverApp/redux/types/Types';

import {
  ChecklistReportReducer,
  ChecklistDamageReport,
  ChecklistReportedDamages,
  CheckListImageState,
  ChecklistCheckType,
  ChecklistOverviewCompleteAction,
  UpdateChecklistImageActionPayload,
} from './types';
import { CheckConfiguration } from '../../../../types';

// CHECKLIST FLOW
export const checklistStepCompleted = createAction<
  | {
      shouldSendReportUpdateRequest?: boolean;
    }
  | undefined
>('CHECKLIST_STEP_COMPETED');

// CHECKLIST TEMPLATE DATA
export const checklistReportStart = createAction('CHECKLIST_REPORT_START');

export const checklistReportSuccess = createAction<{
  data?: ChecklistReportReducer;
}>('CHECKLIST_REPORT_SUCCESS');

export const checklistReportFailure = createAction('CHECKLIST_REPORT_FAILURE');

export const setChecklistTemplateId = createAction<{ checklistTemplateId: string }>(
  'SET_CHECKLIST_TEMPLATE_ID',
);

export const setChecklistTemplateChecks = createAction<{ checks: CheckConfiguration[] }>(
  'SET_CHECKLIST_TEMPLATE_CHECKS',
);

// CHECKLIST IMAGE UPLOAD
export const setChecklistPhoto = createAction<CheckListImageState>('SET_CHECKLIST_PHOTO');

export const startChecklistImageUpload = createAction<CheckListImageState>(
  'START_CHECKLIST_IMAGE_UPLOAD',
);

export const checklistImageUploadSuccess = createAction<{
  internalId: string;
  id: string;
  fullUrl: string;
  context: CheckListImageState['context'];
}>('CHECKLIST_IMAGE_UPLOAD_SUCCESS');
export const checklistImageUploadFailure = createAction<{
  internalId: string;
  error: Error;
  context: CheckListImageState['context'];
}>('CHECKLIST_IMAGE_UPLOAD_FAILURE');
export const checklistImageUploadProgressUpdate = createAction<{
  internalId: string;
  uploadProgress: number;
}>('CHECKLIST_IMAGE_UPLOAD_PROGRESS_UPDATE');

export const setInitialChecklistImages = createAction<{
  images: CheckListImageState[];
}>('SET_INITIAL_CHECKLIST_IMAGES');

export const checklistImageInputSelect = createAction<{ selectedPhoto: CheckListImageState }>(
  'CHECKLIST_IMAGE_INPUT_SELECT',
);

// SAVE CHECKLIST DATA
export const checklistReportUpdate =
  createAction<UpdateChecklistReportInput>('CHECKLIST_REPORT_UPDATE');

// SAVING WARNINGS
export const checklistReportWarningsUpdate = createAction<{
  warnings: ChecklistReport['warnings'];
}>('CHECKLIST_REPORT_WARNINGS_UPDATE');

// UPDATE isCheckedEngine
export const setChecklistReportIsCheckedEngine = createAction<boolean>(
  'SET_CHECKLIST_REPORT_IS_CHECKED_ENGINE',
);

// Set modals that were shown
export const setChecklistReportModalShown = createAction<{ name: string; isShown: boolean }>(
  'SET_CHECKLIST_REPORT_MODAL_SHOWN',
);

// CHECKLIST OVERVIEW

export const checklistOverviewCompleted = createAction<ChecklistOverviewCompleteAction | undefined>(
  'CHECKLIST_OVERVIEW_COMPLETED',
);

// CHECKLIST DAMAGE REPORT HISTORY

export const fetchChecklistDamageReportHistory = createAction(
  'FETCH_CHECKLIST_DAMAGE_REPORT_HISTORY',
);
export const fetchChecklistDamageReportHistorySuccess = createAction<ChecklistReportedDamages[]>(
  'FETCH_CHECKLIST_DAMAGE_REPORT_HISTORY_SUCCESS',
);
export const fetchChecklistDamageReportHistoryFailure = createAction<{ error: Error }>(
  'FETCH_CHECKLIST_DAMAGE_REPORT_HISTORY_FAILURE',
);

// CHECKLIST DAMAGE REPORTS

export const setInitialChecklistDamages = createAction<{ damages: ChecklistDamageReport[] }>(
  'SET_INITIAL_CHECKLIST_DAMAGES',
);

export const updateChecklistDamageReportImage = createAction<{
  damageReportId: string;
  data: UpdateChecklistImageActionPayload;
}>('UPDATE_CHECKLIST_DAMAGE_REPORT_IMAGE');

export const setCurrentDamageReport = createAction<{
  currentDamageReport: Partial<ChecklistDamageReport>;
}>('SET_CURRENT_DAMAGE_REPORT');

export const resetCurrentDamageReport = createAction('RESET_CURRENT_DAMAGE_REPORT');

export const checklistDamageReportUpdateStart = createAction(
  'CHECKLIST_DAMAGE_REPORT_UPDATE_START',
);

export const setChecklistDamageReportLoaded = createAction<{ loaded: boolean }>(
  'SET_CHECKLIST_DAMAGE_REPORT_LOADED',
);

export const checklistDamageReportUpdateEnd = createAction('CHECKLIST_DAMAGE_REPORT_UPDATE_END');

export const saveDamageReport = createAction('SAVE_DAMAGE_REPORT');

export const damageReportUpdated = createAction('DAMAGE_REPORT_UPDATED');

export const damageReportDeleted = createAction<{ damageReportId: string }>(
  'DAMAGE_REPORT_DELETED',
);

export const deleteDamageReport = createAction<{ damageReportId: string }>('DELETE_DAMAGE_REPORT');

export const damageReportingStarted = createAction<{
  checkType: ChecklistCheckType;
  carPart?: CarPartType;
}>('DAMAGE_REPORTING_STARTED');

export const addDamageReport = createAction<{ damageReport: Partial<ChecklistDamageReport> }>(
  'ADD_DAMAGE_REPORT',
);

export const reportChecklistDamage = createAction<{ damage: { id: string } }>(
  'REPORT_CHECKLIST_DAMAGE',
);

export const selectDamageReport = createAction<{
  damageReportId: string;
  isOverviewEdit?: boolean;
}>('SELECT_DAMAGE_REPORT');
export const checkDamagecarPartsCompleted = createAction('CHECK_DAMAGE_CAR_PARTS_COMPLETED');

export const selectCheckDamageTypes = createAction<DamageCategoryType | DamageCategoryType[]>(
  'SELECT_CHECK_DAMAGE_TYPES',
);
export const checkDamageTypeCompleted = createAction('CHECK_DAMAGE_TYPE_COMPLETED');

export const checkDamagePhotos = createAction('CHECK_DAMAGE_PHOTOS');
export const saveCheckDamagePhotos = createAction<{ images: ImageState[] }>(
  'SAVE_CHECK_DAMAGE_PHOTOS',
);

export const checkDamagePhotosCompleted = createAction('CHECK_DAMAGE_PHOTOS_COMPLETED');

export const setDamageComment = createAction<string>('SET_DAMAGE_COMMENT');

export const setDamageMinority = createAction<boolean>('SET_DAMAGE_MINORITY');

export const setDamageStatus = createAction<string>('SET_DAMAGE_STATUS');

// CAR PARTS
export const selectCarPartsDamageCheck = createAction<CarPartType>('SELECT_CAR_PARTS_DAMAGE_CHECK');
export const unselectCarParts = createAction<CarPartType>('UNSELECT_CAR_PARTS');
export const setCarPartsDamageCheck = createAction<CarPartType[]>('SET_CAR_PARTS_DAMAGE_CHECK');

export const checklistDamageReportUpdateFinish = createAction(
  'CHECKLIST_DAMAGE_REPORT_UPDATE_FINISH',
);
