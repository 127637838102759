import { createReducer } from '@reduxjs/toolkit';

import { GetDamageReportInfoNew_damageReportInfo_additionalServices as additionalServiceType } from 'src/apps/NewDriverApp/graphql/queries/__generated__/GetDamageReportInfoNew';
import {
  getDamageReportInfoSuccess,
  toggleAdditionalService,
} from 'src/apps/NewDriverApp/redux/actions';

const initialState: additionalServiceType[] = [];

export const additionalServicesReducer = createReducer(initialState, {
  [getDamageReportInfoSuccess.type]: (state, { payload }) => {
    return payload.info.additionalServices;
  },
  [toggleAdditionalService.type]: (state, { payload }) => {
    const { id, flag, type } = payload;

    return state.map((service: additionalServiceType) => {
      if (service.id === id) {
        return { ...service, isSelected: flag };
      }

      if (flag && service.id !== id && service.type === type) {
        return { ...service, isSelected: false };
      }

      return service;
    });
  },
});

export type additionalServicesReducerType = ReturnType<typeof additionalServicesReducer>;
