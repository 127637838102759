import capitalize from 'lodash/capitalize';
import words from 'lodash/words';

import { isUUID } from './uuid';

export const titleFromUri = (uri = window.location.pathname) =>
  uri
    .split('/')
    .filter(part => Boolean(part) && !isUUID(part))
    .map(word =>
      words(word)
        .map(w => capitalize(w))
        .join(' '),
    )
    .join(' / ');
