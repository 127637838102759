import React from 'react';

import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import redWarningIconURL from 'src/apps/NewDriverApp/assets/icons/px-check-warning-red-small.svg';
import yellowWarningIconURL from 'src/apps/NewDriverApp/assets/icons/px-check-warning-yellow-small.svg';
import Text from 'src/apps/NewDriverApp/ui-components/Text';

import styles from './ServiceLightsOverviewWarning.module.scss';
import { i18n_namespace } from '../../../i18n-namespace';

export enum ServiceLightWaring {
  RED = 'red',
  YELLOW = 'yellow',
}

interface Props {
  type?: ServiceLightWaring;
}

const ServiceLightsOverviewWarning: React.FC<Props> = ({ type = ServiceLightWaring.YELLOW }) => {
  const { t } = useTranslation(i18n_namespace);
  const translationPath = `blocks.damage_information.warning.${type}`;
  const title = t(`${translationPath}.title`);
  const content = t(`${translationPath}.content`);
  const iconURL = type === ServiceLightWaring.RED ? redWarningIconURL : yellowWarningIconURL;

  return (
    <span
      className={cn(styles.container, {
        [styles.red]: type === ServiceLightWaring.RED,
        [styles.yellow]: type === ServiceLightWaring.YELLOW,
      })}
    >
      <img className={styles.icon} src={iconURL} alt="Warning icon" />
      <Text type="body-middle" color="inherit" className={styles.content}>
        <b className={styles.title}>{title}</b>
        <span>{content}</span>
      </Text>
    </span>
  );
};

export default ServiceLightsOverviewWarning;
