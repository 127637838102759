import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setAccidentAdditionalQuestionsData } from '../actions';
import { AccidentAdditionalQuestionsReducer } from '../types';

const initialState: AccidentAdditionalQuestionsReducer = {};

export const accidentAdditionalQuestionsReducer = createReducer(initialState, {
  [setAccidentAdditionalQuestionsData.type]: (
    _,
    { payload }: PayloadAction<Partial<AccidentAdditionalQuestionsReducer>>,
  ) => {
    return {
      ...payload,
    };
  },
});
