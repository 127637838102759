import { CarAvailabilityStatus } from 'src/api/globalTypes';

export interface SavingStatus {
  isCompleted: boolean;
  isCompleting: boolean;
  isDamageConfirmed: boolean;
  error: null | Error;
}

export enum DamageReportDetailsFlow {
  INDICATION_COMPLETE = 'indication-complete',
  INDICATION_DISMISS = 'indication-dismiss',
  VERIFY_REPORT = 'verify-report',
  UPDATE_REPORT = 'update-report',
}

export enum CarStateFormInputs {
  AVAILABILITY = 'availability',
  ADDITIONAL_COMMENT = 'additionalComment',
}

export interface CarState {
  [CarStateFormInputs.AVAILABILITY]: CarAvailabilityStatus | null;
  [CarStateFormInputs.ADDITIONAL_COMMENT]: string | null;
}

export interface VerifyYourInformation {
  reporterEmail?: string;
  reporterName?: string;
  reporterPhone?: string;
}

export interface DamageReportVerificationReducer {
  savingStatus: SavingStatus;
  flow: DamageReportDetailsFlow | null;
  carState: CarState;
  yourInformation: VerifyYourInformation;
  isEditing: boolean;
  message?: string;
  isInitializing: boolean;
  error: Error | null;
}

export interface CreateOverviewBlocksDataItem<T> {
  data: T;
  onClick?: () => void;
}
