import React from 'react';

import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Layout from 'src/apps/NewDriverApp/containers/Layout';
import { reportInfo } from 'src/apps/NewDriverApp/redux/selectors';
import { CarInformation } from 'src/apps/NewDriverApp/ui-components/CarInformation';

import { DetailsOverviewBlock } from './types';
import { getIndicatedBlocks } from './utils';
import DamageReportOverviewBlock from '../../components/DamageReportOverviewBlock';

export const DamageOverviewPage = () => {
  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { t } = useTranslation([
    'overview-screen',
    'interior-overview',
    'mechanical-problem',
    'carParts',
    'damageType',
    'menuPage',
  ]);

  const damageInfo = useSelector(reportInfo);

  const customDamageCategories =
    damageInfo.isAccident && !damageInfo.damageCategories?.length && t('menuPage:accident');

  const damageCategories =
    damageInfo.damageCategories?.length && damageInfo.damageCategories.map(damage => damage.type);

  const blocks = getIndicatedBlocks({
    data: {
      damagePhotos: {
        data: damageInfo.images,
      },
      carParts: {
        data: damageInfo.carParts && damageInfo.carParts.map(part => part.type),
      },
      damageTypes: {
        data: damageCategories || customDamageCategories,
      },
      date: {
        data: damageInfo.createdAt,
      },
      reporter: {
        data: {
          driverName: damageInfo.reporterName,
          driverEmail: damageInfo.reporterEmail,
        },
      },
      additionalInformation: {
        data: damageInfo.damageInformation?.damageDescription,
      },
    },
    damageType: damageInfo.type,
    t,
  });

  return (
    <Layout
      headerProps={{
        title: t('general_title'),
        startAdornment: null,
      }}
    >
      {damageInfo.car && (
        <CarInformation
          make={damageInfo.car.make}
          model={damageInfo.car.model}
          licensePlate={damageInfo.car.licensePlate || undefined}
        />
      )}

      <div>
        <FormContext {...methods}>
          {blocks.map(({ title, items, images }: DetailsOverviewBlock) => (
            <DamageReportOverviewBlock key={title} title={title} items={items} images={images} />
          ))}
        </FormContext>
      </div>
    </Layout>
  );
};
