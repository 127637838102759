import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import { CarPartType } from 'src/api/globalTypes';
import { ImageState } from 'src/apps/NewDriverApp/redux/types/Types';
import { DamagePhotosCameraContext } from 'src/apps/NewDriverApp/types';

import { DamageCategoryTypes, InteriorReportReducer, InteriorSavingStatus } from '../../types';
import {
  setInteriorDriverInformationData,
  updateInteriorReportData,
  resetInteriorReportData,
  startInteriorDamageFlow,
  updateInteriorReportSavingStatus,
  updateInteriorImageState,
  updateInteriorImagesState,
  updateInteriorReportSelectedLocation,
  removeInteriorImage,
} from '../actions';

const initialState: InteriorReportReducer = {
  damageCategory: null,
  additionalInformation: '',
  driverInformation: {
    driverName: '',
    driverEmail: '',
    driverPhone: '',
    driverCity: '',
    driverAddress: '',
    driverZip: '',
  },
  savingStatus: {
    isCompleted: false,
    isCompleting: false,
    error: null,
  },
  images: [],
  selectedLocation: [],
};

export const interiorReportReducer = createReducer(initialState, {
  [startInteriorDamageFlow.type]: (
    state,
    action: PayloadAction<{ damageCategory: DamageCategoryTypes }>,
  ) => {
    state.damageCategory = action.payload.damageCategory;
  },
  [setInteriorDriverInformationData.type]: (state, action) => {
    return merge({}, state, {
      driverInformation: {
        ...action.payload,
      },
    });
  },
  [updateInteriorReportData.type]: (
    state,
    action: PayloadAction<Partial<InteriorReportReducer>>,
  ) => {
    return merge({}, state, action.payload);
  },
  [resetInteriorReportData.type]: () => {
    return initialState;
  },
  [updateInteriorReportSavingStatus.type]: (
    state,
    action: PayloadAction<Partial<{ data: InteriorSavingStatus }>>,
  ) => {
    return {
      ...state,
      savingStatus: merge({}, state.savingStatus, action.payload.data),
    };
  },
  [updateInteriorImageState.type]: (
    state,
    action: PayloadAction<{
      internalId: string;
      valuesToUpdate: Partial<ImageState<DamagePhotosCameraContext>>;
    }>,
  ) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.internalId,
    );

    state.images[imageIndex] = {
      ...state.images[imageIndex],
      ...action.payload.valuesToUpdate,
    };
  },
  [removeInteriorImage.type]: (state, action: PayloadAction<{ internalId: string }>) => {
    return {
      ...state,
      images: [...state.images].filter(image => image.internalId !== action.payload.internalId),
    };
  },
  [updateInteriorImagesState.type]: (
    state,
    action: PayloadAction<{
      images: ImageState<DamagePhotosCameraContext>[];
    }>,
  ) => {
    state.images = action.payload.images;
  },
  [updateInteriorReportSelectedLocation.type]: (state, action: PayloadAction<CarPartType[]>) => {
    state.selectedLocation = action.payload;
  },
});
