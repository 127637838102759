import { useEffect, useState } from 'react';

import { useScreenResize } from '../../hooks';

const MAX_FONT_SIZE = 14;
const MAX_LINE_HEIGHT = 18;

export const usePhotoInputLabelTextSize = () => {
  const [fontSize, setFontSize] = useState(MAX_FONT_SIZE);
  const [lineHeight, setLineHeight] = useState(MAX_LINE_HEIGHT);

  const setSizes = () => {
    const root = document.getElementById('root');

    if (root) {
      const rootMaxWidth = getComputedStyle(root).maxWidth || '0';
      const maxWidth = parseInt(rootMaxWidth, 10);
      const currentWidth = root.offsetWidth;

      const adoptedFontSize = (currentWidth * MAX_FONT_SIZE) / maxWidth;
      const adoptedLineHeight = (currentWidth * MAX_LINE_HEIGHT) / maxWidth;

      setFontSize(adoptedFontSize);
      setLineHeight(adoptedLineHeight);
    }
  };

  useScreenResize(setSizes);
  useEffect(setSizes, []);

  return {
    fontSize: `${fontSize}px`,
    lineHeight: `${lineHeight}px`,
  };
};
