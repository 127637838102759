import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { routeChanged } from 'src/apps/NewDriverApp/redux/actions';

import { RouteChanged } from '../types/Types';

const initialState: RouteChanged = {
  previousLocation: null,
  currentLocation: {
    pathname: '/',
    search: '',
    state: null,
    hash: '',
    key: '',
  },
};

export const navigationReducer = createReducer<RouteChanged>(initialState, {
  [routeChanged.type]: (state, action: PayloadAction<RouteChanged>) => {
    state.previousLocation = action.payload.previousLocation;
    state.currentLocation = action.payload.currentLocation;
  },
});
