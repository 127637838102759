import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { ImageFromCamera } from 'src/apps/NewDriverApp/redux/types/Types';

import {
  clearCameraPhoto,
  closeCamera,
  openCamera,
  saveCameraPhoto,
  setCameraPhoto,
  updateCameraPhotoContext,
} from './actions';

export interface CameraReducerState<T = unknown> {
  selectedPhoto?: ImageFromCamera | null;
  isCameraOpened?: boolean;
  isDeletable?: boolean;
  isRotationLock?: boolean;
  takenPhotos: ImageFromCamera<T>[];
}

const initialState: CameraReducerState = {
  selectedPhoto: null,
  isCameraOpened: false,
  isDeletable: false,
  isRotationLock: false,
  takenPhotos: [],
};

export const cameraReducer = createReducer<CameraReducerState>(initialState, {
  [openCamera.type]: (state, action) => {
    return {
      ...state,
      isCameraOpened: true,
      selectedPhoto: action.payload.selectedPhoto || null,
      isDeletable: action.payload.isDeletable === undefined ? false : action.payload.isDeletable,
      isRotationLock:
        action.payload.isRotationLock === undefined ? false : action.payload.isRotationLock,
      takenPhotos: [],
      removedPhotos: [],
    };
  },
  [closeCamera.type]: state => {
    return { ...initialState, ...pick(state, ['takenPhotos', 'removedPhotos']) };
  },
  [setCameraPhoto.type]: (state, action: PayloadAction<{ selectedPhoto: ImageFromCamera }>) => {
    return {
      ...state,
      selectedPhoto: action.payload.selectedPhoto,
    };
  },
  [clearCameraPhoto.type]: state => {
    return {
      ...state,
      selectedPhoto: { context: get(state, 'selectedPhoto.context') },
    };
  },
  [saveCameraPhoto.type]: (state, action: PayloadAction<{ selectedPhoto: ImageFromCamera }>) => {
    state.takenPhotos.push(action.payload.selectedPhoto);
  },
  [updateCameraPhotoContext.type]: (state, action: PayloadAction<unknown>) => {
    return {
      ...state,
      selectedPhoto: {
        ...state.selectedPhoto,
        context: action.payload,
      },
    };
  },
});
