import { SyntheticEvent } from 'react';

import { ImageUploadStatus } from '../../redux/types/Types';

export enum PhotoInputType {
  SMALL = 'SMALL',
  MEDIUM_WITH_TEXT = 'MEDIUM_WITH_TEXT',
  LARGE = 'LARGE',
}

export interface PhotoInputRef {
  emitOnClick: (e: SyntheticEvent) => void;
}

export interface ImageStateConstraint {
  data?: string | null;
  fullUrl?: string;
  id?: string;
  internalId?: string;
  uploadingStatus?: ImageUploadStatus;
  uploadProgress?: number;
  isVisibilityRestricted?: boolean;
}
