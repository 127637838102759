import { AppEmbeddedEvents } from '../redux/types/AppState';

/**
 * TODO: (roman) strongly type payload, based on type property
 */

interface EmbeddedMessage {
  type: AppEmbeddedEvents;
  payload?: any;
}

export const EmbeddedService = {
  get parentWindow() {
    const parent = window.opener || window.parent;
    return parent;
  },

  postMessage(message: { type: AppEmbeddedEvents; payload?: any }) {
    if (!this.parentWindow) {
      return;
    }

    this.parentWindow.postMessage(message, '*');
  },

  // TODO: (roman) reduce number of subscribers on the page by having a map of subscribers
  subscribe(type: AppEmbeddedEvents | AppEmbeddedEvents[], callback: (payload: any) => void) {
    const subscriber = (event: MessageEvent) => {
      if (!event.isTrusted) {
        return;
      }

      const types = Array.isArray(type) ? type : [type];

      if (types.includes(event.data?.type)) {
        callback(event.data.payload);
      }
    };

    window.addEventListener('message', subscriber);

    return subscriber;
  },

  unsubscribe(subscriber: (event: MessageEvent) => void) {
    window.removeEventListener('message', subscriber);
  },
};
