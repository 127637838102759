import { TextType } from './types';

export const TEXT_LINK_TYPE = {
  LINK_LARGE: 'link-large',
  LINK_MEDIUM: 'link-medium',
  LINK_SMALL: 'link-small',
} as const;

export const TEXT_TYPE = {
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  BODY_BASE: 'body-base',
  BODY_BASE_MEDIUM: 'body-base-medium',
  BODY_BASE_BOLD: 'body-base-bold',
  BODY_BASE_UPPERCASE: 'body-base-uppercase',
  BODY_MIDDLE: 'body-middle',
  BODY_MIDDLE_MEDIUM: 'body-middle-medium',
  BODY_MIDDLE_BOLD: 'body-middle-bold',
  BODY_MIDDLE_UPPERCASE: 'body-middle-uppercase',
  BODY_SMALL: 'body-small',
  BODY_SMALL_MEDIUM: 'body-small-medium',
  BODY_SMALL_BOLD: 'body-small-bold',
  BODY_SMALL_UPPERCASE: 'body-small-uppercase',
  BODY_XSMALL: 'body-xsmall',
  BODY_XSMALL_MEDIUM: 'body-xsmall-medium',
  BODY_XSMALL_BOLD: 'body-xsmall-bold',
  BODY_XSMALL_UPPERCASE: 'body-xsmall-uppercase',
  BUTTON_LARGE: 'button-large',
  BUTTON_MEDIUM: 'button-medium',
  ...TEXT_LINK_TYPE,
} as const;

export const TEXT_TYPE_TAG: Record<TextType, string> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  'body-base': 'span',
  'body-base-medium': 'span',
  'body-base-bold': 'span',
  'body-base-uppercase': 'span',
  'body-middle': 'span',
  'body-middle-medium': 'span',
  'body-middle-bold': 'span',
  'body-middle-uppercase': 'span',
  'body-small': 'span',
  'body-small-medium': 'span',
  'body-small-bold': 'span',
  'body-small-uppercase': 'span',
  'body-xsmall': 'span',
  'body-xsmall-medium': 'span',
  'body-xsmall-bold': 'span',
  'body-xsmall-uppercase': 'span',
  'button-large': 'span',
  'button-medium': 'span',
  'link-large': 'span',
  'link-medium': 'span',
  'link-small': 'span',
};

// should be aligned in the scope of the
// https://motum-by-repairfix.atlassian.net/browse/REP-11752
export const TEXT_COLOR = {
  PRIMARY_CONTENT: 'primary-content',
  PRIMARY_GRAPHIC: 'primary-graphic',
  PRIMARY_BACKGROUND_BASE: 'primary-background-base',
  PRIMARY_MOTUM_CONTENT: 'primary-motum-content',
  PRIMARY_ACCENT: 'primary-accent',
  SECONDARY_GRAPHIC: 'secondary-graphic',
  SECONDARY_CONTENT: 'secondary-content',
  SECONDARY_BORDER_CON_LOW: 'secondary-border-con-low',
  SECONDARY_STATE_DISABLED: 'secondary-state-disabled',
  SECONDARY_BACKGROUND_ALT: 'secondary-background-alt',
  NOTIFICATION_ERROR: 'notification-error',
  NOTIFICATION_ERROR_BACKGROUND: 'notification-error-background',
  NOTIFICATION_WARNING: 'notification-warning',
  NOTIFICATION_WARNING_BACKGROUND: 'notification-warning-background',
  NOTIFICATION_SUCCESS: 'notification-success',
  NOTIFICATION_SUCCESS_BACKGROUND: 'notification-success-background',
  NOTIFICATION_INFO: 'notification-info',
  NOTIFICATION_INFO_BACKGROUND: 'notification-info-background',
  SECONDARY_BORDER_CON_MEDIUM: 'secondary-border-con-medium',
  INHERIT: 'inherit',
  UNDEFINED: 'undefined', // in case no color class needed
} as const;
