import { LinkTextType } from './types';

export const LINK_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

export const LINK_ADORNMENT_SIZE_CLASS_MAPPER: Record<LinkTextType, string> = {
  'link-large': 'adornmentLarge',
  'link-medium': 'adornmentMedium',
  'link-small': 'adornmentSmall',
} as const;
