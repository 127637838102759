import { ImageTypes, MAX_ADDITIONAL_PHOTOS } from 'src/apps/NewDriverApp/constants';
import { ImageState, ImageFromCamera } from 'src/apps/NewDriverApp/redux/types/Types';

import { findDamageReportImageByType } from './helpers';

export const getImagesCounter = (
  currentFlow?: ImageTypes[],
  imageType?: ImageTypes,
  images?: ImageState[],
  imagesFromCamera?: ImageFromCamera[],
) => {
  if (imageType === ImageTypes.ADDITIONAL) {
    if (imagesFromCamera && images) {
      const additionalImages = images.filter(findDamageReportImageByType(ImageTypes.ADDITIONAL));
      const additionalImagesQuantity = [...additionalImages, ...imagesFromCamera];

      return `${additionalImagesQuantity.length}/${MAX_ADDITIONAL_PHOTOS}`;
    }
  }
  if (imageType && currentFlow) {
    const currentImageNumber = currentFlow.indexOf(imageType);

    return `${currentImageNumber + 1}/${currentFlow.length}`;
  }

  return '';
};
