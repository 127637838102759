import { combineReducers } from '@reduxjs/toolkit';

import { checklistDamageReportHistoryReducer } from './checklist-damage-report-history-reducer';
import { checklistDamageReportsReducer } from './checklist-damage-reports-reducer';
import { checklistImagesReducer } from './checklist-images-reducer';
import { checklistReportReducer } from './checklist-report-reducer';
import { checklistTemplateReducer } from './checklist-template-reducer';

export const checklist = combineReducers({
  checklistTemplate: checklistTemplateReducer,
  checklistImages: checklistImagesReducer,
  checklistReport: checklistReportReducer,
  checklistDamageReportHistory: checklistDamageReportHistoryReducer,
  checklistDamageReports: checklistDamageReportsReducer,
});

export type ChecklistReducer = ReturnType<typeof checklist>;
