import React from 'react';

import cn from 'classnames';

import getCssVariableValue from 'src/apps/NewDriverApp/utils/getCssVariableValue';

import styles from './Spinner.module.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
  color?: string;
  width?: string;
}

const Spinner: React.FC<Props> = ({
  className,
  style,
  color = '--primary-accent',
  width = '3px',
}) => {
  const resolvedColor = color.trim().startsWith('--') ? getCssVariableValue(color) : color;

  const cssStyles = {
    border: `${width} solid ${resolvedColor}`,
    borderColor: `${resolvedColor} ${resolvedColor} ${resolvedColor} transparent`,
  };

  return (
    <div className={cn(styles.container, className)} style={style}>
      <div className={styles.circle} style={cssStyles} />
    </div>
  );
};

export default Spinner;
