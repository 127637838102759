import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { CarPartType } from 'src/api/globalTypes';
import { DamageReasonsReducer } from 'src/apps/NewDriverApp/redux/reducers/damage-reasons-reducer';
import { ImageState } from 'src/apps/NewDriverApp/redux/types/Types';

import * as Actions from '../actions';

const initialState: {
  savedDamageReasons?: DamageReasonsReducer;
  savingDamageReasons?: DamageReasonsReducer;
  savedRegistrationPhoto?: ImageState;
  savedCarParts?: CarPartType[];
  savingCarParts?: CarPartType[];

  isCompleted: boolean;
  isCompleting: boolean;
  isCompletingError: null | Error;
} = {
  isCompleted: false,
  isCompleting: false,
  isCompletingError: null,
};

export const saveReportReducer = createReducer(initialState, {
  [Actions.completeDamageReport.type]: state => {
    Object.assign(state, {
      isCompleting: true,
      isCompletingError: null,
    });
  },
  [Actions.completeDamageReportSuccess.type]: state => {
    Object.assign(state, {
      isCompleted: true,
      isCompleting: false,
    });
  },
  [Actions.completeDamageReportFailure.type]: (state, action: PayloadAction<{ error: Error }>) => {
    Object.assign(state, {
      isCompleting: false,
      isCompletingError: action.payload.error,
    });
  },
  [Actions.setRegistrationImage.type]: (state, action: PayloadAction<{ image: ImageState }>) => {
    state.savedRegistrationPhoto = action.payload.image;
  },
  [Actions.setDamageReportIsCompleting.type]: (state, { payload }) => {
    state.isCompleting = payload;
  },
});

export type SavingReportReducer = ReturnType<typeof saveReportReducer>;
