import React from 'react';

import cx from 'classnames';

import pencilIcon from 'src/apps/NewDriverApp/assets/icons/px-pencil.svg';
import Text from 'src/apps/NewDriverApp/ui-components/Text';

import styles from './InputItem.module.scss';

interface Props {
  subtitle: string;
  text?: React.ReactNode | string | null;
  className?: string;
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

const InputItem: React.FC<Props> = ({ text, subtitle, className, onClick, children }) => {
  return (
    <div className={cx(styles.item, className)}>
      <div className={styles.textContainer}>
        <Text color="secondary-content" className={styles.subtitle}>
          {subtitle}
        </Text>
        <Text className={styles.text}>{text}</Text>
        {children}
      </div>
      {onClick && (
        <button className={styles.button} onClick={onClick}>
          <img alt="edit icon" src={pencilIcon} />
        </button>
      )}
    </div>
  );
};

export { InputItem };
