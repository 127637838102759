import React from 'react';

import cn from 'classnames';

import { InputItem } from 'src/apps/NewDriverApp/flows/fleet/accident/AccidentOverview/components/InputItem';
import { DetailsOverviewBlock } from 'src/apps/NewDriverApp/flows/fleet/opponent/pages/OverviewPage/types';
import ReportedDamageItem from 'src/apps/NewDriverApp/pages/ReportedDamage/components/ReportedDamageItem/ReportedDamageItem';
import { ContentArea } from 'src/apps/NewDriverApp/ui-components/ContentArea';
import { StepDescription } from 'src/apps/NewDriverApp/ui-components/StepDescription';

import styles from './DamageReportOverviewBlock.module.scss';

const DamageReportOverviewBlock: React.FC<DetailsOverviewBlock> = ({ title, items = [] }) => (
  <ContentArea className={styles.contentArea}>
    {title && <StepDescription className={styles.title} title={title} />}
    <div>
      {items.map(({ subtitle, text, onClick, withDivider, images: imagesList, isServiceLight }) => {
        return (
          <InputItem
            key={subtitle}
            subtitle={subtitle}
            text={text}
            className={cn(styles.inputItem, {
              [styles.topDividerBlock]: withDivider,
              [styles.serviceLightsContainer]: isServiceLight,
            })}
            onClick={onClick}
          >
            {imagesList && (
              <ReportedDamageItem
                images={imagesList}
                className={styles.imagesContainer}
                imagePreviewContainerClassName={styles.imagePreviewContainer}
                isWithDescription={false}
              />
            )}
          </InputItem>
        );
      })}
    </div>
  </ContentArea>
);

export default React.memo(DamageReportOverviewBlock);
