import React from 'react';

import cn from 'classnames';

import Text from '../../../Text';
import { TEXT_COLOR, TEXT_LINK_TYPE } from '../../../Text/constants';
import { TextColor } from '../../../Text/types';
import { LINK_ADORNMENT_SIZE_CLASS_MAPPER, LINK_TYPE } from '../../constants';
import styles from '../../Link.module.scss';
import { LinkTextType, LinkType } from '../../types';

interface Props {
  size?: LinkTextType;
  variant?: LinkType;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  color?: TextColor;
}
const LinkContent: React.FC<Props> = ({
  startAdornment,
  endAdornment,
  disabled,
  children,
  color = TEXT_COLOR.UNDEFINED,
  variant = LINK_TYPE.PRIMARY,
  size = TEXT_LINK_TYPE.LINK_MEDIUM,
}) => {
  const adornmentSizeClassName = styles[LINK_ADORNMENT_SIZE_CLASS_MAPPER[size]];
  const colorClassName = cn({
    [styles.disabled]: disabled,
    [styles.primary]: variant === LINK_TYPE.PRIMARY,
    [styles.secondary]: variant === LINK_TYPE.SECONDARY,
  });
  const content = (
    <Text
      type={size}
      color={color}
      className={cn(styles.text, { [colorClassName]: color === TEXT_COLOR.UNDEFINED })}
    >
      {startAdornment && (
        <span className={cn(styles.startAdornment, adornmentSizeClassName)}>
          &zwnj;
          {startAdornment}
        </span>
      )}
      <span>{children}</span>
      {endAdornment && (
        <span className={cn(styles.endAdornment, adornmentSizeClassName)}>
          &zwnj;{endAdornment}
        </span>
      )}
    </Text>
  );
  return content;
};
export default LinkContent;
