export const VERIFY_REPORT_FLOW_DOMAIN = 'verify-report';

export const VERIFY_REPORT_ROUTE_NAMES = {
  VERIFY_REPORT_COMPLETED: 'completed',
  VERIFY_REPORT_EDIT_CAR_PARTS: 'car-parts',
  VERIFY_REPORT_EDIT_DAMAGE_TYPES: 'damage-types',
  VERIFY_REPORT_OVERVIEW: 'overview',
  VERIFY_REPORT_OVERVIEW_WITH_DAMAGE: 'overview/:damageId',
  VERIFY_REPORT_CAR_STATE: 'car-state',
  VERIFY_REPORT_YOUR_INFORMATION: 'your-information',
  VERIFY_REPORT_DAMAGE_PHOTOS: 'damage-photos',
  VERIFY_REPORT_INDICATION_OVERVIEW: 'indication-overview',
  VERIFY_REPORT_INTERIOR_DAMAGE_LOCATION: 'interior-damage-location',
};

export type VerifyRouteKeys = keyof typeof VERIFY_REPORT_ROUTE_NAMES;
export type VerifyRoutes = Record<VerifyRouteKeys, string>;

export const VERIFY_FLOW_ROUTES = Object.entries(VERIFY_REPORT_ROUTE_NAMES).reduce((acc, entry) => {
  const [routeName, routeValue] = entry;

  return { ...acc, [routeName]: `/${VERIFY_REPORT_FLOW_DOMAIN}/${routeValue}` };
}, {}) as VerifyRoutes;
