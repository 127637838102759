import React, { isValidElement } from 'react';

import cn from 'classnames';

import Text from 'src/apps/NewDriverApp/ui-components/Text';

import { NOTIFICATION_PLATE_TYPE } from './constants';
import styles from './NotificationPlate.module.scss';
import { NotificationPlateType } from './types';
import CheckCircle from '../../icons/CheckCircle';
import ChevronRightSmall from '../../icons/ChevronRightSmall';
import ExclamationCircle from '../../icons/ExclamationCircle';
import ExclamationTriangle from '../../icons/ExclamationTriangle';
import InfoCircle from '../../icons/InfoCircle';
import XMedium from '../../icons/XMedium';

const iconMapper = {
  [NOTIFICATION_PLATE_TYPE.MAIN]: <ExclamationCircle className={styles.mainIcon} />,
  [NOTIFICATION_PLATE_TYPE.INFO]: <InfoCircle className={styles.infoIcon} />,
  [NOTIFICATION_PLATE_TYPE.ERROR]: <ExclamationCircle className={styles.errorIcon} />,
  [NOTIFICATION_PLATE_TYPE.SUCCESS]: <CheckCircle className={styles.successIcon} />,
  [NOTIFICATION_PLATE_TYPE.WARNING]: <ExclamationTriangle className={styles.warningIcon} />,
};

interface Props {
  type?: NotificationPlateType;
  className?: string;
  title?: React.ReactNode;
  content?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  actionButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  closeButtonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

const NotificationPlate: React.FC<Props> = ({
  title,
  content,
  className,
  startAdornment,
  endAdornment,
  actionButtonProps,
  closeButtonProps,
  type = NOTIFICATION_PLATE_TYPE.MAIN,
}) => {
  const isRenderCloseButton = closeButtonProps && !endAdornment;

  const {
    children: actionButtonChildren,
    className: actionButtonClassName,
    ...restActionButtonProps
  } = actionButtonProps || {};

  const {
    children: closeButtonChildren,
    className: closeButtonClassName,
    ...restCloseButtonProps
  } = closeButtonProps || {};

  const classNameType = styles[type];
  const icon = startAdornment || iconMapper[type];
  const titleText = title ? <Text type="body-middle-bold">{title}</Text> : null;
  const contentText = content ? <Text type="body-middle-medium">{content}</Text> : null;
  const titleComponent = isValidElement(title) ? title : titleText;
  const contentComponent = isValidElement(content) ? content : contentText;

  const actionButtonContent = isValidElement(actionButtonChildren) ? (
    { actionButtonChildren }
  ) : (
    <Text type="link-medium">{actionButtonChildren}</Text>
  );

  return (
    <div className={cn(styles.container, classNameType, className)}>
      {icon && <div className={styles.iconContainer}>{icon}</div>}
      <div className={styles.content}>
        <div className={styles.textContainer}>
          {titleComponent}
          {contentComponent}
        </div>
        {actionButtonProps && (
          <button
            className={cn(styles.actionButton, actionButtonClassName)}
            {...restActionButtonProps}
          >
            {actionButtonContent}
            <ChevronRightSmall />
          </button>
        )}
      </div>
      {endAdornment}
      {isRenderCloseButton && (
        <button className={cn(styles.closeButton, closeButtonClassName)} {...restCloseButtonProps}>
          {closeButtonChildren || <XMedium className={styles.closeIcon} />}
        </button>
      )}
    </div>
  );
};

export default NotificationPlate;
