import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as LangIcon } from 'src/img/other-icons/px-language.svg';

import styles from './LanguageToggle.module.scss';
import { AppConfiguration_appConfiguration } from '../../graphql/queries/__generated__/AppConfiguration';
import { isInEmbeddedMode, appConfigurationSelector } from '../../redux/selectors';
import { getAppLocales } from '../../services/i18n/helpers';
import { BottomModal } from '../BottomModal';
import { LanguageSwitcher } from '../LanguageSwitcher';
import Text from '../Text';

export const titles: Record<string, string> = {
  de: 'Sprache',
  en: 'Language',
  pl: 'Język',
  tr: 'Dil',
  ar: 'اللغة',
  es: 'Idioma',
  ru: 'Язык',
  ro: 'Limbă',
  fr: 'Langue',
};

const LanguageToggle: React.FC = () => {
  const { i18n } = useTranslation();
  const [isLangOptionsModal, setIsLangOptionsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { configuration } = useSelector(appConfigurationSelector);
  const locales = getAppLocales(configuration as AppConfiguration_appConfiguration);
  const isEmbedded = useSelector(isInEmbeddedMode);
  const { language } = i18n;

  const onClick = () => setIsLangOptionsModal(true);
  const onLoadStarted = () => setIsLoading(true);
  const onLoadFinished = () => setIsLoading(false);

  if (isEmbedded) {
    return null;
  }

  if (locales.length === 1) {
    return null;
  }

  return (
    <>
      <button className={styles.container} type="button" onClick={onClick}>
        <Text className={styles.langName} type="body-middle-uppercase" color="secondary-content">
          {language}
        </Text>{' '}
        <LangIcon />
      </button>

      <BottomModal
        title={titles[language] || 'Language'}
        onClose={() => setIsLangOptionsModal(false)}
        isOpen={isLangOptionsModal}
        isDisabled={isLoading}
      >
        <LanguageSwitcher
          onChange={() => setIsLangOptionsModal(false)}
          onLoadStarted={onLoadStarted}
          onLoadFinished={onLoadFinished}
          locales={locales}
        />
      </BottomModal>
    </>
  );
};

export { LanguageToggle };
