import { titleFromUri } from 'src/apps/NewDriverApp/utils/titleFromUri';

const SET_TELEMETRY_FLOW = 'SET_TELEMETRY_FLOW';

export enum AppFlowTelemetry {
  TRIVIAL = 'trivial',
  INTERIOR = 'interior',
  SERVICE_LIGHTS = 'service-lights',
  ACCIDENT = 'accident',
  ACCIDENT_WITH_OTHER_CAR = 'accident-with-other-car',
  HOTLINE = 'hotline',
  CHECKLIST = 'checklist',
  DAMAGE_VERIFICATION = 'damage-verification',
  SIMPLE_ACCIDENT = 'simple-accident',
}

interface SetTelemetryFlowEvent {
  flow?: AppFlowTelemetry;
}

export const setTelemetryFlow = (flow?: AppFlowTelemetry) => {
  const event = new CustomEvent<SetTelemetryFlowEvent>(SET_TELEMETRY_FLOW, {
    detail: { flow },
  });

  document.dispatchEvent(event);
};

export const flowTrackerTelemetry = (initialFlow?: AppFlowTelemetry) => {
  let flow = initialFlow;

  document.addEventListener(SET_TELEMETRY_FLOW, ((e: CustomEvent<SetTelemetryFlowEvent>) => {
    flow = e.detail.flow;
  }) as EventListener);

  return (telemetry: { data?: any }) => {
    if (!flow) {
      return;
    }

    telemetry.data = {
      ...telemetry.data,
      flow,
      step: titleFromUri(window.location.pathname),
    };
  };
};
