import { createAction } from '@reduxjs/toolkit';

import { DriverInformationInput } from 'src/api/globalTypes';

import { MechanicalProblemReducer } from '../types';

export const mechanicalFlowEntered = createAction('MECHANICAL_FLOW_ENTERED');

export const mechanicalFlowExited = createAction('MECHANICAL_FLOW_EXITED');

export const updateMechanicalProblemData = createAction<MechanicalProblemReducer>(
  'UPDATE_MECHANICAL_PROBLEM_DATA',
);

export const completeMechanicalDriverInformationData = createAction<{
  data: DriverInformationInput;
}>('COMPLETE_MECHANICAL_DRIVER_INFORMATION_DATA');
