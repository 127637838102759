import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import { accidentReportReducer } from 'src/apps/NewDriverApp/flows/fleet/accident/redux/reducers/accident-report-reducer';
import { checklist } from 'src/apps/NewDriverApp/flows/fleet/checklist/redux/reducers/checklist-reducer';
import { interiorReportReducer } from 'src/apps/NewDriverApp/flows/fleet/interior/redux/reducers/interior-report-reducer';
import { serviceLightsReportReducer } from 'src/apps/NewDriverApp/flows/fleet/service-lights/redux/reducers/service-lights-report-reducer';
import { damageReportVerificationReducer } from 'src/apps/NewDriverApp/flows/fleet/verify-report/redux/reducers/damage-report-verifiction-reducer';
import { appReducer } from 'src/apps/NewDriverApp/redux/reducers/app-reducer';
import { reportReducer } from 'src/apps/NewDriverApp/redux/reducers/report-reducer';

import { refreshStateForNewReport, resetReport, resetState } from './actions';
import { assetsReducer } from './reducers/assets-reducer';
import { authReducer } from './reducers/auth-reducer';
import { carIdentificationReducer } from './reducers/car-identification-reducer';
import { navigationReducer } from './reducers/navigation-reducer';
import { notificationReducer } from './reducers/notification-reducer';
import { saveReportReducer } from './reducers/save-report-reducer';
import { wizardsReducer } from './reducers/wizards-reducer';
import { DAMAGE_CODE_STORAGE_KEY } from '../constants';
import { carReducer } from '../flows/fleet/fleet-manager/redux/reducers/car/reducer';
import { damageReducer } from '../flows/fleet/fleet-manager/redux/reducers/damage/reducer';
import { userReducer } from '../flows/fleet/fleet-manager/redux/reducers/user/reducer';
import { cameraReducer } from '../ui-components/Camera/redux/camera-reducer';

const mainReducer = combineReducers({
  auth: authReducer,
  report: reportReducer,
  assets: assetsReducer,
  app: appReducer,
  car: carReducer,
  wizards: wizardsReducer,
  damage: damageReducer,
  camera: cameraReducer,
  saveReportState: saveReportReducer,
  carIdentification: carIdentificationReducer,
  accidentReport: accidentReportReducer,
  checklist,
  interiorReport: interiorReportReducer,
  serviceLightsReport: serviceLightsReportReducer,
  damageVerification: damageReportVerificationReducer,
  user: userReducer,
  notification: notificationReducer,
  navigation: navigationReducer,
});

export type RootState = ReturnType<typeof mainReducer>;

const rootReducer = (state: any, action: AnyAction) => {
  let newState;

  switch (action.type) {
    case refreshStateForNewReport.type:
      newState = {
        auth: state.auth,
        app: state.app,
        carIdentification: state.carIdentification,
        user: state.user,
        car: state.car,
      };
      break;
    case resetState.type:
      newState = { app: state.app };
      break;
    case resetReport.type:
      newState = {
        ...state,
        auth: {
          ...state.auth,
          tokens: omit(state.auth.tokens, DAMAGE_CODE_STORAGE_KEY),
        },
        report: undefined,
        interiorReport: undefined,
        serviceLightsReport: undefined,
        accidentReport: undefined,
        checklist: undefined,
      };
      break;
    default:
      newState = state;
  }

  return mainReducer(newState, action);
};

export { rootReducer };
