import React from 'react';

import cx from 'classnames';

import { ReactComponent as CalendarIcon } from 'src/apps/NewDriverApp/assets/icons/px-calendar-pic.svg';
import { ImageState } from 'src/apps/NewDriverApp/redux/types/Types';
import ImagesBlock from 'src/apps/NewDriverApp/ui-components/ImagesBlock';
import Text from 'src/apps/NewDriverApp/ui-components/Text';

import styles from './ReportedDamageItem.module.scss';

interface Props {
  images: ImageState[];
  title?: string;
  createdDate?: string;
  subtitle?: React.ReactNode | string;
  className?: string;
  imagePreviewContainerClassName?: string;
  isWithDescription?: boolean;
  isPreview?: boolean;
  customCounterFunction?: () => string;
}

const ReportedDamageItem: React.FC<Props> = ({
  images,
  title,
  createdDate,
  subtitle,
  className,
  imagePreviewContainerClassName,
  isPreview,
  customCounterFunction,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.titleContainer}>
        {title && <Text>{title}</Text>}
        {createdDate && (
          <div className={styles.dateContainer}>
            <CalendarIcon className={styles.calendarIcon} />
            <Text type="body-middle" color="secondary-content">
              {createdDate}
            </Text>
          </div>
        )}
      </div>
      {subtitle && (
        <Text color="secondary-content" className={styles.partsContainer}>
          {subtitle}
        </Text>
      )}
      <div className={imagePreviewContainerClassName}>
        <ImagesBlock
          images={images}
          withDivider={false}
          maxImagesNumber={images.length}
          isPreview={isPreview}
          customCounterFunction={customCounterFunction}
        />
      </div>
    </div>
  );
};

export default ReportedDamageItem;
