import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import { createSelector } from 'reselect';

import { AppFeatureType } from 'src/api/globalTypes';
import { WebAppFlows } from 'src/apps/NewDriverApp/constants';
import { RootState } from 'src/apps/NewDriverApp/redux/root-reducer';
import { getChecklistCode, getIsWebAppFlow } from 'src/apps/NewDriverApp/redux/selectors/selectors';
import { InitState } from 'src/apps/NewDriverApp/redux/types/AppState';
import { ChecklistTemplate } from 'src/apps/NewDriverApp/types';

export const getChecklistTemplates = createSelector(
  (state: RootState): InitState => ({ ...state.app }),
  getChecklistCode,
  getIsWebAppFlow,
  ({ configuration }, checklistCode, isWebAppFlow) => {
    const { features } = configuration || {};
    const checklistFeature = (features || []).find(({ name }) => name === AppFeatureType.CHECKLIST);

    const checklists = get(
      checklistFeature,
      'options.checklistTemplates',
      [],
    ) as ChecklistTemplate[];

    // filtering out available templates when in webapp flow
    if (isWebAppFlow(WebAppFlows.CHECKLIST)) {
      if (checklistCode) {
        const codeData = jwtDecode<{ checklistId: string }>(checklistCode as string);
        return checklists.filter(checklist => checklist.id === codeData.checklistId);
      }
    }

    return checklists;
  },
);
