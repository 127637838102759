/* eslint-disable */
import { useMemo } from 'react';

import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { getCustomerTheme } from 'src/apps/NewDriverApp/redux/selectors';

import { getThemeQuery } from '../graphql/queries/getTheme.query';
import { BuiltInTheme } from '../theme/BuiltInTheme';
import { ThemeInterface } from '../theme/ThemeInterface';

const useCustomerTheme = () => {
  const { pathname } = document.location;

  const isAppointmentConfirmationApp =
    pathname.startsWith('/handover-confirmation') ||
    pathname.startsWith('/inspection-confirmation');

  if (isAppointmentConfirmationApp) {
    const { data } = useQuery(getThemeQuery);

    return data ? data.getTheme : null;
  }

  return useSelector(getCustomerTheme);
};

export const useTheme = () => {
  const customerTheme = useCustomerTheme();
  const overridedTheme: ThemeInterface = useMemo(
    () => ({
      properties: Object.assign(
        {},
        BuiltInTheme.properties,
        customerTheme !== null ? customerTheme.properties : {},
      ),
      logo: Object.assign({}, BuiltInTheme.logo, customerTheme !== null ? customerTheme.logo : {}),
      favicon: Object.assign(
        {},
        BuiltInTheme.favicon,
        customerTheme !== null ? customerTheme.favicon : {},
      ),
    }),
    [customerTheme],
  );
  return overridedTheme;
};
/* eslint-enable */
