import { createAction } from '@reduxjs/toolkit';

import { ImageFromCamera } from 'src/apps/NewDriverApp/redux/types/Types';

export interface CameraAction {
  selectedPhoto?: ImageFromCamera;
  isDeletable?: boolean;
  isRotationLock?: boolean;
}

export const openCamera = createAction<CameraAction>('OPEN_CAMERA');

export const closeCamera = createAction<boolean | undefined>('CLOSE_CAMERA');

export const setCameraPhoto = createAction<{ selectedPhoto: CameraAction['selectedPhoto'] }>(
  'SET_CAMERA_PHOTO',
);

export const removeCameraPhoto = createAction<{
  selectedPhoto: CameraAction['selectedPhoto'];
}>('REMOVE_CAMERA_PHOTO');

export const clearCameraPhoto = createAction('CLEAR_CAMERA_PHOTO');

export const saveCameraPhoto = createAction<{ selectedPhoto: CameraAction['selectedPhoto'] }>(
  'SAVE_CAMERA_PHOTO',
);

export const updateCameraPhotoContext = createAction<unknown>('UPDATE_CAMERA_PHOTO_CONTEXT');

export const imageFromInputSelected = createAction<ImageFromCamera>('IMAGE_FROM_INPUT_SELECTED');

export const startImageUpload = createAction<ImageFromCamera>('START_IMAGE_UPLOAD');
