import React, { useRef, useEffect, forwardRef, useMemo } from 'react';

import cx from 'classnames';
import Siema from 'siema';

import useZoomContainerConfiguration from 'src/apps/NewDriverApp/pages/ReportedDamage/components/DamageImageSlider/hooks/useZoomContainerConfiguration';
import CircularProgress from 'src/apps/NewDriverApp/ui-components/CircularProgress';
import ZoomContainer from 'src/apps/NewDriverApp/ui-components/ZoomContainer';
import getCssVariableValue from 'src/apps/NewDriverApp/utils/getCssVariableValue';

import styles from './DamageImageSlider.module.scss';

interface SlideItem {
  id: string | undefined;
  src: string;
  uploadProgress?: number | undefined;
}

interface Props {
  slides: SlideItem[];
  index: number;
  onChange: (index: number) => void;
  sliderItemClassName?: string;
  isWithZoom?: boolean;
}

interface UseSiemaArgument {
  ref: React.RefObject<HTMLDivElement>;
  startIndex: number;
  siemaRef: React.MutableRefObject<Siema | null>;
  handleChange: (index: number) => void;
}

const useSiema = ({ ref, startIndex, siemaRef, handleChange }: UseSiemaArgument) => {
  useEffect(() => {
    if (ref.current) {
      siemaRef.current = new Siema({
        selector: ref.current,
        startIndex,
        onChange: () => {
          if (siemaRef.current) {
            handleChange(siemaRef.current.currentSlide);
          }
        },
      });
    }

    return () => {
      if (siemaRef.current) {
        siemaRef.current.destroy();
      }
    };
  }, []);
};

const DamageImageSlider: React.FC<Props> = (
  { slides, index, onChange, sliderItemClassName, isWithZoom = false },
  ref,
) => {
  const sliderRef = useRef<HTMLDivElement>(null);

  const zoomContainerConfiguration = useZoomContainerConfiguration();

  useSiema({
    ref: sliderRef,
    startIndex: index,
    siemaRef: ref,
    handleChange: onChange,
  });

  const backgroundLineColor = useMemo(() => getCssVariableValue('--disabled-text-color'), []);
  const progressProps = { backgroundLineColor, size: 2, lineWidth: 4 };

  const isLoaded = (slide: SlideItem) => Boolean(slide.src);

  return (
    <div ref={sliderRef}>
      {slides.map(slide => (
        <div key={slide.id || slide.src}>
          {isLoaded(slide) ? (
            <ZoomContainer
              wrapperProps={zoomContainerConfiguration.wrapperProps}
              contentProps={zoomContainerConfiguration.contentProps}
              isWithZoom={isWithZoom}
            >
              <div
                className={cx(styles.image, sliderItemClassName)}
                style={{
                  backgroundImage: `url(${slide.src})`,
                }}
              />
            </ZoomContainer>
          ) : (
            <div className={styles.progressContainer}>
              <CircularProgress {...progressProps} progress={slide.uploadProgress || 0} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default forwardRef<Siema, Props>(DamageImageSlider as any);
