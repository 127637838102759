import React from 'react';

import cx from 'classnames';

import { ReactComponent as PxArrowLeftIcon } from 'src/apps/NewDriverApp/assets/icons/px-arrow-left.svg';
import { ReactComponent as PxArrowRightIcon } from 'src/apps/NewDriverApp/assets/icons/px-arrow-right.svg';

import styles from './SlideButton.module.scss';

export enum SlideButtonType {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

interface Props {
  type?: SlideButtonType;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

const SlideButton: React.FC<Props> = ({
  type = SlideButtonType.LEFT,
  disabled,
  className,
  onClick,
}) => {
  const Icon = type === SlideButtonType.RIGHT ? PxArrowRightIcon : PxArrowLeftIcon;

  return (
    <button onClick={onClick} className={cx(styles.button, className)} disabled={disabled}>
      <Icon />
    </button>
  );
};

export default SlideButton;
