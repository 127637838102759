import { MutableRefObject, useEffect, useRef, useState } from 'react';

import get from 'lodash/get';

const HINT_BOTTOM_TOLERANCE = 50;

export const useActiveScroll = (
  parentElementRef: MutableRefObject<HTMLDivElement | null>,
  isScrollHelpingArrowVisible: boolean,
) => {
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(true);
  const [isFirstTimeShow, setIsFirstTimeShow] = useState<boolean>(true);
  const [isAtTop, setIsAtTop] = useState<boolean>(true);

  const lastElement = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const isScrollVisible =
    get(parentElementRef, 'current.scrollHeight', 0) >
    get(parentElementRef, 'current.offsetHeight', 0);

  const parentElement = parentElementRef.current;
  const isAtBottom =
    parentElement &&
    parentElement.scrollTop + parentElement.clientHeight >=
      parentElement.scrollHeight - HINT_BOTTOM_TOLERANCE;

  const isShowHint =
    isScrollHelpingArrowVisible && isScrollVisible && isButtonVisible && isAtTop && !isAtBottom;

  useEffect(() => {
    if (parentElement && !isShowHint) {
      setIsFirstTimeShow(false);
    }
  }, [isShowHint]);

  useEffect(() => {
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setIsButtonVisible(false);
      } else {
        setIsButtonVisible(true);
      }
    });
    if (lastElement.current) {
      observer.current.observe(lastElement.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  if (parentElement) {
    parentElement.onscroll = () => {
      const hasUserScrolled = parentElement && parentElement.scrollTop > HINT_BOTTOM_TOLERANCE;

      if (hasUserScrolled) {
        setIsAtTop(false);
      }
    };
  }

  return {
    isButtonVisible: isFirstTimeShow && isShowHint,
    lastElement,
  };
};
