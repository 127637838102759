import { ServiceLightNames } from 'src/apps/NewDriverApp/types';

export const MILEAGE_INPUT_NAME = 'mileage';
export const INSPECTION_DUE_ICON_NAME = 'inspection-due';

export const WATCH_VALUES = [
  ServiceLightNames.BREAK_SYSTEM,
  ServiceLightNames.BATTERY,
  ServiceLightNames.OIL_LEVEL,
  ServiceLightNames.BREAK_WEAR,
  ServiceLightNames.ABS,
  ServiceLightNames.MOTOR_CONTROL,
  ServiceLightNames.TYRE_PRESSURE,
  ServiceLightNames.INSPECTION_DUE,
];
