import { useDispatch } from 'react-redux';

import { goCustomerSite } from '../redux/actions';

const useLeaveApp = () => {
  const dispatch = useDispatch();
  const leaveApp = () => dispatch(goCustomerSite());

  return leaveApp;
};

export { useLeaveApp };
