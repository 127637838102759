import { useEffect } from 'react';

export const useScreenResize = (callBack: () => void, dependencies: any[] = []) => {
  useEffect(() => {
    if (window) {
      window.addEventListener('resize', callBack);
    }

    return () => {
      window.removeEventListener('resize', callBack);
    };
  }, dependencies);
};
