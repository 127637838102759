import { createAction } from '@reduxjs/toolkit';

export const trivialDamageFlowSelected = createAction('TRIVIAL_DAMAGE_FLOW_SELECTED');
export const serviceLightsFlowSelected = createAction('SERVICE_LIGHTS_FLOW_SELECTED');
export const criticalFlowSelected = createAction('CRITICAL_FLOW_SELECTED');
export const interiorFlowSelected = createAction('INTERIOR_FLOW_SELECTED');
export const interiorProblemFlowSelected = createAction('INTERIOR_PROBLEM_FLOW_SELECTED');

export const accidentFlowSelected = createAction('ACCIDENT_FLOW_SELECTED');
export const accidentFlowWithoutOtherCarSelected = createAction(
  'ACCIDENT_FLOW_WITHOUT_OTHER_CAR_SELECTED',
);
export const selectSimpleAccidentFlow = createAction('SELECT_SIMPLE_ACCIDENT_FLOW');

export const checklistTemplateSelect = createAction<{
  checklistTemplateId: string;
}>('CHECKLIST_TEMPLATE_SELECT');

export const goToMenu = createAction('GO_TO_MENU');

export const mechanicalFlowSelected = createAction('MECHANICAL_FLOW_SELECTED');
