import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import { CarAvailabilityStatus } from 'src/api/globalTypes';

import {
  addNextPageCars,
  resetCarList,
  setCarListData,
  setCarListFilters,
  setCarInformationData,
  changeCarDetailsStatus,
  serReportNavigationData,
} from './actions';
import {
  CarDetailsReducer,
  CarListFilters,
  CarListFormFields,
  CarListReducer,
  CarReducer,
  ReportNavigation,
} from '../../../types';

const initialState: CarReducer = {
  carList: {
    isLoading: false,
    error: null,
    data: [],
    total: null,
    filters: {
      page: 0,
      [CarListFormFields.AVAILABILITY_FILTER]: [],
      [CarListFormFields.LICENSE_PLATE]: '',
    },
  },
  carDetails: {
    carInformation: {
      data: null,
      isLoading: false,
      error: null,
    },
    reportNavigation: {
      isLoading: false,
      error: null,
    },
  },
};

export const carReducer = createReducer(initialState, {
  [setCarListData.type]: (state: CarReducer, action: PayloadAction<Partial<CarListReducer>>) => {
    state.carList = merge(state.carList, action.payload);
  },
  [resetCarList.type]: (state: CarReducer) => {
    state.carList.data = [];
  },
  [addNextPageCars.type]: (
    state: CarReducer,
    action: PayloadAction<Pick<CarListReducer, 'total' | 'data' | 'filters'>>,
  ) => {
    const { data, total, filters } = action.payload;
    const updatedData = [...state.carList.data, ...data];
    const dataToMerge = {
      data: updatedData,
      total,
      filters,
    };

    state.carList = merge(state.carList, dataToMerge);
  },
  [setCarListFilters.type]: (state: CarReducer, action: PayloadAction<Partial<CarListFilters>>) => {
    state.carList.filters = { ...state.carList.filters, ...action.payload };
  },
  [setCarInformationData.type]: (
    state: CarReducer,
    action: PayloadAction<Partial<CarDetailsReducer>>,
  ) => {
    state.carDetails.carInformation = {
      ...state.carDetails.carInformation,
      ...action.payload,
    };
  },
  [changeCarDetailsStatus.type]: (
    state: CarReducer,
    action: PayloadAction<CarAvailabilityStatus>,
  ) => {
    if (state.carDetails.carInformation.data) {
      state.carDetails.carInformation.data.availability = action.payload;
    }
  },
  [serReportNavigationData.type]: (state: CarReducer, action: PayloadAction<ReportNavigation>) => {
    state.carDetails.reportNavigation = { ...state.carDetails.reportNavigation, ...action.payload };
  },
});
