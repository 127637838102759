import React, { forwardRef, useRef } from 'react';

import cn from 'classnames';
import merge from 'lodash/merge';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styles from './Layout.module.scss';
import { HeaderProps } from './types';
import { useActiveScroll } from '../../hooks';
import { getAssetsData } from '../../redux/selectors';
import Button from '../../ui-components/Button/Button';
import { ButtonProps } from '../../ui-components/Button/types';
import Header from '../../ui-components/Header';
import { LegalPages, LegalPagesProps } from '../../ui-components/LegalPages';
import { ScrollHint } from '../../ui-components/ScrollHint';

interface Props {
  children: React.ReactNode;
  className?: string;
  buttonProps?: ButtonProps & { titleComponent?: React.ReactNode };
  headerProps?: HeaderProps;
  legalPagesProps?: LegalPagesProps;
  style?: React.CSSProperties;
  withButtonDivider?: boolean;
  withLegalPages?: boolean;
  isScrollHelpingArrowVisible?: boolean;
  isStickyFooter?: boolean;
}

const Layout = forwardRef(
  (
    {
      className,
      children,
      buttonProps,
      headerProps,
      legalPagesProps,
      style,
      withButtonDivider = true,
      withLegalPages = true,
      isScrollHelpingArrowVisible = true,
      isStickyFooter = false,
    }: Props,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const { t } = useTranslation('common');
    const assets = useSelector(getAssetsData);
    const { titleComponent, ...restButtonProps } = buttonProps || {};

    const parentElementRef = useRef<HTMLDivElement | null>(null);

    const { isButtonVisible, lastElement } = useActiveScroll(
      parentElementRef,
      isScrollHelpingArrowVisible,
    );

    const defaultButtonProps = {
      children: t('common:continue'),
    };

    const assetsLoadingButtonProps = assets.isLoading
      ? {
          disabled: true,
          isLoading: true,
        }
      : {};

    const compoundButtonProps = merge(
      defaultButtonProps,
      restButtonProps,
      assetsLoadingButtonProps,
    );

    const bottomPanel = (
      <div
        ref={lastElement}
        className={cn(styles.bottomContainer, { [styles.stickyFooter]: isStickyFooter })}
      >
        {buttonProps && (
          <div className={cn(styles.btnContainer, { [styles.buttonDivider]: withButtonDivider })}>
            {titleComponent || <Button {...compoundButtonProps} />}
          </div>
        )}
        {withLegalPages && (
          <div className={styles.footerBottomContainer}>
            <LegalPages {...legalPagesProps} />
          </div>
        )}
      </div>
    );

    return (
      <div style={style} className={cn(styles.container, className)}>
        {headerProps && <Header {...headerProps} />}
        <main
          id="mainLayout"
          ref={ref}
          className={cn(styles.content, { [styles.noButtonPadding]: !buttonProps })}
        >
          <div className={styles.mainContentWrapper} ref={parentElementRef}>
            {children}
            {bottomPanel}
            {isButtonVisible && <ScrollHint />}
          </div>
        </main>
      </div>
    );
  },
);

export default Layout;
