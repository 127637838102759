import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';
import merge from 'lodash/merge';

import { DriverInformation } from 'src/api/globalTypes';
import { ImageState, ImageUploadStatus } from 'src/apps/NewDriverApp/redux/types/Types';
import { ServiceLightNames, DamagePhotosCameraContext } from 'src/apps/NewDriverApp/types';

import { MILEAGE_INPUT_NAME } from '../../ServiceLightsDashboard/constants';
import {
  ServiceLightsFormModel,
  ServiceLightsReportReducerState,
  ServiceLightsReportSavingStatus,
} from '../../types';
import {
  serviceLightsDashboardCompleted,
  serviceLightsImageUploadFailure,
  serviceLightsImageUploadProgressUpdate,
  serviceLightsImageUploadStart,
  serviceLightsImageUploadSuccess,
  setServiceLightsPhoto,
  updateServiceLightsDriverInformation,
  setServiceLightsDriverInformation,
  updateServiceLightsReportSavingStatus,
  removeServiceLightsImage,
} from '../actions';

const initialState: ServiceLightsReportReducerState = {
  images: [],
  serviceLightsData: {
    [ServiceLightNames.BREAK_SYSTEM]: undefined,
    [ServiceLightNames.BATTERY]: undefined,
    [ServiceLightNames.OIL_LEVEL]: undefined,
    [ServiceLightNames.BREAK_WEAR]: undefined,
    [ServiceLightNames.ABS]: undefined,
    [ServiceLightNames.MOTOR_CONTROL]: undefined,
    [ServiceLightNames.TYRE_PRESSURE]: undefined,
    [ServiceLightNames.DIFFERENTIAL]: undefined,
    [ServiceLightNames.SYSTEM_MALFUNCTION]: undefined,
    [ServiceLightNames.COOLANT]: undefined,
    [ServiceLightNames.AIRBAG]: undefined,
    [ServiceLightNames.STOP]: undefined,
    [ServiceLightNames.INSPECTION_DUE]: undefined,
    [ServiceLightNames.AD_BLUE]: undefined,
    [MILEAGE_INPUT_NAME]: undefined,
  },
  driverInformation: {
    driverName: null,
    driverEmail: null,
    driverPhone: null,
    driverAddress: null,
    driverCity: null,
    driverZip: null,
  },
  savingStatus: {
    isCompleting: false,
    isCompleted: false,
    error: null,
  },
};

export const serviceLightsReportReducer = createReducer(initialState, {
  [updateServiceLightsDriverInformation.type]: (
    state,
    action: PayloadAction<{ data: DriverInformation }>,
  ) => {
    state.driverInformation = action.payload.data;
  },
  [setServiceLightsDriverInformation.type]: (state, action) => {
    return merge({}, state, {
      driverInformation: {
        ...action.payload,
      },
    });
  },
  [serviceLightsDashboardCompleted.type]: (
    state,
    action: PayloadAction<{ data: ServiceLightsFormModel }>,
  ) => {
    state.serviceLightsData = action.payload.data;
  },

  [setServiceLightsPhoto.type]: (
    state,
    action: PayloadAction<ImageState<DamagePhotosCameraContext>>,
  ) => {
    const type = get(action, 'payload.context.imageType');

    const filteredImages = state.images.filter(image => {
      const imageType = get(image, 'context.imageType');

      return imageType !== type;
    });
    state.images = [...filteredImages, action.payload];
  },
  [serviceLightsImageUploadStart.type]: (state, action: PayloadAction<{ internalId: string }>) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.internalId,
    );

    state.images[imageIndex].uploadingStatus = ImageUploadStatus.STARTED;
  },
  [serviceLightsImageUploadProgressUpdate.type]: (
    state,
    action: PayloadAction<{ internalId: string; uploadProgress: number }>,
  ) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.internalId,
    );
    state.images[imageIndex].uploadProgress = action.payload.uploadProgress;
  },
  [serviceLightsImageUploadSuccess.type]: (
    state,
    action: PayloadAction<{ image: ImageState; fullUrl: string; id: string }>,
  ) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.image.internalId,
    );

    const stateImage = state.images[imageIndex];

    state.images[imageIndex] = {
      ...stateImage,
      id: action.payload.id,
      uploadingStatus: ImageUploadStatus.SUCCESS,
      fullUrl: action.payload.fullUrl,
    };
  },
  [serviceLightsImageUploadFailure.type]: (
    state,
    action: PayloadAction<{ image: ImageState; error: Error }>,
  ) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.image.internalId,
    );

    const stateImage = state.images[imageIndex];

    state.images[imageIndex] = {
      ...stateImage,
      uploadingStatus: ImageUploadStatus.FAILURE,
      error: action.payload.image.error || action.payload.error,
    };
  },
  [removeServiceLightsImage.type]: (state, action: PayloadAction<{ internalId: string }>) => {
    state.images = state.images.filter(image => image.internalId !== action.payload.internalId);
  },
  [updateServiceLightsReportSavingStatus.type]: (
    state,
    action: PayloadAction<{ data: ServiceLightsReportSavingStatus }>,
  ) => {
    state.savingStatus = action.payload.data;
  },
});
