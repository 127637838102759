import { resolveKeyByExtendedPermissionsResource } from 'src/apps/NewDriverApp/hooks/useExtendedPermissions/utils/resolveKeyByExtendedPermissionsResource';
import { resolveValueByExtendedPermissionsAction } from 'src/apps/NewDriverApp/hooks/useExtendedPermissions/utils/resolveValueByExtendedPermissionsAction';

const EXTENDED_PERMISSION_TOKENIZATION_CHARACTER = ':';
export const resolveExtendedPermissions = (permission: string) => {
  const [resourceToken, actionToken] = permission.split(EXTENDED_PERMISSION_TOKENIZATION_CHARACTER);
  const key = resolveKeyByExtendedPermissionsResource(resourceToken);
  const value = resolveValueByExtendedPermissionsAction(actionToken);

  return key
    ? {
        [key]: value,
      }
    : null;
};
