import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { MechanicalProblemReducer } from '../../types';
import * as actions from '../actions';

const initialState: MechanicalProblemReducer = {
  problems: [],
  problemDescription: '',
};

export const mechanicalProblemReducer = createReducer(initialState, {
  [actions.updateMechanicalProblemData.type]: (
    state,
    action: PayloadAction<MechanicalProblemReducer>,
  ) => {
    state.problems = action.payload.problems;
    state.problemDescription = action.payload.problemDescription;
  },
});
