import { combineReducers, createReducer, PayloadAction } from '@reduxjs/toolkit';

import { checklistCurrentDamageReducer } from './checklist-current-damage-reducer';
import {
  addDamageReport,
  saveDamageReport,
  deleteDamageReport,
  setInitialChecklistDamages,
  updateChecklistDamageReportImage,
} from '../actions';
import { ChecklistDamageReport, UpdateChecklistImageActionPayload } from '../types';

const initialState: ChecklistDamageReport[] = [];

const damageReportsReducer = createReducer(initialState, {
  [setInitialChecklistDamages.type]: (
    state,
    action: PayloadAction<{ damages: ChecklistDamageReport[] }>,
  ) => action.payload.damages || [],
  [addDamageReport.type]: (
    state,
    action: PayloadAction<{ damageReport: ChecklistDamageReport }>,
  ) => {
    state.push(action.payload.damageReport);
  },
  [saveDamageReport.type]: (
    state,
    action: PayloadAction<{ damageReport: ChecklistDamageReport }>,
  ) => {
    return state.map(damage => {
      if (damage.id === action.payload.damageReport.id) {
        return action.payload.damageReport;
      }
      return damage;
    });
  },
  [deleteDamageReport.type]: (state, action: PayloadAction<{ damageReportId: string }>) => {
    return state.filter(damage => damage.id !== action.payload.damageReportId);
  },
  [updateChecklistDamageReportImage.type]: (
    state,
    action: PayloadAction<{ damageReportId: string; data: UpdateChecklistImageActionPayload }>,
  ) => {
    const { damageReportId, data } = action.payload;
    const damageReportIndex = state.findIndex(damage => damage.id === damageReportId);

    if (damageReportIndex === -1) {
      return state;
    }

    const damageReport = state[damageReportIndex];
    const damageReportImages = [...(damageReport.images || [])];
    const updateImageIndex = damageReportImages.findIndex(
      image => image.internalId === data.internalId,
    );

    if (updateImageIndex === -1) {
      return state;
    }

    damageReportImages[updateImageIndex] = {
      ...damageReportImages[updateImageIndex],
      ...data,
    };

    const updatedDamageReport = {
      ...damageReport,
      images: damageReportImages,
    };

    const updatedReports = [...state];
    updatedReports[damageReportIndex] = updatedDamageReport;

    return updatedReports;
  },
});

export const checklistDamageReportsReducer = combineReducers({
  damages: damageReportsReducer,
  currentDamage: checklistCurrentDamageReducer,
});
