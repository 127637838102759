import { useEffect, useRef } from 'react';

const usePreviousValue = <T>(value: T) => {
  const previousRef = useRef<T | undefined>();

  useEffect(() => {
    previousRef.current = value;
  }, [value]);

  return previousRef.current;
};

export { usePreviousValue };
