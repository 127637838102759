import React, { SyntheticEvent, forwardRef } from 'react';

import cx from 'classnames';

import styles from './ContentArea.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: (e: SyntheticEvent) => void;
  noMargin?: boolean;
}

const ContentArea = forwardRef<HTMLDivElement, Props>(
  ({ className, children, style, onClick, noMargin }, ref) => {
    return (
      <div
        ref={ref}
        onClick={onClick}
        style={style}
        className={cx(styles.container, className, { [styles.noMargin]: noMargin })}
      >
        {children}
      </div>
    );
  },
);

export { ContentArea };
