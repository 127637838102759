import { useSelector } from 'react-redux';

import { getDamageKey } from '../redux/selectors';

export const useGetLinkQuery = () => {
  const damageReportCode = useSelector(getDamageKey);

  const getLinkQuery = (params: { [key: string]: string }) => {
    return new URLSearchParams({
      code: damageReportCode || '',
      ...params,
    }).toString();
  };

  return getLinkQuery;
};
