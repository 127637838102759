import { AppSegmentType } from 'src/api/globalTypes';
import { TENANT } from 'src/constants';

export interface TrackingIdentity {
  sessionId?: string;
  extra: Record<string, string | null | undefined | any>;
}

export const TRACKING_IDENTITY_EVENT = 'tracking-identity';

export type TrackingConfig<T = number> = Record<AppSegmentType, TrackingConfigItem<T>>;

export type TrackingConfigItem<T> = Record<string | 'default', T>;

export const getTrackingCode = <T>(config: TrackingConfigItem<T>) => {
  if (!config) {
    return null;
  }

  return config[TENANT] || config.default;
};

export const setTrackingIdentity = (identity: TrackingIdentity) => {
  const event = new CustomEvent(TRACKING_IDENTITY_EVENT, { detail: identity });
  document.dispatchEvent(event);
};
