import { titleFromUri } from 'src/apps/NewDriverApp/utils/titleFromUri';

export const pageNameTelemetry = (telemetry: {
  baseData: { name: string; uri: string | undefined };
  baseType: string;
}) => {
  if (telemetry.baseData && telemetry.baseType === 'PageviewData') {
    telemetry.baseData.name = titleFromUri(telemetry.baseData.uri);
  }
};
