import { createReducer } from '@reduxjs/toolkit';

import {
  updateDriverInformationData,
  setDriverInformationData,
} from 'src/apps/NewDriverApp/redux/actions';

import { completeMechanicalDriverInformationData } from '../../flows/fleet/mechanical/redux/actions';
import { DriverInformationReducer } from '../types/Types';

const initialState: DriverInformationReducer = {
  isChanged: false,
  driverInformation: {},
};

export const driverInformationReducer = createReducer(initialState, {
  [updateDriverInformationData.type]: (state, { payload }) => {
    state.driverInformation = payload.data;
  },
  [setDriverInformationData.type]: (state, { payload }) => {
    state.driverInformation = {
      ...state.driverInformation,
      ...payload,
      ...{
        driverName: payload.driverName || payload.reporterName,
        driverEmail: payload.driverEmail || payload.reporterEmail,
      },
    };
    state.isChanged = true;
  },
  [completeMechanicalDriverInformationData.type]: (state, { payload }) => {
    state.driverInformation = payload.data;
  },
});
