import { AppSegmentType } from 'src/api/globalTypes';

import { CODE_URL_PARAM_KEY, MANAGER_CODE_KEY, WebAppFlows } from '../constants';
import { FLEET_MANAGER_DOMAIN } from '../flows/fleet/fleet-manager/constants';
import { PersistStorage } from '../redux/persist-service';
import { getStoredInitParams, parseJWTToken } from '../redux/sagas/initApp/init-app-common';
import { PersistType } from '../redux/types/Types';

export const isFleetManagerApp = (segment: AppSegmentType) => {
  if (segment !== AppSegmentType.FLEETS) {
    return false;
  }

  const localStorage = PersistStorage.getStorageService(PersistType.PERSIST);
  const userToken = localStorage.getItem(MANAGER_CODE_KEY);
  const params = new URLSearchParams(document.location.search.slice(1));
  const codeFromParams = params.get(CODE_URL_PARAM_KEY);
  const initParams = getStoredInitParams();
  const code = codeFromParams || initParams?.code;
  const decodedJwt = parseJWTToken<{ flow?: WebAppFlows } | null>(code);
  const { flow } = decodedJwt || {};
  const isVerificationFlow = flow === WebAppFlows.DAMAGE_MODIFY;

  return (
    !!userToken || window.location.pathname.includes(FLEET_MANAGER_DOMAIN) || isVerificationFlow
  );
};
