import { useEffect, useState } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LANG } from 'src/constants';

import { appScreenConfiguration } from '../redux/selectors';
import { DriverFacingAppScreens, I18nTranslations } from '../types';

export default function useScreenCustomization({
  namespace,
  screen,
  setApplied,
  customOverrides,
}: {
  namespace: string;
  screen?: DriverFacingAppScreens;
  setApplied?: () => void;
  customOverrides?: I18nTranslations;
}) {
  const { i18n } = useTranslation(namespace);
  const [, updateState] = useState<unknown>();
  const forceUpdate = () => updateState({});
  const screenOverrides = useSelector(appScreenConfiguration(screen));
  const overrides = customOverrides || screenOverrides;

  useEffect(() => {
    if (overrides) {
      (Object.keys(overrides) as LANG[]).forEach((lang: LANG) => {
        const override = cloneDeep(overrides[lang]);

        i18n.addResourceBundle(lang, namespace, override, true, true);

        // updates a component when the resource is added
        forceUpdate();
      });

      if (setApplied) {
        setApplied();
      }
    }
  }, [overrides]);
}
