import get from 'lodash/get';
import { createSelector } from 'reselect';

import { AppFeatureType } from 'src/api/globalTypes';
import { InitState as AppState } from 'src/apps/NewDriverApp/redux/types/AppState';

import {
  appConfigurationSelector,
  getCarKey,
  getIsWebAppFlow,
  getIsWebAppFlowAllowed,
} from './selectors';
import { WebAppFlows } from '../../constants';
import { getChecklistTemplates } from '../../flows/fleet/menu/redux/selectors';
import { AppConfiguration_appConfiguration_features } from '../../graphql/queries/__generated__/AppConfiguration';

type AppFeature = AppConfiguration_appConfiguration_features;

export const appFeatures = createSelector(appConfigurationSelector, (appConfig: AppState) => {
  if (appConfig && appConfig.configuration) {
    return appConfig.configuration.features;
  }
  return [];
});

const getFeature = (_: any, feature: AppFeatureType) => feature;

export const appIncludedFeatures = createSelector(
  appFeatures,
  getFeature,
  (features: AppFeature[], featureName: AppFeatureType) => {
    return !!features.find(feature => feature.name === featureName);
  },
);

export const getAppFeature = createSelector(
  appFeatures,
  getFeature,
  (features: AppFeature[], featureName: AppFeatureType) => {
    return features.find(feature => feature.name === featureName);
  },
);

export const segmentSelector = createSelector(appConfigurationSelector, (appConfig: AppState) => {
  return get(appConfig, 'configuration.segment', null);
});

export const getHasFeatureList = createSelector(appConfigurationSelector, ({ configuration }) => {
  return (featureNames: string[]): boolean => {
    return get(configuration, 'features', []).some(item => featureNames.includes(item.name));
  };
});

export const getHasAccidentFeatures = createSelector(
  getIsWebAppFlowAllowed,
  getHasFeatureList,
  (isWebAppFlowAllowed, hasFeatureList) => {
    return (
      isWebAppFlowAllowed(WebAppFlows.ACCIDENT) &&
      hasFeatureList([
        AppFeatureType.ACCIDENT_REPORT,
        AppFeatureType.ACCIDENT_WITHOUT_ANOTHER_CAR_REPORT,
        AppFeatureType.SIMPLE_ACCIDENT_REPORT,
      ])
    );
  },
);

export const getHasDamageFeatures = createSelector(
  getIsWebAppFlowAllowed,
  getHasFeatureList,
  (isWebAppFlowAllowed, hasFeatureList) => {
    const hasDamageAllowedFlow = isWebAppFlowAllowed([
      WebAppFlows.EXTERIOR,
      WebAppFlows.INTERIOR,
      WebAppFlows.SERVICE_LIGHTS,
      WebAppFlows.MECHANICAL,
      WebAppFlows.DAMAGE_REPORT_UPDATE,
    ]);

    return (
      hasDamageAllowedFlow &&
      hasFeatureList([
        AppFeatureType.EXTERIOR_DAMAGE_REPORT,
        AppFeatureType.INTERIOR_DAMAGE_REPORT,
        AppFeatureType.SERVICE_LIGHTS_REPORT,
        AppFeatureType.MECHANICAL_DAMAGE_REPORT,
        AppFeatureType.CAR_CHECK,
      ])
    );
  },
);

export const getHasChecklistFeature = createSelector(
  getIsWebAppFlowAllowed,
  getIsWebAppFlow,
  getChecklistTemplates,
  getCarKey,
  (isWebAppFlowAllowed, isWebAppFlow, checklistTemplates, carAuth) => {
    return (
      ((isWebAppFlowAllowed(WebAppFlows.CHECKLIST) && Boolean(carAuth)) ||
        isWebAppFlow(WebAppFlows.CHECKLIST)) &&
      Boolean(checklistTemplates.length)
    );
  },
);

export const getHasFeature = createSelector(appConfigurationSelector, ({ configuration }) => {
  return (featureName: string): boolean => {
    return get(configuration, 'features', []).some(item => item.name === featureName);
  };
});
