import React, { createElement, forwardRef } from 'react';

import cn from 'classnames';

import { TEXT_COLOR, TEXT_TYPE, TEXT_TYPE_TAG } from './constants';
import styles from './Text.module.scss';
import { TextColor, TextType } from './types';

// https://www.figma.com/file/VrbCFUoiMT1jxeKwlyc9Zx/App-Design-Style-Guide?node-id=1195%3A90&mode=dev

interface Props extends React.HTMLAttributes<HTMLElement> {
  type?: TextType;
  color?: TextColor;
}

const Text = (
  {
    children,
    className,
    color = TEXT_COLOR.PRIMARY_CONTENT,
    type = TEXT_TYPE.BODY_BASE,
    ...restProps
  }: Props,
  ref: React.LegacyRef<HTMLElement>,
) => {
  const typeClass = styles[type];
  const colorClass = styles[color];
  const tagName = TEXT_TYPE_TAG[type];

  return createElement(
    tagName,
    { className: cn(typeClass, colorClass, className), ref, ...restProps },
    children,
  );
};

export default forwardRef(Text);
