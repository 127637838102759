import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { accidentScenePageCompleted } from '../actions';
import { AccidentSceneReducer } from '../types';

const initialState: AccidentSceneReducer = {
  location: '',
  datetime: '',
};

export const accidentSceneReducer = createReducer(initialState, {
  [accidentScenePageCompleted.type]: (state, action: PayloadAction<AccidentSceneReducer>) => {
    return {
      ...state,
      ...action.payload,
    };
  },
});
