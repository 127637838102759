import React from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as EditSvg } from 'src/img/other-icons/px-pencil.svg';

import styles from './StepDescription.module.scss';
import Text from '../Text';

interface Props {
  title?: string | React.ReactNode;
  text?: string | React.ReactNode;
  textAfter?: string | React.ReactNode;
  id?: string;
  onEdit?: () => void;
  className?: string;
}

const StepDescription: React.FC<Props> = ({ title, text, id, onEdit, textAfter, className }) => {
  const { t } = useTranslation('overview');
  return (
    <section id={id} className={className}>
      {title && (
        <div className={styles.titleContainer}>
          <Text type="h2">{title}</Text>
          {onEdit && (
            <div className={styles.editContainer} onClick={onEdit}>
              <EditSvg className={styles.editIcon} />{' '}
              <Text type="body-middle-medium" color="secondary-content" className={styles.edit}>
                {t('overview:card.edit')}
              </Text>
            </div>
          )}
        </div>
      )}
      {text && (
        <Text color="secondary-content">
          {text}
          {textAfter}
        </Text>
      )}
    </section>
  );
};

export { StepDescription };
