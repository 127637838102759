import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { DAMAGE_REASONS } from 'src/apps/NewDriverApp/constants';

import {
  selectAccidentReason,
  selectAccidentWithCarReason,
  setAccidentReasonDescription,
  unselectAccidentReason,
  unselectAccidentWithCarReason,
} from '../actions';

export interface AccidentReasonsReducer {
  selectedReasons: DAMAGE_REASONS[];
  selectedReasonsWithCar: DAMAGE_REASONS[];
  reasonDescription: string;
}

const initialState: AccidentReasonsReducer = {
  selectedReasons: [],
  selectedReasonsWithCar: [],
  reasonDescription: '',
};

export const accidentReasonsReducer = createReducer(initialState, {
  [selectAccidentReason.type]: (state, action: PayloadAction<DAMAGE_REASONS>) => {
    state.selectedReasons = [...state.selectedReasons, action.payload];
  },
  [unselectAccidentReason.type]: (state, action: PayloadAction<DAMAGE_REASONS>) => {
    state.selectedReasons = state.selectedReasons.filter(type => type !== action.payload);
  },

  [selectAccidentWithCarReason.type]: (state, action: PayloadAction<DAMAGE_REASONS>) => {
    state.selectedReasonsWithCar = [...state.selectedReasonsWithCar, action.payload];
  },
  [unselectAccidentWithCarReason.type]: (state, action: PayloadAction<DAMAGE_REASONS>) => {
    state.selectedReasonsWithCar = state.selectedReasonsWithCar.filter(
      type => type !== action.payload,
    );
  },

  [setAccidentReasonDescription.type]: (state, { payload }) => {
    state.reasonDescription = payload;
  },
});
