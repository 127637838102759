import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { removeCameraPhoto } from 'src/apps/NewDriverApp/ui-components/Camera/redux/actions';

import { ImageUploadStatus, ImageFromCamera } from '../../../../../redux/types/Types';
import {
  setChecklistPhoto,
  checklistImageUploadSuccess,
  checklistImageUploadFailure,
  checklistImageUploadProgressUpdate,
  setInitialChecklistImages,
} from '../actions';
import { CheckListImageState } from '../types';

export interface ChecklistImagesReducerState {
  images: CheckListImageState[];
}

const initialState: ChecklistImagesReducerState = {
  images: [],
};

export const checklistImagesReducer = createReducer(initialState, {
  [removeCameraPhoto.type]: (state, action: PayloadAction<{ selectedPhoto: ImageFromCamera }>) => {
    return {
      ...state,
      images: [...state.images].filter(
        image => image.internalId !== action.payload.selectedPhoto.internalId,
      ),
    };
  },
  [setChecklistPhoto.type]: (state, action) => {
    const { internalId } = action.payload;

    const isImageExist = state.images.some(image => image.internalId === internalId);

    if (isImageExist) {
      const images = state.images.map(image => {
        if (image.internalId === internalId) {
          return action.payload;
        }
        return image;
      });

      return {
        ...state,
        images,
      };
    }

    return {
      ...state,
      images: [...state.images, action.payload],
    };
  },
  [checklistImageUploadProgressUpdate.type]: (
    state,
    action: PayloadAction<{ internalId: string; uploadProgress: number }>,
  ) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.internalId,
    );

    if (imageIndex !== -1) {
      state.images[imageIndex].uploadProgress = action.payload.uploadProgress;
    }
  },
  [checklistImageUploadSuccess.type]: (
    state,
    action: PayloadAction<{
      internalId: string;
      fullUrl: string;
      id: string;
      context: CheckListImageState['context'];
    }>,
  ) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.internalId,
    );

    state.images[imageIndex].uploadingStatus = ImageUploadStatus.SUCCESS;
    state.images[imageIndex].id = action.payload.id;
    state.images[imageIndex].fullUrl = action.payload.fullUrl;
    state.images[imageIndex].context = action.payload.context;
  },
  [checklistImageUploadFailure.type]: (
    state,
    action: PayloadAction<{
      internalId: string;
      error: Error;
      context: CheckListImageState['context'];
    }>,
  ) => {
    const imageIndex = state.images.findIndex(
      item => item.internalId === action.payload.internalId,
    );

    state.images[imageIndex].uploadingStatus = ImageUploadStatus.FAILURE;
    state.images[imageIndex].error = action.payload.error;
    state.images[imageIndex].context = action.payload.context;
  },
  [setInitialChecklistImages.type]: (
    state,
    action: PayloadAction<{ images: CheckListImageState[] }>,
  ) => {
    state.images = action.payload.images;
  },
});
