import React, { useState } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { LanguageOptionItem } from './LanguageOptionItem';
import { lngOptions } from './languages';
import styles from './LanguageSwitcher.module.scss';

interface Props {
  locales: string[];
  onChange?: () => void;
  className?: string;
  classNameItem?: string;
  onLoadStarted?: () => void;
  onLoadFinished?: () => void;
}

const LanguageSwitcher: React.FC<Props> = ({
  onChange,
  className,
  classNameItem,
  locales,
  onLoadStarted,
  onLoadFinished,
}) => {
  const { i18n } = useTranslation();
  const [loadingLanguage, setLoadingLanguage] = useState<null | string>(null);

  const languagesList = lngOptions.filter(({ name }) => locales.includes(name));

  const changeLanguage = async (lng: string) => {
    try {
      if (onLoadStarted) {
        onLoadStarted();
      }

      setLoadingLanguage(lng);
      await i18n.reloadResources([lng], i18n.options.ns);
      await i18n.changeLanguage(lng);

      if (onChange) {
        onChange();
      }
    } finally {
      if (onLoadFinished) {
        onLoadFinished();
      }

      setLoadingLanguage(null);
    }
  };

  return (
    <div className={cx(styles.listGroup, className)}>
      {languagesList.map(({ name, nativeName, icon }) => {
        const isLoading = loadingLanguage === name;

        return (
          <LanguageOptionItem
            key={name}
            name={nativeName}
            onClick={() => changeLanguage(name)}
            isChecked={i18n.language === name}
            FlagIcon={icon}
            className={classNameItem}
            isLoading={isLoading}
          />
        );
      })}
    </div>
  );
};

export { LanguageSwitcher };
