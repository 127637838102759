import { IApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';

import { clearSensitiveData } from './initializers/clearSensitiveDataFromTelemetry';
import { customDimensionTelemetry } from './initializers/customDimensionsTelemetry';
import { flowTrackerTelemetry } from './initializers/flowTelemetry';
import { pageNameTelemetry } from './initializers/pageNameTelemetry';
import { ExtendedApplicationInsights, GRAPHQL_OPERATION_TRACE_TYPE, TRACE_MESSAGE } from './types';

// @ts-ignore
const { appInsights } = window as { appInsights: IApplicationInsights };

const extendedAppInsights: ExtendedApplicationInsights = (appInsights ||
  {}) as ExtendedApplicationInsights;

if (appInsights) {
  // Deeply copy original appInsights instance
  const initialAppInsights: IApplicationInsights = Object.create(
    Object.getPrototypeOf(appInsights),
    Object.getOwnPropertyDescriptors(appInsights),
  );

  extendedAppInsights.trackException = function ({ exception: error }, customProperties) {
    if (error?.message && this.loggerContext) {
      const optionallyPrefixedErrorMessaged = `${this.loggerContext}: ${error.message}`;

      error.message = optionallyPrefixedErrorMessaged;
    }

    return initialAppInsights.trackException({ exception: error }, customProperties);
  };

  extendedAppInsights.trackGraphQLOperation = function (
    payload: object,
    type: GRAPHQL_OPERATION_TRACE_TYPE,
  ) {
    const mapTraceTypeToMessage = {
      [GRAPHQL_OPERATION_TRACE_TYPE.START]: TRACE_MESSAGE.GRAPHQL_OPERATION_START,
      [GRAPHQL_OPERATION_TRACE_TYPE.END]: TRACE_MESSAGE.GRAPHQL_OPERATION_END,
    };

    this.trackTrace(
      {
        message: mapTraceTypeToMessage[type],
      },
      {
        ...payload,
      },
    );
  };

  extendedAppInsights.addTelemetryInitializer(
    pageNameTelemetry as unknown as (item: ITelemetryItem) => boolean | void,
  );
  extendedAppInsights.addTelemetryInitializer(customDimensionTelemetry);
  extendedAppInsights.addTelemetryInitializer(flowTrackerTelemetry());
  extendedAppInsights.addTelemetryInitializer(clearSensitiveData);

  if (localStorage.getItem('debugTelemetry')) {
    extendedAppInsights.addTelemetryInitializer((e: any) => {
      console.log('Telemetry', e);
    });
  }
}

const getLogger = (context?: string) => {
  // FIXME: This is not working, appInsights is a complex object with internal state and cloning it triggers side effects on react state change
  // https://motum-by-repairfix.atlassian.net/browse/REP-9234

  // const clonedAppInsights: ExtendedApplicationInsights = Object.create(
  //   Object.getPrototypeOf(extendedAppInsights),
  //   Object.getOwnPropertyDescriptors(extendedAppInsights),
  // );

  // if (context) {
  //   clonedAppInsights.loggerContext = context;
  // }

  // return clonedAppInsights;

  return extendedAppInsights;
};

export { getLogger };
