import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { BodyStyle } from 'src/api/globalTypes';

import { getDamageReportInfoSuccess, carBodyTypesCompleted } from '../actions';

export interface BodyTypeReducerState {
  carBodyType: BodyStyle | null;
  saving: boolean;
  savingError: null | Error;
}

const initialState: BodyTypeReducerState = {
  carBodyType: null,
  saving: false,
  savingError: null,
};

export const bodyTypesReducer = createReducer(initialState, {
  [carBodyTypesCompleted.type]: (state, action: PayloadAction<{ carBodyType: BodyStyle }>) => {
    state.carBodyType = action.payload.carBodyType;
  },
  [getDamageReportInfoSuccess.type]: (state, { payload }) => {
    state.carBodyType = get(payload, 'info.car.bodyStyle');
  },
});
