import { combineReducers } from '@reduxjs/toolkit';

import { appReducer } from 'src/apps/NewDriverApp/redux/reducers/app-reducer';
import { assetsReducer } from 'src/apps/NewDriverApp/redux/reducers/assets-reducer';
import { authReducer } from 'src/apps/NewDriverApp/redux/reducers/auth-reducer';
import { reportReducer } from 'src/apps/NewDriverApp/redux/reducers/report-reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  report: reportReducer,
  assets: assetsReducer,
  app: appReducer,
});
export type RootState = ReturnType<typeof rootReducer>;
export { rootReducer };
