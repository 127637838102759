import React from 'react';

import Text from 'src/apps/NewDriverApp/ui-components/Text';

import styles from './ServiceLightItem.module.scss';
import ServiceLightsOverviewWarning, {
  ServiceLightWaring,
} from '../../ServiceLightsOverview/components/ServiceLightsOverviewBlock/ServiceLightsOverviewWarning';

interface Props {
  color: string;
  text: string;
  IconComponent: React.FunctionComponent<{ color?: string }>;
  isDisplayWarning?: boolean;
  warningType?: ServiceLightWaring;
}

const ServiceLightItem: React.FC<Props> = ({
  IconComponent,
  color,
  text,
  isDisplayWarning,
  warningType,
}) => (
  <>
    <span className={styles.container}>
      <span className={styles.iconContainer}>
        <IconComponent color={color} />
      </span>
      <Text className={styles.text}>{text}</Text>
    </span>
    {isDisplayWarning && <ServiceLightsOverviewWarning type={warningType} />}
  </>
);

export default React.memo(ServiceLightItem);
