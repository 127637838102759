import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import InfiniteLoader from 'src/apps/NewDriverApp/ui-components/InfiniteLoader/InfiniteLoader';
import ClickableArea from 'src/components/ClickableArea';

import styles from './DamageImagePreview.module.scss';

interface Props {
  src: string;
  onClick: (index: number) => void;
  index: number;
  isDisplayQuantity?: boolean;
  quantity?: number;
  className?: string;
}

const DamageImagePreview: React.FC<Props> = ({
  src,
  onClick,
  index,
  isDisplayQuantity,
  quantity,
  className,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const onClickImage = useCallback(() => {
    onClick(index);
  }, []);

  const onLoad = () => setIsLoaded(true);

  return (
    <ClickableArea onClick={onClickImage} className={cn(styles.container, className)}>
      <img
        className={cn(styles.image, { [styles.notVisible]: !isLoaded })}
        src={src}
        alt="Preview damage"
        onLoad={onLoad}
      />
      {isDisplayQuantity && !!quantity && <div className={styles.overlay}>{`+${quantity}`}</div>}
      {!isLoaded && (
        <div className={styles.loader}>
          <InfiniteLoader />
        </div>
      )}
    </ClickableArea>
  );
};

export default DamageImagePreview;
