import { getLogger } from 'src/services/app-insights';

const PERFRORMANCE_LOGGER_CONTEXT_IDENTIFIER = 'performance';

const appInsights = getLogger(PERFRORMANCE_LOGGER_CONTEXT_IDENTIFIER);

export enum PERFORMANCE_MARK {
  APP_LOADER_LOADED = 'APP_LOADER_LOADED',
  ONBOARDING_PAGE_LOADED = 'ONBOARDING_PAGE_LOADED',
}

/**
 * Measure timing from request start to now()
 *
 * @param mark {PERFORMANCE_MARK}
 */
export const measurePerformance = (mark: string) => {
  performance.mark(mark);
  setTimeout(() => {
    performance.measure(mark, undefined, mark);
    const [result] = performance.getEntriesByType('measure');
    performance.clearMeasures();

    appInsights.trackMetric(
      {
        name: mark,
        average: result.duration / 1000,
      },
      {
        tenant: document.location.hostname.split('.')[0],
      },
    );
  }, 1000);
};

Object.assign(window, {
  measurePerformence: measurePerformance,
});
