import { useState, MutableRefObject, useEffect } from 'react';

import debounce from 'lodash/debounce';

import { usePreviousValue } from 'src/apps/NewDriverApp/hooks/usePreviousValue';

const DEFAULT_SCROLL_DEBOUNCE_TIME = 130;

export const useCollapsibleHeader = (scrollableRef: MutableRefObject<HTMLDivElement | null>) => {
  const [isHeaderCollapsed, setIsHeaderCollapsed] = useState(false);

  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const prevLastScrollPosition = usePreviousValue(lastScrollPosition) || 0;

  useEffect(() => {
    if (lastScrollPosition > prevLastScrollPosition) {
      setIsHeaderCollapsed(true);
    }
    if (lastScrollPosition < prevLastScrollPosition) {
      setIsHeaderCollapsed(false);
    }
  }, [lastScrollPosition, prevLastScrollPosition]);

  const handleScroll = debounce(
    () => {
      if (scrollableRef.current) {
        const scrollTop = Math.trunc(scrollableRef.current.scrollTop);
        const computedScrollTop = Math.trunc(
          scrollableRef.current.scrollHeight - scrollableRef.current.offsetHeight,
        );

        if (scrollTop !== computedScrollTop) {
          setLastScrollPosition(scrollableRef.current.scrollTop);
        }
      }
    },
    DEFAULT_SCROLL_DEBOUNCE_TIME,
    { leading: true, trailing: true },
  );
  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (scrollableRef.current) {
        scrollableRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return {
    isHeaderCollapsed,
  };
};
