import { gql } from '@apollo/client';

export const getThemeQuery = gql`
  query GetTheme {
    getTheme {
      properties
      logo
    }
  }
`;
