import { Location } from 'history';

import {
  AcceptLegalAgreement,
  CarAvailabilityStatus,
  DamageReportType,
  DriverInformationInput,
} from 'src/api/globalTypes';
import {
  CAR_CODE_STORAGE_KEY,
  DAMAGE_CODE_STORAGE_KEY,
  EXTENDED_PERMISSIONS_STORAGE_KEY,
  FLOW_STORAGE_KEY,
  IS_CODE_BASE_AUTH,
  MANAGER_CODE_KEY,
  WebAppFlows,
} from 'src/apps/NewDriverApp/constants';
import { CODE_KEY } from 'src/constants';

import { CHECKLIST_CODE_STORAGE_KEY } from '../../flows/fleet/checklist/constants';
import { GetCarInfoByFleet_getCarInfoByFleet } from '../../graphql/queries/__generated__/GetCarInfoByFleet';
import {
  GetDamageReportHistory_damageReports,
  GetDamageReportHistory_damageReports_images,
} from '../../graphql/queries/__generated__/GetDamageReportHistory';
import {
  GetDamageReportInfoNew_damageReportInfo_bodyshop,
  GetDamageReportInfoNew_damageReportInfo_carParts,
  GetDamageReportInfoNew_damageReportInfo_damageCategories,
  GetDamageReportInfoNew_damageReportInfo_damageInformation,
  GetDamageReportInfoNew_damageReportInfo_images,
} from '../../graphql/queries/__generated__/GetDamageReportInfoNew';
import { NotificationPlateType } from '../../ui-components/NotificationPlate/types';

export interface DriverInformationData extends DriverInformationInput {
  isCompanyCar?: boolean | null;
  carRegistrationCompany?: string | null;
  desiredRepairAddress?: string | null;
}

export interface DriverInformationReducer {
  isChanged: boolean;
  driverInformation: DriverInformationData;
}

export interface CompletingStatus {
  isCompleted?: boolean;
  isCompleting?: boolean;
  isCompletingError?: Error | null;
}

export interface ImageFromCamera<T = unknown> {
  data?: string | null;
  internalId?: string;
  context?: T;
}

export enum ImageUploadStatus {
  STARTED = 'started',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

export interface DefaultImageState {
  id?: string;
  uploadingStatus?: ImageUploadStatus;
  fullUrl?: string;
  error?: Error;
  uploadProgress?: number;
  createdAt?: string | Date;
  isVisibilityRestricted?: boolean | null;
  isDeletable?: boolean | null;
}

export interface ImageState<T = any> extends DefaultImageState, ImageFromCamera<T> {}

export interface RadioInputType {
  reason: string;
  children?: RadioInputType[];
}

export interface ScheduleAppointmentInputType {
  fieldName: string;
  value: string;
}

export type CarInfo = Omit<GetCarInfoByFleet_getCarInfoByFleet, '__typename'>;

export interface DriverCameraConsentData {
  loading?: boolean;
  loaded?: boolean;
  error?: Error | null;
}

export interface DriverCameraAccessConsent {
  [key: string]: DriverCameraConsentData;
}

export interface RouteChanged {
  currentLocation: Location;
  previousLocation: Location | null;
}

export interface OCRReducerInterface {
  driverAddress: string | null;
  driverCity: string | null;
  driverName: string | null;
  driverZip: string | null;
  driverPhone: string | null;
  driverEmail: string | null;
  firstRegistration: string | null;
  inspectionDate: string | null;
  carRegistrationCompany: string | null;
  licensePlate: string | null;
  vin: string | null;
  isCarCompany?: boolean;
  make?: string;
  model?: string;
}

export interface InfoDocument {
  id: string;
  name: string;
  size: string;
  url: string;
  type: string;
  createdAt: Date;
}
export interface InfoReducer {
  id?: string;
  createdAt?: string;
  status?: string;
  car?: InfoCar;
  fleetComment?: string;
  linkSentEmail?: string;
  documents?: InfoDocument[];
  bodyshop?: GetDamageReportInfoNew_damageReportInfo_bodyshop;
  carParts?: GetDamageReportInfoNew_damageReportInfo_carParts[] | null;
  damageCategories?: GetDamageReportInfoNew_damageReportInfo_damageCategories[] | null;
  images?: GetDamageReportInfoNew_damageReportInfo_images[] | null;
  legalAgreements?: AcceptLegalAgreement[];
  driverPhone?: string;
  reporterEmail?: string;
  reporterName?: string;
  carCode?: string;
  damageNumber?: number;
  damageInformation?: GetDamageReportInfoNew_damageReportInfo_damageInformation;
  type?: DamageReportType;
  loading: boolean;
  loaded: boolean;
  error: null | Error;
  isAccident?: boolean;
  isMinor?: boolean | null;
}

export interface InfoCar {
  id?: string;
  make?: string;
  model?: string;
  licensePlate?: string | null;
  vin?: string | null;
  availability?: CarAvailabilityStatus;
}

export interface AssetsReducer {
  isLoading?: boolean;
  isContentLoading?: boolean;
  isContentLoadingFailed?: boolean;
}

export interface Token {
  [CHECKLIST_CODE_STORAGE_KEY]: string | null;
  [CODE_KEY]: string | null;
  [DAMAGE_CODE_STORAGE_KEY]: string | null;
  [CAR_CODE_STORAGE_KEY]: string | null;
  [MANAGER_CODE_KEY]: string | null;
}

export interface AuthReducer {
  tokens: Token;
  [FLOW_STORAGE_KEY]: WebAppFlows | null;
  [EXTENDED_PERMISSIONS_STORAGE_KEY]: string[] | null;
  [IS_CODE_BASE_AUTH]: string | null;
  appName?: string;
  isLoggingOut: boolean;
}

export enum PersistType {
  PERSIST = 'persist',
  SESSION = 'session',
  IGNORE = 'ignore',
}

export interface PersistMapType {
  type?: PersistType;
  path?: string;
}

export type StorageMapper = Record<string, PersistMapType>;

export enum IconNotificationType {
  NETWORK = 'network',
}

export interface NotificationTypeReducer {
  type: NotificationPlateType;
  message: string;
  title?: string;
  time?: number;
  typeIcon?: IconNotificationType;
}

export interface InsuranceInformationReducer {
  insuranceCompany: string;
  policyNumber?: string;
}

export interface DateAndLocationReducer {
  location: string;
  datetime: string;
}

export interface OtherAccidentPartyReducer {
  driverName?: string;
  licensePlate?: string;
}

export interface DamageReportHistoryImage extends GetDamageReportHistory_damageReports_images {
  internalId?: string;
}
export interface HistoryDamageReports extends GetDamageReportHistory_damageReports {
  images: DamageReportHistoryImage[];
}

export interface HistoryReducer {
  loading: boolean;
  loaded: boolean;
  error: null | Error;
  damageReports: HistoryDamageReports[];
}
