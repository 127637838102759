import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { updateDamageReportCompletingStatus } from 'src/apps/NewDriverApp/redux/actions';

import { CompletingStatus } from '../types/Types';

const initialState: CompletingStatus = {
  isCompleted: false,
  isCompleting: false,
  isCompletingError: null,
};

export const damageReportCompletingStatusReducer = createReducer(initialState, {
  [updateDamageReportCompletingStatus.type]: (state, action: PayloadAction<CompletingStatus>) => {
    return { ...state, ...action.payload };
  },
});
