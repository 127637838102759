import JwtDecode from 'jwt-decode';
import fromPairs from 'lodash/fromPairs';
import { put } from 'redux-saga/effects';

import { setTelemetryFlow } from 'src/services/app-insights/initializers/flowTelemetry';

import { CODE_URL_PARAM_KEY, INIT_PARAM_STORAGE_KEY, WebAppFlows } from '../../../constants';
import {
  resetCarIdentification,
  resetReport,
  setCarCode,
  setChecklistCode,
  setDamageCode,
  setExtendedPermissions,
  setFlowKey,
  setHasAuth,
  setInitParams,
} from '../../actions';
import { PersistStorage } from '../../persist-service';
import { getCarKey } from '../../selectors';
import { store } from '../../store';

export function getStoredInitParams() {
  try {
    const storedParams = PersistStorage.getStorageService().getItem(INIT_PARAM_STORAGE_KEY);
    if (!storedParams) {
      return {};
    }
    return JSON.parse(storedParams);
  } catch (e) {
    return {};
  }
}

export function* storeInitParams() {
  const initParams = fromPairs(Array.from(new URLSearchParams(document.location.search.slice(1))));
  const hasValue = Boolean(Object.keys(initParams || {}).length);

  if (!hasValue) {
    return getStoredInitParams();
  }

  yield put(setInitParams(initParams));
  PersistStorage.getStorageService().setItem(INIT_PARAM_STORAGE_KEY, JSON.stringify(initParams));
  return initParams;
}

export const parseJWTToken = <T = unknown>(token?: string | null) => {
  if (!token) {
    return null;
  }

  try {
    return JwtDecode<T>(token);
  } catch (err) {
    return null;
  }
};

export function storeAuthCodeFromUrl() {
  const params = new URLSearchParams(document.location.search.slice(1));

  const code = params.get(CODE_URL_PARAM_KEY);
  const decodedJwt = parseJWTToken<{ type: string } | null>(code);

  if (decodedJwt) {
    PersistStorage.reset();

    if (['DamageReport', 'PreInspection'].includes(decodedJwt.type)) {
      store.dispatch(setDamageCode(code));
    }

    if (['ChecklistReport'].includes(decodedJwt.type)) {
      store.dispatch(setChecklistCode(code));
    }

    if (['Car'].includes(decodedJwt.type)) {
      store.dispatch(setCarCode(code));
    }

    store.dispatch(setHasAuth('true'));
  }

  return code;
}

export function storeInformationFromUrlCode() {
  const params = new URLSearchParams(document.location.search.slice(1));

  const code = params.get(CODE_URL_PARAM_KEY);
  const decodedJwt = parseJWTToken<{
    flow?: WebAppFlows | null;
    extendedPermissions?: string[] | null;
  }>(code);

  if (decodedJwt) {
    const { flow, extendedPermissions } = decodedJwt;

    if (flow) {
      store.dispatch(setFlowKey(flow));
    }

    if (extendedPermissions) {
      store.dispatch(setExtendedPermissions(extendedPermissions));
    }

    return {
      flow,
      extendedPermissions,
    };
  }

  return null;
}

export function cleanupSession(flow = WebAppFlows.EXTERIOR) {
  if (flow === WebAppFlows.CAR_DAMAGES_OVERVIEW) {
    store.dispatch(setDamageCode(null));
    return;
  }

  if (flow === WebAppFlows.CHECKLIST) {
    store.dispatch(setChecklistCode(null));
  } else {
    store.dispatch(resetReport());
    store.dispatch(setDamageCode(null));
  }

  const carAuth = getCarKey(store.getState());
  if (!carAuth) {
    store.dispatch(resetCarIdentification());
  }

  store.dispatch(setHasAuth(null));
  PersistStorage.getStorageService().removeItem(INIT_PARAM_STORAGE_KEY);
  store.dispatch(setFlowKey(null));

  // clean up telemetry flow
  setTelemetryFlow();
}
