import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useElementResize = <T extends unknown>(
  ref: React.RefObject<T>,
  cb: (observeEntry?: ResizeObserverEntry | null) => void,
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      const observeEntry = entries.find(entry => entry.target === ref.current) || null;

      // https://stackoverflow.com/questions/76187282/react-resizeobserver-loop-completed-with-undelivered-notifications
      window.requestAnimationFrame(() => {
        if (!observeEntry) {
          return;
        }

        cb(observeEntry);
      });
    });

    if (ref.current) {
      resizeObserver.observe(ref.current as unknown as HTMLElement);
    }

    return () => {
      if (ref.current) {
        resizeObserver.disconnect();
      }
    };
  }, [ref.current]);
};

export default useElementResize;
