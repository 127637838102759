import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { resetNotification, setNotificationData } from '../actions';
import { NotificationTypeReducer } from '../types/Types';

const initialState = null as NotificationTypeReducer | null;

export const notificationReducer = createReducer(initialState, {
  [setNotificationData.type]: (state, action: PayloadAction<NotificationTypeReducer>) => {
    return action.payload;
  },
  [resetNotification.type]: () => {
    return null;
  },
});
