import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { accidentResponsibilityPageCompleted } from '../actions';

interface AccidentResponsibilityReducer {
  responsible: string;
}

const initialState: AccidentResponsibilityReducer = {
  responsible: '',
};

export const accidentResponsibilityReducer = createReducer(initialState, {
  [accidentResponsibilityPageCompleted.type]: (
    state,
    action: PayloadAction<AccidentResponsibilityReducer>,
  ) => {
    return {
      ...state,
      responsible: action.payload.responsible,
    };
  },
});
