import { DamageCategoryType } from 'src/api/globalTypes';
import { RED_COLOR, YELLOW_COLOR } from 'src/apps/NewDriverApp/constants';
import { QuestionOptionIconName } from 'src/apps/NewDriverApp/types';

export const serviceLightIconsPaths: Partial<
  Record<
    DamageCategoryType,
    {
      icon: QuestionOptionIconName;
      color: string;
    }
  >
> = {
  [DamageCategoryType.RED_BRAKE_SYSTEM]: {
    icon: QuestionOptionIconName.BREAK_SYSTEM,
    color: RED_COLOR,
  },
  [DamageCategoryType.RED_BATTERY]: {
    icon: QuestionOptionIconName.BATTERY,
    color: RED_COLOR,
  },
  [DamageCategoryType.RED_OIL_LEVEL]: {
    icon: QuestionOptionIconName.OIL_LEVEL,
    color: RED_COLOR,
  },
  [DamageCategoryType.RED_BRAKE_WEAR]: {
    icon: QuestionOptionIconName.BREAK_WEAR,
    color: RED_COLOR,
  },
  [DamageCategoryType.RED_ABS]: {
    icon: QuestionOptionIconName.ABS,
    color: RED_COLOR,
  },
  [DamageCategoryType.RED_MOTOR_CONTROL]: {
    icon: QuestionOptionIconName.MOTOR_CONTROL,
    color: RED_COLOR,
  },
  [DamageCategoryType.RED_TYRE_PRESSURE]: {
    icon: QuestionOptionIconName.TYRE_PRESSURE,
    color: RED_COLOR,
  },

  [DamageCategoryType.YELLOW_BRAKE_SYSTEM]: {
    icon: QuestionOptionIconName.BREAK_SYSTEM,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.YELLOW_BATTERY]: {
    icon: QuestionOptionIconName.BATTERY,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.YELLOW_OIL_LEVEL]: {
    icon: QuestionOptionIconName.OIL_LEVEL,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.YELLOW_BRAKE_WEAR]: {
    icon: QuestionOptionIconName.BREAK_WEAR,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.YELLOW_ABS]: {
    icon: QuestionOptionIconName.ABS,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.YELLOW_MOTOR_CONTROL]: {
    icon: QuestionOptionIconName.MOTOR_CONTROL,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.YELLOW_TYRE_PRESSURE]: {
    icon: QuestionOptionIconName.TYRE_PRESSURE,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.YELLOW_INSPECTION_IS_DUE]: {
    icon: QuestionOptionIconName.INSPECTION_DUE,
    color: YELLOW_COLOR,
  },
  [DamageCategoryType.RED_COOLANT_TEMPERATURE]: {
    icon: QuestionOptionIconName.COOLANT,
    color: RED_COLOR,
  },
  [DamageCategoryType.RED_AIRBAGS]: {
    icon: QuestionOptionIconName.AIRBAG,
    color: RED_COLOR,
  },
};
