import { ThemeInterface } from 'src/apps/NewDriverApp/theme/ThemeInterface';

const applyCssVariabes = (theme: ThemeInterface) => {
  Object.keys(theme.properties).forEach((key: string) => {
    document.documentElement.style.setProperty(
      `--${key.replace(/_/g, '-')}`,
      theme.properties[key as keyof typeof theme.properties],
    );
  });
};

export default applyCssVariabes;
