import { useTranslation } from 'react-i18next';

import { AppSegmentType } from 'src/api/globalTypes';
import { goCustomerSite } from 'src/apps/NewDriverApp/redux/actions';

const useCompleteFlowBodyshop = (namespace = 'completed') => {
  const { t } = useTranslation(namespace);

  const localeStringKey = `${AppSegmentType.BODYSHOPS}.button`;

  return {
    isCompleteActionAvailable: true,
    completeAction: goCustomerSite,
    actionName: t(localeStringKey),
  };
};

export default useCompleteFlowBodyshop;
