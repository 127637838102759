import { ButtonSize } from './types';
import { TextType } from '../Text/types';

export const BUTTON_SIZE = {
  LARGE: 'large',
  MEDIIM: 'medium',
} as const;

export const BUTTON_VARIANT = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

export const BUTTON_TEXT_TYPE_MAPPER: Record<ButtonSize, TextType> = {
  large: 'button-large',
  medium: 'button-medium',
} as const;

export const BUTTON_SPINNER_SIZE_CLASS_MAPPER: Record<ButtonSize, string> = {
  large: 'spinnerLarge',
  medium: 'spinnerMedium',
} as const;

export const BUTTON_ADORNMENT_SIZE_CLASS_MAPPER: Record<ButtonSize, string> = {
  large: 'adornmentLarge',
  medium: 'adornmentMedium',
} as const;
