import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DamageReportImageType } from 'src/api/globalTypes';
import { ReactComponent as EditIcon } from 'src/apps/NewDriverApp/assets/icons/px-pencil.svg';
import { i18n_namespace as i18nInteriorNamespace } from 'src/apps/NewDriverApp/flows/fleet/interior/DamagePhotos/i18n-namespace';
import {
  getCurrentFlow,
  getPhotoTip,
  getPhotosForbiddenError,
  getPhotosUploadLimitExceededError,
} from 'src/apps/NewDriverApp/pages/DamagePhotos/utils';
import { DamageImagePreview } from 'src/apps/NewDriverApp/pages/ReportedDamage/components';
import ReportedDamageImagesModal from 'src/apps/NewDriverApp/pages/ReportedDamage/components/ReportedDamageImagesModal/ReportedDamageImagesModal';
import { ImageState, ImageUploadStatus } from 'src/apps/NewDriverApp/redux/types/Types';
import { DamagePhotosCameraContext } from 'src/apps/NewDriverApp/types';
import { openCamera } from 'src/apps/NewDriverApp/ui-components/Camera/redux/actions';
import { cameraSelector } from 'src/apps/NewDriverApp/ui-components/Camera/redux/selectors';
import { PhotoInput } from 'src/apps/NewDriverApp/ui-components/PhotoInput';
import { findDamageReportImageByType, urlToBase64 } from 'src/apps/NewDriverApp/utils/helpers';
import { getImagesCounter } from 'src/apps/NewDriverApp/utils/photoStepHelpers';
import { useRequiredImagesContext } from 'src/apps/NewDriverApp/utils/useRequiredImagesContext';

import styles from './ImagesBlock.module.scss';
import { CameraSettings, SupportedImageOrder } from './types';
import { getUnTakenImageType, shouldRenderAdditionalInput } from './utils';
import lazyWithPreload from '../../utils/lazyWithPreload';
import NotificationPlate from '../NotificationPlate';

const AdditionalPhotoPlaceholder = lazyWithPreload(
  () =>
    import(
      /* webpackChunkName: "AdditionalPhotoPlaceholder" */ 'src/apps/NewDriverApp/icons/AdditionalPhotoPlaceholder/AdditionalPhotoPlaceholder'
    ),
);

const Camera = lazyWithPreload(
  () => import(/* webpackChunkName: "Camera" */ 'src/apps/NewDriverApp/ui-components/Camera'),
);

interface Props {
  images: ImageState[];
  withDivider?: boolean;
  maxImagesNumber?: number;
  supportedImageOrder?: SupportedImageOrder[];
  listImageTypes?: DamageReportImageType[];
  cameraSettings?: CameraSettings;
  isPreview?: boolean;
  customCounterFunction?: () => string;
}

const ImagesBlock: React.FC<Props> = ({
  images,
  withDivider = true,
  maxImagesNumber,
  supportedImageOrder,
  listImageTypes,
  cameraSettings,
  isPreview,
  customCounterFunction,
}) => {
  const { t } = useTranslation([i18nInteriorNamespace, 'camera', 'damagePhotos']);
  const dispatch = useDispatch();
  const requiredImages = useRequiredImagesContext();
  const { selectedPhoto, takenPhotos } = useSelector(cameraSelector) || {};
  const cameraContext = get(selectedPhoto, 'context', {}) as DamagePhotosCameraContext;
  const { imageType } = cameraContext;
  const currentFlow = getCurrentFlow(imageType);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const initialImages = images.filter(image => !image.internalId);
  const addedImages = images.filter(image => Boolean(image.internalId));
  const unTakenImageInfo = getUnTakenImageType(images, supportedImageOrder);
  const customCameraProps = get(cameraSettings, `${imageType}.props`, {});
  const hasUploadingError = images.some(
    image => image.uploadingStatus === ImageUploadStatus.FAILURE,
  );
  const hasForbiddenUploadingError = getPhotosForbiddenError(images);
  const hasPhotosUploadLimitExceededError = getPhotosUploadLimitExceededError(images);

  const isErrorTypeWithoutLabel = hasForbiddenUploadingError || hasPhotosUploadLimitExceededError;

  const shouldDisplayAdditionalImageInput = shouldRenderAdditionalInput({
    images,
    maxImagesNumber,
    isPreview,
  });

  const openViewer = (index: number) => {
    setImageIndex(index);
    setIsImageViewerOpen(true);
  };

  const onCloseViewer = () => {
    setImageIndex(0);
    setIsImageViewerOpen(false);
  };
  const checkIsImageRequired = useCallback(({ internalId, context }: ImageState = {}) => {
    const type = get(context, 'imageType') || DamageReportImageType.ADDITIONAL;

    return (
      requiredImages &&
      get(
        requiredImages.find(
          requiredImage => requiredImage.type === type && requiredImage.internalId === internalId,
        ),
        'isRequired',
        false,
      )
    );
  }, []);

  const onCameraOpen = (selectedImage?: ImageState) => async () => {
    const { id, internalId, context, fullUrl } = selectedImage || {};

    const nextListImageType = listImageTypes
      ? listImageTypes.find(requiredType => !images.find(findDamageReportImageByType(requiredType)))
      : null;

    const type = get(context, 'imageType') || nextListImageType || DamageReportImageType.ADDITIONAL;
    const actionArgs = get(cameraSettings, `${type}.action`, {});
    const data = await urlToBase64(fullUrl);

    const isImageRequired = checkIsImageRequired({ internalId, context: { imageType: type } });

    const isDeletable = isImageRequired !== undefined && !isImageRequired;

    dispatch(
      openCamera({
        selectedPhoto: { internalId, data, context: { id, imageType: type } },
        isDeletable,
        ...actionArgs,
      }),
    );
  };

  const uploadError = (
    <NotificationPlate
      title={t('camera:errors.upload.title')}
      content={t('camera:errors.upload.description')}
      className={styles.error}
      type="error"
    />
  );

  const forbiddenUploadError = (
    <NotificationPlate
      title={t('camera:errors.forbidden.title')}
      content={t('camera:errors.forbidden.description')}
      className={styles.error}
      type="error"
    />
  );

  const imageInputs = addedImages.map(image => (
    <PhotoInput<ImageState>
      key={image.id || image.internalId}
      isOnlyExternalClickHandler
      image={image}
      isRequired={checkIsImageRequired({ ...image })}
      onClickInputHandler={onCameraOpen(image)}
      isOverlayEnabled={false}
      RightIcon={EditIcon}
    />
  ));

  if (shouldDisplayAdditionalImageInput) {
    imageInputs.push(
      <PhotoInput<ImageState>
        key="additional-image-input"
        isOnlyExternalClickHandler
        onClickInputHandler={onCameraOpen()}
        placeholder={AdditionalPhotoPlaceholder}
        isRequired={unTakenImageInfo.isRequired}
        isDisplayErrorOutline={unTakenImageInfo.isRequired}
      />,
    );
  }

  const counter = customCounterFunction
    ? customCounterFunction()
    : getImagesCounter(currentFlow, imageType, images, takenPhotos);

  return (
    <div className={cn({ [styles.withDivider]: withDivider, [styles.container]: !withDivider })}>
      {hasUploadingError && !isErrorTypeWithoutLabel && uploadError}
      {hasForbiddenUploadingError && forbiddenUploadError}
      <div className={styles.imagesContainer}>
        {initialImages.map(({ id, data, fullUrl }: ImageState, i: number) => (
          <DamageImagePreview
            key={id}
            src={(data || fullUrl) as string}
            onClick={openViewer}
            index={i}
            className={styles.imageItem}
          />
        ))}
        {imageInputs}
      </div>

      {Boolean(images.length) && (
        <ReportedDamageImagesModal
          isOpen={isImageViewerOpen}
          onClose={onCloseViewer}
          index={imageIndex}
          slides={images.map(image => {
            const type = get(image, 'context.imageType') || get(image, 'type');

            return {
              id: image.id,
              type,
              src: image.fullUrl as string,
              uploadProgress: image.uploadProgress,
            };
          })}
        />
      )}
      <Camera
        counterLabel={counter}
        messageLabel={getPhotoTip(t, imageType)}
        {...customCameraProps}
      />
    </div>
  );
};

export default ImagesBlock;
