import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
  setChecklistCode,
  setCode,
  setDamageCode,
  setCarCode,
  setFlowKey,
  setHasAuth,
  setManagerCode,
  setIsLoggingOut,
  setExtendedPermissions,
  setAppName,
} from 'src/apps/NewDriverApp/redux/actions';
import { CODE_KEY } from 'src/constants';

import {
  CAR_CODE_STORAGE_KEY,
  DAMAGE_CODE_STORAGE_KEY,
  EXTENDED_PERMISSIONS_STORAGE_KEY,
  FLOW_STORAGE_KEY,
  IS_CODE_BASE_AUTH,
  MANAGER_CODE_KEY,
  WebAppFlows,
} from '../../constants';
import { CHECKLIST_CODE_STORAGE_KEY } from '../../flows/fleet/checklist/constants';
import { AuthReducer } from '../types/Types';

const initialState: AuthReducer = {
  tokens: {
    [CHECKLIST_CODE_STORAGE_KEY]: null,
    [CODE_KEY]: null,
    [DAMAGE_CODE_STORAGE_KEY]: null,
    [CAR_CODE_STORAGE_KEY]: null,
    [MANAGER_CODE_KEY]: null,
  },
  [FLOW_STORAGE_KEY]: null,
  [EXTENDED_PERMISSIONS_STORAGE_KEY]: null,
  [IS_CODE_BASE_AUTH]: null,
  isLoggingOut: false,
};

export const authReducer = createReducer(initialState, {
  [setChecklistCode.type]: (state: AuthReducer, action: PayloadAction<string | null>) => {
    state.tokens = {
      ...state.tokens,
      [CHECKLIST_CODE_STORAGE_KEY]: action.payload,
    };
  },
  [setCode.type]: (state: AuthReducer, action: PayloadAction<string | null>) => {
    state.tokens = {
      ...state.tokens,
      [CODE_KEY]: action.payload,
    };
  },
  [setDamageCode.type]: (state: AuthReducer, action: PayloadAction<string | null>) => {
    state.tokens = {
      ...state.tokens,
      [DAMAGE_CODE_STORAGE_KEY]: action.payload,
    };
  },
  [setCarCode.type]: (state: AuthReducer, action: PayloadAction<string | null>) => {
    state.tokens = {
      ...state.tokens,
      [CAR_CODE_STORAGE_KEY]: action.payload,
    };
  },
  [setFlowKey.type]: (state: AuthReducer, action: PayloadAction<WebAppFlows | null>) => {
    state[FLOW_STORAGE_KEY] = action.payload;
  },
  [setExtendedPermissions.type]: (state: AuthReducer, action: PayloadAction<string[] | null>) => {
    state[EXTENDED_PERMISSIONS_STORAGE_KEY] = action.payload;
  },
  [setHasAuth.type]: (state: AuthReducer, action: PayloadAction<string | null>) => {
    state[IS_CODE_BASE_AUTH] = action.payload;
  },
  [setManagerCode.type]: (state: AuthReducer, action: PayloadAction<string | null>) => {
    state.tokens = {
      ...state.tokens,
      [MANAGER_CODE_KEY]: action.payload,
    };
  },
  [setAppName.type]: (state: AuthReducer, action: PayloadAction<string>) => {
    state.appName = action.payload;
  },
  [setIsLoggingOut.type]: (state: AuthReducer, action: PayloadAction<boolean>) => {
    state.isLoggingOut = action.payload;
  },
});
