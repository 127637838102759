import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import * as Actions from '../actions';
import { ChecklistReportedDamages } from '../types';

const initialState: {
  loading: boolean;
  loaded: boolean;
  error: null | Error;
  damageReports: ChecklistReportedDamages[];
} = {
  loading: false,
  error: null,
  loaded: false,
  damageReports: [],
};

export const checklistDamageReportHistoryReducer = createReducer(initialState, {
  [Actions.fetchChecklistDamageReportHistory.type]: state => {
    state.loading = true;
    state.error = null;
    state.loaded = false;
  },
  [Actions.fetchChecklistDamageReportHistorySuccess.type]: (
    state,
    action: PayloadAction<ChecklistReportedDamages[]>,
  ) => {
    state.loading = false;
    state.error = null;
    state.loaded = true;
    state.damageReports = action.payload;
  },
  [Actions.fetchChecklistDamageReportHistoryFailure.type]: (
    state,
    action: PayloadAction<{ error: Error }>,
  ) => {
    state.loading = false;
    state.loaded = false;
    state.damageReports = [];
    state.error = action.payload.error;
  },
});
