import { ResourceManipulationConfig } from '../types';

const mapExtendedPermissionsActionToValues: Record<string, ResourceManipulationConfig> = {
  update: {
    isEnabled: true,
  },
  view: {
    isEnabled: true,
    isReadonly: true,
  },
  delete: {
    isEnabled: true,
  },
};

export const resolveValueByExtendedPermissionsAction = (
  action?: string,
): ResourceManipulationConfig => {
  return mapExtendedPermissionsActionToValues[
    action as keyof typeof mapExtendedPermissionsActionToValues
  ];
};
