import { ExtendedPermissionAccessConfiguration } from 'src/apps/NewDriverApp/hooks/useExtendedPermissions/types';
import { resolveExtendedPermissions } from 'src/apps/NewDriverApp/hooks/useExtendedPermissions/utils/resolveExtendedPermissions';

export const getAccessConfiguration = (permissions?: string[] | null) => {
  if (!permissions) {
    return {};
  }

  return permissions.reduce<ExtendedPermissionAccessConfiguration>((acc, permission) => {
    const resolvedPermission = resolveExtendedPermissions(permission);

    return {
      ...acc,
      ...resolvedPermission,
    };
  }, {} as ExtendedPermissionAccessConfiguration);
};
