import { useMemo } from 'react';

import { MAX_ZOOMING_SCALE } from 'src/apps/NewDriverApp/pages/ReportedDamage/components/DamageImageSlider/constants';

const useZoomContainerConfiguration = () => {
  return useMemo(
    () => ({
      wrapperProps: {
        disabled: false,
        disablePadding: true,
        maxScale: MAX_ZOOMING_SCALE,
        doubleClick: {
          disabled: true,
        },
      },
      contentProps: {},
    }),
    [],
  );
};

export default useZoomContainerConfiguration;
