import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { SecrecyObligationData } from '../../SecrecyObligation/types';
import { setIsCarPrototype } from '../actions';

const initialState: SecrecyObligationData = {};

export const accidentSecrecyObligationReducer = createReducer(initialState, {
  [setIsCarPrototype.type]: (state, action: PayloadAction<boolean>) => {
    state.isCarPrototype = action.payload;
  },
});
