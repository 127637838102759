import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { DamageCategoryType } from 'src/api/globalTypes';
import { selectDamageTypes } from 'src/apps/NewDriverApp/redux/actions';

export interface DamageTypeReducer {
  selectedTypes: DamageCategoryType[];
}

const initialState: DamageTypeReducer = {
  selectedTypes: [],
};

export const damageTypeReducer = createReducer(initialState, {
  [selectDamageTypes.type]: (
    state,
    action: PayloadAction<DamageCategoryType | DamageCategoryType[]>,
  ) => {
    const selectedTypes = Array.isArray(action.payload) ? action.payload : [action.payload];
    state.selectedTypes = [...selectedTypes];
  },
});
