import React, { FC } from 'react';

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import styles from './ReportProgress.module.scss';
import Text from '../Text';

interface Props {
  classNames?: string;
}

const ReportProgress: FC<Props> = ({ classNames }) => {
  const { t } = useTranslation('common');
  const { state } = useLocation();

  if (!state) {
    return null;
  }

  const { totalSteps: totalStepQuantity, progressStep } = state;

  const width = `${(100 / totalStepQuantity) * progressStep}%`;

  return (
    <div className={cx(styles.container, classNames)}>
      <div className={styles.progress} aria-describedby="report-progress-steps">
        <div
          className={styles.progressBar}
          role="progressbar"
          style={{
            width,
          }}
          aria-valuenow={progressStep}
          aria-valuemin={0}
          aria-valuemax={totalStepQuantity}
        />
      </div>
      <Text
        type="body-small-medium"
        color="inherit"
        id="report-progress-steps"
        className={styles.progressText}
      >
        {t('common:step')} {progressStep}/{totalStepQuantity}
      </Text>
    </div>
  );
};

export { ReportProgress };
