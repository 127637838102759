import { createAction } from '@reduxjs/toolkit';

import {
  DamageListFilters,
  DamageListReducer,
  DamageReportListItem,
  DamageListFilter,
} from '../../../types';

export const setDamageListData = createAction<Partial<DamageListReducer>>('SET_DAMAGE_LIST_DATA');

export const fetchDamageList = createAction<{ isInitialCall?: boolean } | undefined>(
  'GET_DAMAGE_LIST',
);

export const resetDamageList = createAction('RESET_DAMAGE_LIST');

export const resetDamageListFilters = createAction('RESET_DAMAGE_LIST_FILTERS');

export const addNextPageDamages =
  createAction<Pick<DamageListReducer, 'total' | 'data' | 'filters'>>('ADD_NEXT_PAGE_DAMAGES');

export const setDamageListFilters =
  createAction<Partial<DamageListFilters>>('SET_DAMAGE_LIST_FILTERS');

export const updateDamageListItem =
  createAction<Partial<DamageReportListItem>>('UPDATE_DAMAGE_LIST_ITEM');
export const setDamageFilterStatusData = createAction<Partial<DamageListFilter>>(
  'SET_DAMAGE_FILTER_STATUS_DATA',
);

export const fetchDamageFilterStatusData = createAction('FETCH_DAMAGE_FILTER_STATUS_DATA');
