import { createAction } from '@reduxjs/toolkit';

import { CompleteDamageReportVerificationStatus } from 'src/api/globalTypes';
import { ImageFromCamera } from 'src/apps/NewDriverApp/redux/types/Types';

import { VerifyRouteKeys } from '../constants';
import { CarState, DamageReportDetailsFlow, VerifyYourInformation } from '../types';

// edit mode
export const damageVerificationEdit = createAction<{
  route: VerifyRouteKeys;
}>('DAMAGE_VERIFICATION_EDIT');

export const damageVerificationEditComplete = createAction('DAMAGE_VERIFICATION_EDIT_COMPLETED');

export const setAdditionalComment = createAction<{ message: string }>('SET_ADDITIONAL_COMMENT');

// complete verification
export const completeDamageVerification = createAction<{ isDamageConfirmed?: boolean }>(
  'COMPLETE_DAMAGE_VERIFICATION',
);

export const completeDamageVerificationSuccess = createAction(
  'COMPLETE_DAMAGE_VERIFICATION_SUCCESS',
);

export const setDamageVerificationIsCompleting = createAction<boolean>(
  'SET_DAMAGE_VERIFICATION_IS_COMPLETING',
);

export const completeDamageVerificationFailure = createAction<{ error: Error }>(
  'COMPLETE_DAMAGE_VERIFICATION_FAILURE',
);

export const verificationCarPartsCompleted = createAction('VERIFICATION_CAR_PARTS_COMPLETED');

export const verificationDamageTypesCompleted = createAction('VERIFICATION_DAMAGE_TYPES_COMPLETED');

export const setIsEditingMode = createAction<boolean>('SET_IS_EDITING_MODE');

export const setIsVerifyInitializing = createAction<boolean>('SET_IS_VERIFY_INITIALIZING');

export const setVerificationError = createAction<Error | null>('SET_VERIFICATION_ERROR');

export const damageVerificationCompleted = createAction<{
  isDamageConfirmed: boolean;
  message?: string;
  nextStatus?: CompleteDamageReportVerificationStatus;
}>('DAMAGE_VERIFICATION_COMPLETED');

export const selectDamageReportDetailsFlow = createAction<DamageReportDetailsFlow | null>(
  'SELECT_INDICATION_FLOW',
);

export const setCarState = createAction<Partial<CarState>>('SET_CAR_STATE');

export const carStateCompleted = createAction('CAR_STATE_COMPLETED');

export const setYourInformation = createAction<{ data: VerifyYourInformation }>(
  'SET_YOUR_INFORMATION',
);

export const updateYourInformation =
  createAction<Partial<VerifyYourInformation>>('UPDATE_YOUR_INFORMATION');

export const verifyDamagePhotosCompleted = createAction('VERIFY_DAMAGE_PHOTOS_COMPLETED');

export const navigateToDamageDetailsPage = createAction<string>('NAVIGATE_TO_DAMAGE_DETAILS_PAGE');

export const indicationOverviewCompleted = createAction('INDICATION_OVERVIEW_COMPLETED');

export const startOtherStatusesDamageReportImageUpload = createAction<ImageFromCamera>(
  'START_OTHER_STATUSES_DAMAGE_REPORT_IMAGE_UPLOAD',
);
