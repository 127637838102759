import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import uniq from 'lodash/uniq';

import {
  checklistDamageReportUpdateEnd,
  checklistDamageReportUpdateStart,
  resetCurrentDamageReport,
  saveCheckDamagePhotos,
  selectCarPartsDamageCheck,
  selectCheckDamageTypes,
  setCarPartsDamageCheck,
  setChecklistDamageReportLoaded,
  setCurrentDamageReport,
  setDamageComment,
  setDamageMinority,
  setDamageStatus,
  unselectCarParts,
} from '../actions';
import { ChecklistDamageReport } from '../types';

const initialState: Partial<ChecklistDamageReport> & { loading?: boolean; loaded?: boolean } = {
  carParts: [],
  loading: false,
  loaded: false,
};

export const checklistCurrentDamageReducer = createReducer(initialState, {
  [setCurrentDamageReport.type]: (
    state,
    action: PayloadAction<{ currentDamageReport: ChecklistDamageReport }>,
  ) => {
    return { ...action.payload.currentDamageReport, loading: state.loading };
  },
  [selectCarPartsDamageCheck.type]: (state, { payload }) => {
    state.carParts = uniq((state.carParts || []).concat([payload]));
  },
  [unselectCarParts.type]: (state, { payload }) => {
    state.carParts = (state.carParts || []).filter(item => item !== payload);
  },
  [setCarPartsDamageCheck.type]: (state, { payload }) => {
    state.carParts = payload;
  },
  [setDamageComment.type]: (state, { payload }) => {
    state.damageComment = payload;
  },
  [setDamageMinority.type]: (state, { payload }) => {
    state.isMinor = payload;
  },
  [setDamageStatus.type]: (state, { payload }) => {
    state.status = payload;
  },
  [saveCheckDamagePhotos.type]: (
    state,
    { payload }: PayloadAction<{ images: ChecklistDamageReport['images'] }>,
  ) => {
    state.images = payload.images;
  },
  [checklistDamageReportUpdateStart.type]: state => {
    state.loading = true;
  },
  [checklistDamageReportUpdateEnd.type]: state => {
    state.loading = false;
  },
  [setChecklistDamageReportLoaded.type]: (state, { payload }) => {
    state.loaded = payload.loaded;
  },
  [selectCheckDamageTypes.type]: (state, { payload }) => {
    state.damageCategories = payload;
  },
  [resetCurrentDamageReport.type]: () => {
    return initialState;
  },
});
