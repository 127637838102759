import React from 'react';

import { TFunction } from 'i18next';
import compact from 'lodash/compact';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import snakeCase from 'lodash/snakeCase';

import { CarPartType, DamageCategoryType, DamageReportType } from 'src/api/globalTypes';
import { serviceLightIconsPaths } from 'src/apps/DriverOverviewApp/utils/serviceLightsHelper';
import { iconSet } from 'src/apps/NewDriverApp/flows/fleet/checklist/components/ServiceLightSelectItem/service-lights-icon-set';
import ServiceLightItem from 'src/apps/NewDriverApp/flows/fleet/service-lights/components/ServiceLightItem/ServiceLightItem';
import formatDate from 'src/apps/NewDriverApp/utils/formatDate';

import { CreateIndicationBlocksData, DetailsOverviewBlock, ReporterBlock } from './types';

const SEPARATOR = ' / ';

interface CreateIndicationBlock {
  data: CreateIndicationBlocksData;
  damageType: DamageReportType | undefined;
  t: TFunction;
}

const driverInformationFields = (driverInformation: ReporterBlock | null = {}, t: TFunction) =>
  Object.entries(driverInformation || {}).map(([inputName, value]) => ({
    subtitle: t(
      `interior-overview:blocks.driver_information.items.${snakeCase(inputName)}.subtitle`,
    ),
    text: value,
  }));

const createIndicationBlocks = ({ data, t }: CreateIndicationBlock): DetailsOverviewBlock[] => {
  const { carParts, damageTypes, date, damagePhotos, reporter, additionalInformation } = data;

  return compact([
    {
      title: t('blocks.damage_information.title'),
      items: compact([
        damagePhotos.data && {
          subtitle: t('blocks.damage_information.items.damage_photos.subtitle'),
          images: damagePhotos.data,
          supportedImageOrder: damagePhotos.supportedImageOrder,
          listImageTypes: damagePhotos.listImageTypes,
        },
        carParts.data && {
          subtitle: t('blocks.damage_information.items.car_parts.subtitle'),
          text: carParts.data
            .map((carPart: CarPartType) => t(`carParts:${carPart}`))
            .join(SEPARATOR),
          withDivider: true,
        },
        damageTypes.data && {
          subtitle: t('blocks.damage_information.items.damage_category.subtitle'),
          text: isArray(damageTypes.data)
            ? damageTypes.data
                .map((dmgType: DamageCategoryType) => t(`damageType:types.${dmgType}`))
                .join(SEPARATOR)
            : damageTypes.data,
          withDivider: true,
        },
        date.data && {
          subtitle: t('blocks.damage_information.items.date.subtitle'),
          text: formatDate(date.data),
        },
      ]),
    },
    !isEmpty(reporter.data) && {
      title: t('interior-overview:blocks.driver_information.title'),
      withDivider: true,
      items: driverInformationFields(reporter.data, t),
    },
    !isEmpty(additionalInformation.data) && {
      title: t('blocks.comments.title', { ns: 'interior-overview' }),
      withDivider: true,
      items: [
        {
          subtitle: t('blocks.comments.items.additional_comments.subtitle', {
            ns: 'interior-overview',
          }),
          text: additionalInformation.data,
        },
      ],
    },
  ]);
};

const createMechanicalBlocks = ({ data, t }: CreateIndicationBlock): DetailsOverviewBlock[] => {
  const { carParts, damageTypes, date, damagePhotos, reporter, additionalInformation } = data;

  return compact([
    {
      title: t('blocks.damage_information.title'),
      items: compact([
        damagePhotos.data && {
          subtitle: t('blocks.damage_information.items.damage_photos.subtitle'),
          images: damagePhotos.data,
          supportedImageOrder: damagePhotos.supportedImageOrder,
          listImageTypes: damagePhotos.listImageTypes,
        },
        carParts.data && {
          subtitle: t('blocks.damage_information.items.mechanical_problems.subtitle'),
          text: carParts.data
            .map(carPart => t(`mechanical-problem:problem.${carPart.toLowerCase()}`))
            .join(SEPARATOR),
          withDivider: true,
        },
        damageTypes.data && {
          subtitle: t('blocks.damage_information.items.damage_category.subtitle'),
          text: isArray(damageTypes.data)
            ? damageTypes.data
                .map((dmgType: DamageCategoryType) => t(`damageType:types.${dmgType}`))
                .join(SEPARATOR)
            : damageTypes.data,
          withDivider: true,
        },
        date.data && {
          subtitle: t('blocks.damage_information.items.date.subtitle'),
          text: formatDate(date.data),
        },
      ]),
    },
    !isEmpty(reporter.data) && {
      title: t('interior-overview:blocks.driver_information.title'),
      withDivider: true,
      items: driverInformationFields(reporter.data, t),
    },
    !isEmpty(additionalInformation.data) && {
      title: t('blocks.comments.title', { ns: 'interior-overview' }),
      withDivider: true,
      items: [
        {
          subtitle: t('blocks.comments.items.problem_description.subtitle'),
          text: additionalInformation.data,
        },
      ],
    },
  ]);
};

const createServiceLightIndicationBlocks = ({
  data,
  t,
}: CreateIndicationBlock): DetailsOverviewBlock[] => {
  const { damageTypes, date, damagePhotos, reporter } = data;

  const serviceLightsDataToRender = (types: DamageCategoryType[]) =>
    types.map(type => {
      const serviceLightIconsPathsMapping = serviceLightIconsPaths[type];
      if (!serviceLightIconsPathsMapping) {
        return null;
      }
      const { icon, color } = serviceLightIconsPathsMapping;
      const IconComponent = iconSet[icon];

      return React.createElement(ServiceLightItem, {
        key: 'inputName',
        IconComponent,
        color,
        text: t(`damageType:types.${type}`),
      });
    });

  return compact([
    {
      title: t('blocks.damage_information.title'),
      items: compact([
        damagePhotos.data && {
          subtitle: t('blocks.damage_information.items.damage_photos.subtitle'),
          images: damagePhotos.data,
          supportedImageOrder: damagePhotos.supportedImageOrder,
        },
        damageTypes.data && {
          subtitle: t('blocks.damage_information.items.damage_category.subtitle'),
          text: serviceLightsDataToRender(damageTypes.data),
          isServiceLight: true,
        },
        date.data && {
          subtitle: t('blocks.damage_information.items.date.subtitle'),
          text: formatDate(date.data),
        },
      ]),
    },
    !isEmpty(reporter.data) && {
      title: t('interior-overview:blocks.driver_information.title'),
      withDivider: true,
      items: driverInformationFields(reporter.data, t),
    },
  ]);
};

export const getIndicatedBlocks = (args: CreateIndicationBlock) => {
  switch (args.damageType) {
    case DamageReportType.SERVICE_LIGHTS:
      return createServiceLightIndicationBlocks(args);
    case DamageReportType.MECHANICAL:
      return createMechanicalBlocks(args);
    default:
      return createIndicationBlocks(args);
  }
};
