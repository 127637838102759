import { createReducer } from '@reduxjs/toolkit';

import { updateAdditionalInformationData } from 'src/apps/NewDriverApp/redux/actions';

export interface AdditionalInformationReducer {
  isChanged: boolean;
  additionalInformation: {
    note?: string;
    mileage: string;
  };
}

const initialState: AdditionalInformationReducer = {
  isChanged: false,
  additionalInformation: {
    note: '',
    mileage: '',
  },
};

export const additionalInformationReducer = createReducer(initialState, {
  [updateAdditionalInformationData.type]: (state, { payload }) => {
    state.additionalInformation = payload.data;
  },
});
