import config from 'src/config';

const { REACT_APP_ROOT_DOMAIN = '' } = config;

const { pathname, search, protocol } = window.location;
const [, customerName, ...rest] = pathname.split('/');

/**
 * Handles redirects from OLD_WEBAPP_DOMAIN to REACT_APP_ROOT_DOMAIN
 */
export function redirectToNewDomain() {
  if (pathname === '/') {
    document.body.innerHTML = '<h1>Not found</h1>';
  } else {
    const restPath = rest.join('/');
    const realRestPath = restPath.startsWith('report-damage') ? '' : restPath;
    const newUrl = `${protocol}//${customerName}.${REACT_APP_ROOT_DOMAIN}/${realRestPath}${search}`;

    window.location.replace(newUrl);
  }
}
