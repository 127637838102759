import { loadApp } from './app-loader';
import { redirectToNewDomain } from './app-redirect';
import { OLD_WEBAPP_DOMAIN } from './constants';

/**
 * Cases:
 * - OLD_WEBAPP_DOMAIN - redirect
 * - REACT_APP_ROOT_DOMAIN - load app
 * - other cases suppose customer domain support - load app
 */
if (OLD_WEBAPP_DOMAIN.test(window.location.host)) {
  redirectToNewDomain();
} else {
  loadApp();
}
