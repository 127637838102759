import React, { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';

import {
  ReactZoomPanPinchContentRef,
  ReactZoomPanPinchProps,
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';

import { DEFAULT_INITIAL_SCALE } from 'src/apps/NewDriverApp/ui-components/ZoomContainer/constants';

import styles from './ZoomContainer.module.scss';

type WrapperProps = Omit<ReactZoomPanPinchProps, 'ref'> &
  React.RefAttributes<ReactZoomPanPinchContentRef>;

type ZoomContainerChildren = ReactElement<unknown, string | JSXElementConstructor<unknown>> | null;

interface ContentProps {
  wrapperClass?: string;
  contentClass?: string;
}

interface Props {
  wrapperProps?: WrapperProps;
  contentProps?: ContentProps;
  children: ZoomContainerChildren;
  initialScale?: number;
  isWithZoom?: boolean;
}

const ZoomContainer: React.FC<Props> = ({
  wrapperProps,
  contentProps,
  children,
  initialScale = DEFAULT_INITIAL_SCALE,
  isWithZoom = false,
}) => {
  // Is needed to disable event propagation blocking. Sample issue: https://github.com/prc5/react-zoom-pan-pinch/issues/143
  const [scale, setScale] = useState(DEFAULT_INITIAL_SCALE);

  useEffect(() => {
    setScale(initialScale);
  }, [initialScale]);

  if (!isWithZoom) {
    return children;
  }

  return (
    <TransformWrapper
      {...wrapperProps}
      initialScale={scale}
      panning={{
        disabled: scale === DEFAULT_INITIAL_SCALE,
        velocityDisabled: true,
      }}
      onZoom={(ref: ReactZoomPanPinchRef) => setScale(ref.state.scale)}
    >
      <TransformComponent
        contentClass={styles.zoomContainerContent}
        wrapperClass={styles.zoomContainerWrapper}
        {...contentProps}
      >
        {children}
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomContainer;
