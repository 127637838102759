import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
  completeAccidentReport,
  completeAccidentReportSuccess,
  completeAccidentReportFailure,
} from '../actions';

const initialState: {
  isCompleted: boolean;
  isCompleting: boolean;
  isCompletingError: null | Error;
} = {
  isCompleted: false,
  isCompleting: false,
  isCompletingError: null,
};

export const saveAccidentReportReducer = createReducer(initialState, {
  [completeAccidentReport.type]: state => {
    Object.assign(state, {
      isCompleting: true,
      isCompletingError: null,
    });
  },
  [completeAccidentReportSuccess.type]: state => {
    Object.assign(state, {
      isCompleted: true,
      isCompleting: false,
    });
  },
  [completeAccidentReportFailure.type]: (state, action: PayloadAction<{ error: Error }>) => {
    Object.assign(state, {
      isCompleting: false,
      isCompletingError: action.payload.error,
    });
  },
});

export type AccidentSavingReportReducer = ReturnType<typeof saveAccidentReportReducer>;
