import React, { SyntheticEvent } from 'react';

import cn from 'classnames';

import styles from './PhotoInputIconPlaceholder.module.scss';

interface Props {
  onClick: (e: SyntheticEvent) => void;
  className?: string;
}

const PhotoInputEditIcon: React.FC<Props> = ({ children, className, onClick }) => (
  <div className={cn(styles.container, className)} onClick={onClick}>
    {children}
  </div>
);

export default PhotoInputEditIcon;
