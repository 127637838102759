import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { DamageReportDrivableType } from 'src/api/globalTypes';

import { setIsCarRoadworthy } from '../actions';
import { AccidentCarConditionReducer } from '../types';

const initialState: AccidentCarConditionReducer = {};

export const accidentCarConditionReducer = createReducer(initialState, {
  [setIsCarRoadworthy.type]: (state, action: PayloadAction<DamageReportDrivableType>) => {
    state.isCarRoadworthy = action.payload;
  },
});
