import { createReducer } from '@reduxjs/toolkit';
import get from 'lodash/get';

import {
  getDamageReportInfoSuccess,
  setDamageReportInfoFleetComment,
  setDamageReportInfoMinority,
  setDamageReportInfoStatus,
} from 'src/apps/NewDriverApp/redux/actions';
import { InfoReducer } from 'src/apps/NewDriverApp/redux/types/Types';

const initialState: {
  isMinor?: InfoReducer['isMinor'];
  status?: InfoReducer['status'];
  fleetComment?: InfoReducer['fleetComment'];
} = {};

export const currentProcessingReportReducer = createReducer(initialState, {
  [setDamageReportInfoStatus.type]: (state, { payload }) => {
    return {
      ...state,
      status: payload,
    };
  },
  [setDamageReportInfoMinority.type]: (state, { payload }) => {
    return {
      ...state,
      isMinor: payload,
    };
  },
  [getDamageReportInfoSuccess.type]: (state, { payload }) => {
    state.isMinor = get(payload, 'info.isMinor');
    state.status = get(payload, 'info.status');
  },
  [setDamageReportInfoFleetComment.type]: (state, { payload }) => {
    state.fleetComment = payload;
  },
});
