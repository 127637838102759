import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import pick from 'lodash/pick';

import { CODE_KEY } from 'src/constants';

import { CAR_CODE_STORAGE_KEY, DAMAGE_CODE_STORAGE_KEY, MANAGER_CODE_KEY } from '../constants';
import { CHECKLIST_CODE_STORAGE_KEY } from '../flows/fleet/checklist/constants';

const PUBLIC_JWT_CONTENT = [
  'id',
  'carId',
  'checklistId',
  'name',
  'type',
  'flow',
  'fleet',
  'iat',
  'exp',
];

const TOKEN_NAMES = [
  DAMAGE_CODE_STORAGE_KEY,
  CODE_KEY,
  CAR_CODE_STORAGE_KEY,
  CHECKLIST_CODE_STORAGE_KEY,
  MANAGER_CODE_KEY,
];

const getDecodedTokens = (state: any) => {
  let decodedTokens = {};

  TOKEN_NAMES.forEach(tokenKey => {
    const token = get(state, `auth.tokens.${tokenKey}`);

    const decodedToken = token && pick(jwtDecode(token), PUBLIC_JWT_CONTENT);

    decodedTokens = {
      ...decodedTokens,
      ...(token && {
        [tokenKey]: decodedToken,
      }),
    };
  });

  return decodedTokens;
};

export const persistTelemetry =
  (getState: () => Record<string, any>) => (telemetry: { data?: any }) => {
    // TODO: add storage data
    telemetry.data = {
      ...telemetry.data,
      jwt: getDecodedTokens(getState()),
    };
  };
