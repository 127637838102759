import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';

/**
 * Supports localization via
 * - camelcased suffix. eg. message -> messageEn
 * - snakecased suffix. eg message -> message_en
 * - direct value. eg message -> message
 * - localizition suffix. the value by than key is then used as localization key. eg message_i18n -> t(obj[message_i18n])
 *
 * @param namespace
 * @returns
 */
export const useLocalized = (namespace?: string) => {
  const { i18n, t } = useTranslation(namespace);

  const lang = i18n.language;

  return <T>(obj: T, key: keyof T) => {
    const objectKey = typeof key === 'string' ? key : '';

    const translation =
      obj[`${objectKey}_${lang}` as keyof T] ||
      obj[`${objectKey}${capitalize(lang)}` as keyof T] ||
      obj[key] ||
      t(obj[`${objectKey}_i18n` as keyof T] as any);

    return typeof translation === 'string' ? translation : undefined;
  };
};
