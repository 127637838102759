import { createAction } from '@reduxjs/toolkit';

import { CarAvailabilityStatus } from 'src/api/globalTypes';

import { CarInformation, CarListFilters, CarListReducer, ReportNavigation } from '../../../types';

export const setCarListData = createAction<Partial<CarListReducer>>('SET_CAR_LIST_DATA');

export const fetchCarList = createAction('GET_CAR_LIST');

export const resetCarList = createAction('RESET_CAR_LIST');

export const addNextPageCars =
  createAction<Pick<CarListReducer, 'total' | 'data' | 'filters'>>('ADD_NEXT_PAGE_CARS');

export const setCarListFilters = createAction<Partial<CarListFilters>>('SET_CAR_LIST_FILTERS');

export const fetchCarDetails = createAction('GET_CAR_DETAILS');

export const setCarInformationData = createAction<Partial<CarInformation>>(
  'SET_CAR_INFORMATION_DATA',
);

export const changeCarDetailsStatus = createAction<CarAvailabilityStatus>(
  'CHANGE_CAR_DETAILS_STATUS',
);

export const fetchCarDetailsData = createAction('FETCH_CAR_DETAILS_DATA');

export const serReportNavigationData = createAction<Partial<ReportNavigation>>(
  'SER_REPORT_NAVIGATION_DATA',
);
