import React, { FC, ReactNode } from 'react';

import cx from 'classnames';

import './o-clickable-area.scss';

interface Props {
  readonly children?: ReactNode;
  readonly className?: string;
  readonly active?: boolean;
  readonly onClick?: () => void;
  readonly onKeyPress?: () => void;
}

const ClickableArea: FC<Props> = ({ children, className, onClick, onKeyPress }) => (
  <div className={cx('o-clickable-area', className)}>
    <div
      className="o-clickable-area__overlay"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onKeyPress}
    ></div>
    {children}
  </div>
);

export default ClickableArea;
