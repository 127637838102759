export type Base64 = string;

export const toBase64 = (file: Blob | File) => {
  const reader = new FileReader();

  return new Promise<Base64>((resolve, reject) => {
    reader.onerror = error => reject(error);

    reader.onload = () => resolve(reader.result as Base64);

    reader.readAsDataURL(file);
  });
};

const toArrayBuffer = (file: Blob) => {
  const reader = new FileReader();

  return new Promise<ArrayBuffer>((resolve, reject) => {
    reader.onerror = error => reject(error);

    reader.onload = () => resolve(reader.result as ArrayBuffer);

    reader.readAsArrayBuffer(file);
  });
};

const toImage = (data: Blob) => {
  const img = new Image();
  img.src = URL.createObjectURL(data);

  return new Promise<HTMLImageElement>(resolve => {
    img.onload = () => {
      resolve(img);
      URL.revokeObjectURL(img.src);
    };
  });
};

export default { toImage, toBase64, toArrayBuffer };
