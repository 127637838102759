import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setChecklistTemplateId, setChecklistTemplateChecks } from '../actions';
import { ChecklistTemplateReducer } from '../types';

const initialState: ChecklistTemplateReducer = {
  checklistTemplateId: null,
  checks: [],
};

export const checklistTemplateReducer = createReducer(initialState, {
  [setChecklistTemplateId.type]: (
    state,
    action: PayloadAction<Omit<ChecklistTemplateReducer, 'checks'>>,
  ) => {
    state.checklistTemplateId = action.payload.checklistTemplateId;
  },
  [setChecklistTemplateChecks.type]: (
    state,
    action: PayloadAction<Omit<ChecklistTemplateReducer, 'checklistTemplateId'>>,
  ) => {
    state.checks = action.payload.checks;
  },
});
