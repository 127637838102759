import { IApplicationInsights } from '@microsoft/applicationinsights-web';

export interface ExtendedApplicationInsights extends IApplicationInsights {
  trackGraphQLOperation: (
    payload: Record<string, unknown>,
    type: GRAPHQL_OPERATION_TRACE_TYPE,
  ) => ReturnType<IApplicationInsights['trackTrace']>;
  loggerContext?: string;
}

export enum TRACE_MESSAGE {
  GRAPHQL_OPERATION_START = 'CAPTURED_GRAPHQL_OPERATION_START',
  GRAPHQL_OPERATION_END = 'CAPTURED_GRAPHQL_OPERATION_END',
  LOCIZE_EMPTY_NAMESPACE = 'CAPTURE_LOCIZE_EMPTY_NAMESPACE',
}

export enum GRAPHQL_OPERATION_TRACE_TYPE {
  START = 'START',
  END = 'END',
}
