import { configureStore } from '@reduxjs/toolkit';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducer } from 'src/apps/NewDriverApp/redux/root-reducer';
import { CODE_KEY } from 'src/constants';
import { getLogger } from 'src/services/app-insights';

import { PersistStorage } from './persist-service';
import { persistTelemetry } from './persist-telemetry';
import { PersistType, StorageMapper } from './types/Types';
import {
  CAR_CODE_STORAGE_KEY,
  DAMAGE_CODE_STORAGE_KEY,
  FLOW_STORAGE_KEY,
  IS_CODE_BASE_AUTH,
  MANAGER_CODE_KEY,
  CODE_URL_PARAM_KEY,
  SESSION_ID,
  APP_NAME,
} from '../constants';
import { CHECKLIST_CODE_STORAGE_KEY } from '../flows/fleet/checklist/constants';

const STORE_LOGGER_CONTEXT_IDENTIFIER = 'store';

const appInsights = getLogger(STORE_LOGGER_CONTEXT_IDENTIFIER);

const reduxKeyMap: StorageMapper = {
  [`auth.tokens.${CHECKLIST_CODE_STORAGE_KEY}`]: {
    type: PersistType.SESSION,
    path: CHECKLIST_CODE_STORAGE_KEY,
  },
  [`auth.tokens.${CODE_KEY}`]: {
    type: PersistType.SESSION,
    path: CODE_KEY,
  },
  [`auth.tokens.${DAMAGE_CODE_STORAGE_KEY}`]: {
    type: PersistType.SESSION,
    path: DAMAGE_CODE_STORAGE_KEY,
  },
  [`auth.tokens.${CAR_CODE_STORAGE_KEY}`]: {
    type: PersistType.SESSION,
    path: CAR_CODE_STORAGE_KEY,
  },
  [`auth.${FLOW_STORAGE_KEY}`]: {
    type: PersistType.SESSION,
    path: FLOW_STORAGE_KEY,
  },
  [`auth.${IS_CODE_BASE_AUTH}`]: {
    type: PersistType.SESSION,
    path: IS_CODE_BASE_AUTH,
  },
  [`app.${SESSION_ID}`]: {
    type: PersistType.SESSION,
    path: SESSION_ID,
  },
  [`camera.isCameraOpened`]: {
    type: PersistType.IGNORE,
  },
  [`camera.selectedPhoto`]: {
    type: PersistType.IGNORE,
  },
  [`auth.tokens.${MANAGER_CODE_KEY}`]: {
    type: PersistType.IGNORE,
  },
  [`user.data.email`]: {
    type: PersistType.IGNORE,
  },
  [`auth.appName`]: {
    type: PersistType.PERSIST,
    path: APP_NAME,
  },
};

const persistStorageService = new PersistStorage(reduxKeyMap);

// resetting storage if code is present in url
const params = new URLSearchParams(document.location.search.slice(1));
const code = params.get(CODE_URL_PARAM_KEY);
const reset = params.get('reset');
if (code || reset) {
  persistStorageService.reset();
}

export const sagaMiddleware = createSagaMiddleware({
  onError: (error, { sagaStack }) => {
    console.error('ERROR in Saga', error, sagaStack);

    appInsights.trackException({
      exception: error,
      properties: {
        sagaStack,
      },
    });
  },
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: !['production', 'testing'].includes(process.env.NODE_ENV),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddleware),
  preloadedState: persistStorageService.hydrate(),
});

export type AppDispatch = typeof store.dispatch;
const useAppDispatch = () => useDispatch<AppDispatch>(); // Export a hook that can be reused to resolve types

export const getState = () => store.getState();

const persistStateHandler = () => {
  const state = getState();
  persistStorageService.dehydrate(state);
};

const debouncedPersistStateHandler = debounce(persistStateHandler, 1000);

store.subscribe(debouncedPersistStateHandler);

appInsights.addTelemetryInitializer(persistTelemetry(getState));

export { useAppDispatch };
