import { createReducer } from '@reduxjs/toolkit';

import { CarStateFormInputs, DamageReportVerificationReducer } from '../../types';
import {
  completeDamageVerification,
  completeDamageVerificationSuccess,
  completeDamageVerificationFailure,
  setAdditionalComment,
  selectDamageReportDetailsFlow,
  setCarState,
  setYourInformation,
  setIsEditingMode,
  updateYourInformation,
  setIsVerifyInitializing,
  setDamageVerificationIsCompleting,
  setVerificationError,
} from '../actions';

const initialState: DamageReportVerificationReducer = {
  message: '',
  flow: null,
  isEditing: false,
  isInitializing: false,
  error: null,
  savingStatus: {
    isCompleted: false,
    isCompleting: false,
    isDamageConfirmed: false,
    error: null,
  },
  carState: {
    [CarStateFormInputs.AVAILABILITY]: null,
    [CarStateFormInputs.ADDITIONAL_COMMENT]: null,
  },
  yourInformation: {
    reporterEmail: '',
    reporterName: '',
    reporterPhone: '',
  },
};

export const damageReportVerificationReducer = createReducer(initialState, {
  [completeDamageVerification.type]: (state, action) => {
    state.savingStatus.isCompleting = true;
    state.savingStatus.isDamageConfirmed = action.payload.isDamageConfirmed;
    state.savingStatus.error = null;
  },
  [completeDamageVerificationSuccess.type]: state => {
    state.savingStatus.isCompleted = true;
    state.savingStatus.isCompleting = false;
  },
  [completeDamageVerificationFailure.type]: (state, action) => {
    state.savingStatus.isCompleted = false;
    state.savingStatus.isCompleting = false;
    state.savingStatus.error = action.payload.error;
  },
  [setAdditionalComment.type]: (state, { payload }) => {
    state.message = payload;
  },
  [selectDamageReportDetailsFlow.type]: (state, { payload }) => {
    state.flow = payload;
  },
  [setCarState.type]: (state, { payload }) => {
    state.carState = { ...state.carState, ...payload };
  },
  [setYourInformation.type]: (state, { payload }) => {
    state.yourInformation = payload.data;
  },
  [updateYourInformation.type]: (state, { payload }) => {
    state.yourInformation = { ...state.yourInformation, ...payload };
  },
  [setIsEditingMode.type]: (state, { payload }) => {
    state.isEditing = payload;
  },
  [setIsVerifyInitializing.type]: (state, { payload }) => {
    state.isInitializing = payload;
  },
  [setDamageVerificationIsCompleting.type]: (state, { payload }) => {
    state.savingStatus.isCompleting = payload;
  },
  [setVerificationError.type]: (state, { payload }) => {
    state.error = payload;
  },
});
