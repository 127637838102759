import { PhotoInputType } from './types';

export const getPlaceholderProps = ({
  inputType,
  isRequired,
}: {
  inputType: PhotoInputType;
  isRequired?: boolean;
}) => {
  const common = {
    width: '75%',
    height: '75%',
    isRequired,
  };

  const mediumWithText = {
    width: '35%',
    height: '35%',
    isRequired,
  };

  if (inputType === PhotoInputType.MEDIUM_WITH_TEXT) {
    return mediumWithText;
  }

  return common;
};

export const getProgressProps = ({
  inputType,
  backgroundLineColor,
  progress,
}: {
  inputType: PhotoInputType;
  backgroundLineColor: string;
  progress?: number;
}) => {
  if (inputType === PhotoInputType.MEDIUM_WITH_TEXT) {
    return {
      size: 1.4,
      lineWidth: 3,
      backgroundLineColor,
      progress: progress ?? 0,
    };
  }

  return {
    size: 0.7,
    lineWidth: 7,
    progress: progress ?? 0,
    lineColor: 'var(--primary-background-base)',
  };
};
