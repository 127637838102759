import { createReducer } from '@reduxjs/toolkit';

import { setScheduleAppointmentDate } from 'src/apps/NewDriverApp/redux/actions';

enum Appointments {
  APPOINTMENT1 = 'appointment1',
  APPOINTMENT2 = 'appointment2',
  APPOINTMENT3 = 'appointment3',
}

export interface ScheduleAppointmentReducer {
  [key: string]: string;
}

const initialState: ScheduleAppointmentReducer = {
  appointment1: '',
  appointment2: '',
  appointment3: '',
};

export const scheduleAppointmentReducer = createReducer(initialState, {
  [setScheduleAppointmentDate.type]: (state, { payload }) => {
    if (payload.fieldName === Appointments.APPOINTMENT1) state.appointment1 = payload.value;
    if (payload.fieldName === Appointments.APPOINTMENT2) state.appointment2 = payload.value;
    if (payload.fieldName === Appointments.APPOINTMENT3) state.appointment3 = payload.value;
  },
});
