import { isDesktop, isTablet } from 'react-device-detect';

import { CHECKLIST_CODE_STORAGE_KEY } from 'src/apps/NewDriverApp/flows/fleet/checklist/constants';
import config from 'src/config';

import { getApiUrl } from './api-url';
import {
  CAR_CODE_STORAGE_KEY,
  DAMAGE_CODE_STORAGE_KEY,
  FLOW_STORAGE_KEY,
} from './apps/NewDriverApp/constants';

export const PROJECT_NAME = 'Web';
export const ROOT_ELEMENT_ID = 'root';
export const API_URL = getApiUrl();
export const OLD_WEBAPP_DOMAIN = /b2b.*\.repairfix\.eu/;
export const BUILD_ID = config.REACT_APP_BUILD_ID;

// Locale storage keys
export { IS_CODE_BASE_AUTH } from './apps/NewDriverApp/constants';

export const CODE_KEY = 'code';
export const APPOINTMENT_CODE_KEY = 'appointment_code';
export const API_KEY = 'apiKey';
export const LOCALE_KEY = 'locale';
export const LOCAL_STORAGE_TOKEN_KEYS = [
  CODE_KEY,
  DAMAGE_CODE_STORAGE_KEY,
  CAR_CODE_STORAGE_KEY,
  CHECKLIST_CODE_STORAGE_KEY,
  FLOW_STORAGE_KEY,
];

export const DRIVER_EMAIL = 'driver_email';

export const USER_LAST_ACTIVITY_TIME = 'userLastActivityTime';

/**
 * NOTE: Must match related file names
 */
export enum WEB_APPS {
  APPOINTMENT_CONFIRMATION_APP = 'appointment-confirmation-app',
  DRIVER_OVERVIEW_APP = 'driver-overview-app',
  DIGITAL_RECEPTION_APP = 'new-driver-app',
}

export interface WebAppConfig {
  isDriverFacingApp: boolean;
  isPreinspection: boolean;
  isDigitalReception: boolean;
  isNewDriverApp: boolean;
}

export enum LANG {
  DE = 'de',
  EN = 'en',
  ES = 'es',
  GB = 'gb',
  PL = 'pl',
  AR = 'ar',
  TR = 'tr',
  RU = 'ru',
  IT = 'it',
  UA = 'ua',
}

export const LOCIZE_URL = 'https://api.locize.app';

export const USER_LOGOUT_DOWNTIME = 900000; // 15 minutes

export enum ENVS {
  PRODUCTION = 'production',
  STAGING = 'staging',
  LOCAL = 'local',
}

export const IS_DEV = process.env.NODE_ENV === 'development';

export const TENANT = (() => {
  if (typeof window === 'undefined') {
    return '';
  }

  const [subdomain] = window.location.hostname.split('.');

  return subdomain.toLowerCase();
})();

export const ENV_NAME: ENVS = (() => {
  if (typeof document === 'undefined') {
    return ENVS.LOCAL;
  }

  const isStaging = document.location.href.includes('.staging.motum.app/');
  const isProduction = !isStaging && document.location.href.includes('.motum.app/');

  if (isProduction) {
    return ENVS.PRODUCTION;
  }

  return isStaging ? ENVS.STAGING : ENVS.LOCAL;
})();

export const isPC = isDesktop && !isTablet;

export const NOT_FOUND_STATUS_CODE = '404';

export const DAMAGE_NUMBER_INTERNAL_PREFIX = 'RF-';

export enum IMAGE_PREVIEW_TYPE {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  THUMBNAIL = 'Thumbnail',
}
