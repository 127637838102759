import format from 'date-fns/format';

import { DATE_TIME_FORMAT } from '../constants';

const formatDate = (
  date?: string | Date | null,
  template = DATE_TIME_FORMAT,
  options?: { locale?: Locale },
) => {
  try {
    if (date) {
      return format(new Date(date), template, options);
    }

    return '';
  } catch (error) {
    return '';
  }
};

export default formatDate;
