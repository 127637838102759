import { createReducer } from '@reduxjs/toolkit';

import { updateOtherAccidentPartyData } from 'src/apps/NewDriverApp/redux/actions';
import { OtherAccidentPartyReducer } from 'src/apps/NewDriverApp/redux/types/Types';

const initialState: OtherAccidentPartyReducer = {
  driverName: '',
  licensePlate: '',
};

export const otherAccidentPartyReducer = createReducer(initialState, {
  [updateOtherAccidentPartyData.type]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
});
