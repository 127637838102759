import React, { useRef, useEffect, useState } from 'react';

interface Props {
  progress?: number;
  size?: number;
  lineColor?: string;
  backgroundLineColor?: string;
  lineWidth?: number;
}

const CircularProgress: React.FC<Props> = ({
  progress = 0,
  size = 1,
  lineWidth = 4,
  lineColor = 'var(--primary-accent)',
  backgroundLineColor = '#323232',
}) => {
  const [total, setTotal] = useState(0);
  const pathRef = useRef() as React.MutableRefObject<SVGPathElement>;
  const scale = `${size * 100}px`;
  const currentProgress = (progress * total) / 100;

  useEffect(() => {
    if (pathRef.current) {
      setTotal(pathRef.current.getTotalLength());
    }
  }, []);

  return (
    <svg
      style={{
        width: scale,
        height: scale,
      }}
      viewBox="0 0 200 200"
    >
      <g>
        <path
          fill="none"
          strokeWidth={lineWidth}
          stroke={backgroundLineColor}
          strokeDasharray={`${total},${total}`}
          d="M100 60 
             A1 1 0 0 1 100 140
             A1 1 0 0 1 100 60"
        />
      </g>
      <g>
        <path
          ref={pathRef}
          fill="none"
          strokeWidth={lineWidth}
          stroke={lineColor}
          strokeDasharray={`${currentProgress},${total}`}
          d="M100 60 
             A1 1 0 0 1 100 140
             A1 1 0 0 1 100 60"
        />
      </g>
    </svg>
  );
};

export default CircularProgress;
