import React from 'react';

import cn from 'classnames';

import styles from './Button.module.scss';
import {
  BUTTON_ADORNMENT_SIZE_CLASS_MAPPER,
  BUTTON_SIZE,
  BUTTON_SPINNER_SIZE_CLASS_MAPPER,
  BUTTON_TEXT_TYPE_MAPPER,
  BUTTON_VARIANT,
} from './constants';
import { ButtonProps } from './types';
import Spinner from '../Spinner';
import Text from '../Text';

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  isLoading,
  disabled,
  startAdornment,
  variant = BUTTON_VARIANT.PRIMARY,
  size = BUTTON_SIZE.MEDIIM,
  ...restProps
}) => {
  const variantClassName = styles[variant];
  const sizeClassName = styles[size];
  const spinnerSizeClassName = styles[BUTTON_SPINNER_SIZE_CLASS_MAPPER[size]];
  const adornmentSizeClassName = styles[BUTTON_ADORNMENT_SIZE_CLASS_MAPPER[size]];
  const textType = BUTTON_TEXT_TYPE_MAPPER[size];

  const spinner = (
    <Spinner
      className={spinnerSizeClassName}
      color={cn({
        '--button-primary-spinner-color': variant === BUTTON_VARIANT.PRIMARY,
        '--button-secondary-spinner-color': variant === BUTTON_VARIANT.SECONDARY,
      })}
      width="2px"
    />
  );

  const textContent = (
    <Text type={textType} color="inherit">
      {startAdornment && (
        <span className={cn(styles.startAdornment, adornmentSizeClassName)}>
          &zwnj;{startAdornment}
        </span>
      )}
      <span>{children}</span>
    </Text>
  );

  const content = isLoading ? spinner : textContent;

  return (
    <button
      className={cn(
        styles.button,
        variantClassName,
        sizeClassName,
        { [styles.loading]: isLoading },
        className,
      )}
      disabled={disabled}
      {...restProps}
    >
      {content}
    </button>
  );
};

export default Button;
