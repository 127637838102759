import React from 'react';

import CarIcon from 'src/apps/NewDriverApp/assets/icons/suv-pic.svg';

import styles from './CarInformation.module.scss';
import { InfoCar } from '../../redux/types/Types';
import Text from '../Text';

export type CarInformationProps = Omit<InfoCar, 'licensePlate'> & { licensePlate?: string };

const CarInformation: React.FC<CarInformationProps> = ({ make, model, licensePlate }) => {
  const encircledWithParenthesesLicensePlate = licensePlate ? `(${licensePlate})` : '';
  const info = [make, model, encircledWithParenthesesLicensePlate].filter(Boolean).join(' ');

  return (
    <div className={styles.container}>
      <img className={styles.carIcon} src={CarIcon} alt="Car" />
      <Text type="body-small-medium" color="secondary-content">
        {info}
      </Text>
    </div>
  );
};

export { CarInformation };
