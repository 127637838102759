import React from 'react';

import { IconProps } from '../../types';

const ExclamationTriangle: React.FC<IconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M4.58391 17.4167H17.4172C17.7164 17.4146 18.0104 17.3393 18.2738 17.1975C18.5371 17.0556 18.7618 16.8515 18.9281 16.6029C19.0944 16.3543 19.1974 16.0687 19.2281 15.7712C19.2587 15.4737 19.2161 15.1731 19.1039 14.8958L12.5956 3.66667C12.437 3.38012 12.2046 3.14126 11.9225 2.97494C11.6404 2.80862 11.3189 2.72089 10.9914 2.72089C10.6639 2.72089 10.3424 2.80862 10.0603 2.97494C9.7782 3.14126 9.54579 3.38012 9.38725 3.66667L2.87891 14.8958C2.76886 15.1668 2.72522 15.4601 2.75164 15.7514C2.77805 16.0426 2.87376 16.3233 3.03076 16.57C3.18777 16.8168 3.40152 17.0223 3.65416 17.1696C3.90681 17.3169 4.19103 17.4016 4.48308 17.4167M11.0004 8.25V10.0833M11.0004 13.75V13.7592"
        stroke="currentColor"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExclamationTriangle;
