import { useContext, createContext } from 'react';

import { RequiredImages } from 'src/apps/NewDriverApp/ui-components/ImagesBlock/types';

export const ImagesBlockRequiredImagesContext = createContext<{
  requiredImages: RequiredImages[] | undefined;
}>({
  requiredImages: [],
});
export const useRequiredImagesContext = () => {
  const { requiredImages } = useContext(ImagesBlockRequiredImagesContext);

  return requiredImages;
};
