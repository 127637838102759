import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppSegmentType } from 'src/api/globalTypes';
import {
  appSegmentSelector,
  bodyshopInfo,
  isInEmbeddedMode,
} from 'src/apps/NewDriverApp/redux/selectors';

import styles from './LegalPages.module.scss';
import { ROUTES } from '../../constants';
import { useGetLinkQuery } from '../../hooks/useGetLinkQuery';
import Link from '../Link';
import Text from '../Text';

export interface LegalPagesProps {
  isLinksDisplay?: boolean;
}

const LegalPages: React.FC<LegalPagesProps> = ({ isLinksDisplay = true }) => {
  const { t } = useTranslation('legal');
  const getLinkQuery = useGetLinkQuery();

  const showCookieModal = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  const segment = useSelector(appSegmentSelector);

  const {
    addressCity = '',
    addressHouse = '',
    addressStreet = '',
    addressZip = '',
    email = '',
    phoneNumber = '',
    title = '',
  } = useSelector(bodyshopInfo) || {};

  const isEmbedded = useSelector(isInEmbeddedMode);

  if (isEmbedded) {
    return null;
  }

  const fullAddress = `${addressStreet}, ${addressHouse}, ${addressCity}, ${addressZip}`;

  const privacyQuery = getLinkQuery({
    name: title,
    address: fullAddress,
    email,
  });

  const termsAndConditionQuery = getLinkQuery({
    name: title,
    address: fullAddress,
  });

  const cancellationPolicyQuery = getLinkQuery({
    name: title,
    address: fullAddress,
    phone: phoneNumber as string,
    email,
  });

  const privacyURL = `${ROUTES.BODYSHOP_PRIVACY_POLICY}?${privacyQuery}`;
  const termsAndConditionsURL = `${ROUTES.BODYSHOP_TERMS_AND_CONDITIONS}?${termsAndConditionQuery}`;
  const cancellationPolicyURL = `${ROUTES.CANCELLATION_POLICY}?${cancellationPolicyQuery}`;

  const fleetLegal = [
    {
      text: t('dataProtection'),
      link: ROUTES.PRIVACY_POLICY,
    },
    {
      text: t('conditions'),
      link: ROUTES.TERMS_AND_CONDITIONS,
    },
    {
      text: t('legalNotice'),
      link: ROUTES.IMPRESSUM,
    },
  ];
  const bodyshopLegal = [
    {
      text: t('dataProtection'),
      link: privacyURL,
    },
    {
      text: t('conditions'),
      link: termsAndConditionsURL,
    },
    {
      text: t('legalNotice'),
      link: cancellationPolicyURL,
    },
  ];

  const dot = <span className={styles.separator}>·</span>;
  const itemsToRender = segment === AppSegmentType.BODYSHOPS ? bodyshopLegal : fleetLegal;

  return (
    <div className={styles.container}>
      {isLinksDisplay && (
        <div className={styles.navigation}>
          {itemsToRender.map((route, i) => {
            const isFirstItem = i === 0;

            return (
              <React.Fragment key={route.link}>
                {!isFirstItem && dot}
                <Link
                  className={styles.link}
                  to={`/${route.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="link-small"
                  variant="secondary"
                >
                  {route.text}
                </Link>
              </React.Fragment>
            );
          })}

          <span className={styles.cookieSettingsContainer}>
            {dot}
            <Link
              to="/"
              className={styles.link}
              onClick={showCookieModal}
              size="link-small"
              variant="secondary"
            >
              {t('cookieSettings')}
            </Link>
          </span>
        </div>
      )}

      <Text className={styles.footer} type="body-xsmall" color="undefined">
        {t('footer')}
      </Text>
    </div>
  );
};

export { LegalPages };
