import { createReducer } from '@reduxjs/toolkit';
import { Location } from 'history';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { CarPartType } from 'src/api/globalTypes';
import { OPPONENT_DOMAIN } from 'src/apps/NewDriverApp/flows/fleet/opponent/constants';
import {
  VERIFY_REPORT_ROUTE_NAMES,
  VERIFY_REPORT_FLOW_DOMAIN,
} from 'src/apps/NewDriverApp/flows/fleet/verify-report/constants';

import { ROUTES } from '../../constants';
import { selectCarParts, unselectCarParts, selectTempCarParts, routeChanged } from '../actions';
import { RouteChanged } from '../types/Types';

const { VERIFY_REPORT_EDIT_CAR_PARTS } = VERIFY_REPORT_ROUTE_NAMES;
const { CAR_PARTS, ACCIDENT, ACCIDENT_WITHOUT_OTHER_CAR, MENU } = ROUTES;

const isCarPartPages = (currentLocation: Location) => {
  const pathname = get(currentLocation, 'pathname', '');

  return [
    VERIFY_REPORT_EDIT_CAR_PARTS,
    CAR_PARTS,
    `${ACCIDENT}/${CAR_PARTS}`,
    `${ACCIDENT_WITHOUT_OTHER_CAR}/${CAR_PARTS}`,
    `${VERIFY_REPORT_FLOW_DOMAIN}/${VERIFY_REPORT_EDIT_CAR_PARTS}`,
    `${OPPONENT_DOMAIN}/${CAR_PARTS}`,
  ].some(route => pathname.startsWith(`/${route}`));
};

const isCarPartPreviousPage = (previousLocation: Location | null) => {
  const pathname = get(previousLocation, 'pathname', '');
  return [VERIFY_REPORT_EDIT_CAR_PARTS, CAR_PARTS].some(route => pathname.endsWith(route));
};

const shouldClearTempCarParts = (payload: RouteChanged, selectedCarPartsTemp: CarPartType[]) => {
  return (
    isCarPartPreviousPage(payload.previousLocation) &&
    !isCarPartPages(payload.currentLocation) &&
    !isEmpty(selectedCarPartsTemp)
  );
};

export interface CarPartsReducer {
  selectedCarParts: CarPartType[];
  selectedCarPartsTemp: CarPartType[];
}

const initialState: CarPartsReducer = {
  selectedCarParts: [],
  selectedCarPartsTemp: [],
};

export const carPartsReducer = createReducer(initialState, {
  [selectCarParts.type]: (state, { payload }) => {
    state.selectedCarParts = uniq([...payload]);
  },
  [unselectCarParts.type]: (state, { payload }) => {
    state.selectedCarParts = state.selectedCarParts.filter(
      carPartType => !payload.includes(carPartType),
    );
  },
  [selectTempCarParts.type]: (state, { payload }) => {
    state.selectedCarPartsTemp = uniq(payload);
  },
  [routeChanged.type]: (state, { payload }) => {
    if (shouldClearTempCarParts(payload, state.selectedCarPartsTemp)) {
      state.selectedCarPartsTemp = [];
    }

    if (payload.currentLocation.pathname === `/${MENU}`) {
      state.selectedCarParts = [];
    }
  },
});
