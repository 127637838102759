export const FLEET_MANAGER_DOMAIN = 'fleet-manager';
export const RESET_PASSWORD_TOKEN = 'resetPasswordToken';

export enum Pages {
  LOGIN = 'LOGIN',
  CAR_LIST = 'CAR_LIST',
  PROFILE = 'PROFILE',
  CAR_DETAILS = 'CAR_DETAILS',
  DAMAGE_LIST = 'DAMAGE_LIST',
  REPORT = 'REPORT',
  MENU = 'MENU',
  INTERIOR_PROBLEM = 'INTERIOR_PROBLEM',
  RESET_PASSWORD = 'RESET_PASSWORD',
  EMAIL_SENT = 'EMAIL_SENT',
  NEW_PASSWORD = 'NEW_PASSWORD',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
}

export const FLEET_MANAGER_NAMESPACES = {
  [Pages.LOGIN]: 'login',
  [Pages.CAR_LIST]: 'car-list',
  [Pages.PROFILE]: 'profile',
  [Pages.CAR_DETAILS]: 'car-details',
  [Pages.DAMAGE_LIST]: 'damage-list',
  [Pages.REPORT]: 'report',
  [Pages.MENU]: 'menu',
  [Pages.INTERIOR_PROBLEM]: 'interior-problem',
  [Pages.RESET_PASSWORD]: 'reset-password',
  [Pages.EMAIL_SENT]: 'email-sent',
  [Pages.NEW_PASSWORD]: 'new-password',
  [Pages.PASSWORD_CHANGED]: 'password-changed',
};

export const FLEET_MANAGER_ROUTES = {
  [Pages.LOGIN]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES[Pages.LOGIN]}`,
  [Pages.CAR_LIST]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES[Pages.CAR_LIST]}`,
  [Pages.DAMAGE_LIST]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES[Pages.DAMAGE_LIST]}`,
  [Pages.PROFILE]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES[Pages.PROFILE]}`,
  [Pages.CAR_DETAILS]: `/${FLEET_MANAGER_DOMAIN}/${
    FLEET_MANAGER_NAMESPACES[Pages.CAR_DETAILS]
  }/:carId`,
  [Pages.REPORT]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES[Pages.REPORT]}`,
  [Pages.MENU]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES.MENU}`,
  [Pages.INTERIOR_PROBLEM]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES.INTERIOR_PROBLEM}`,
  [Pages.RESET_PASSWORD]: `/${FLEET_MANAGER_DOMAIN}/${
    FLEET_MANAGER_NAMESPACES[Pages.RESET_PASSWORD]
  }`,
  [Pages.EMAIL_SENT]: `/${FLEET_MANAGER_DOMAIN}/${FLEET_MANAGER_NAMESPACES[Pages.EMAIL_SENT]}`,
  [Pages.NEW_PASSWORD]: `/${FLEET_MANAGER_DOMAIN}/${
    FLEET_MANAGER_NAMESPACES[Pages.NEW_PASSWORD]
  }/:${RESET_PASSWORD_TOKEN}`,
  [Pages.PASSWORD_CHANGED]: `/${FLEET_MANAGER_DOMAIN}/${
    FLEET_MANAGER_NAMESPACES[Pages.PASSWORD_CHANGED]
  }`,
};

export const CARS_PER_LIST_PAGE = 20;
export const DAMAGE_PER_LIST_PAGE = 20;

export const EMAIL_NOT_FOUND_ERROR = 'NOT_SUCCESS';
