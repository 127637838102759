import { ENV_NAME, PROJECT_NAME, TENANT, BUILD_ID } from 'src/constants';

type AppCustomDimensions = {
  project: string;
  hostname: string;
  pathname: string;
  jwt?: Record<string, unknown>;
  storage?: Record<string, unknown>;
};

export function getCustomDimension(): AppCustomDimensions {
  const dimensions = {
    project: PROJECT_NAME,
    // TODO: (roman) Add ability to track different app names FleetDriverApp / BodyshopPreInspection
    hostname: window.location.hostname,
    pathname: window.location.pathname,
    tenant: TENANT,
    env: ENV_NAME,
    buildId: BUILD_ID,
  };

  return dimensions;
}

export const customDimensionTelemetry = (telemetry: { data?: any }) => {
  telemetry.data = {
    ...telemetry.data,
    ...getCustomDimension(),
  };
};
