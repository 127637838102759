import { ROUTES } from 'src/apps/NewDriverApp/constants';

export const OPPONENT_DOMAIN = 'opponent';

export const OPPONENT_FLOW_ROUTES = {
  WELCOME_SCREEN: 'welcome-screen',
  CAR_BODY_TYPES: ROUTES.CAR_BODY_TYPES,
  DATE_AND_LOCATION: 'date-and-location',
  CAR_PARTS: ROUTES.CAR_PARTS,
  DAMAGE_TYPE: ROUTES.DAMAGE_TYPE,
  DAMAGE_PHOTOS: ROUTES.DAMAGE_PHOTOS,
  ADDITIONAL_INFO: ROUTES.ADDITIONAL_INFO,
  CAR_IDENTIFICATION: ROUTES.CAR_IDENTIFICATION,
  DRIVER_INFORMATION: ROUTES.DRIVER_INFORMATION,
  INSURANCE_INFORMATION: 'insurance-information',
  OTHER_ACCIDENT_PARTY: ROUTES.OTHER_ACCIDENT_PARTY,
  OVERVIEW: ROUTES.OVERVIEW,
  DATE_LOCATION_PAGE: 'date-location',
  OTHER_ACCIDENT_PARTY_PAGE: 'other-accident-party',
  COMPLETED: ROUTES.COMPLETED,
};

export const otherAccidentPartyCompanyName = 'RMG Rohstoffmanagement GmbH'; // TODO dynamic ?
