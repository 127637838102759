import { wait } from '../../utils/wait';

interface FetchWithRetryOptions {
  operation: () => Promise<any>;
  retries: number;
  delay: number;
}

export const fetchWithRetry = async ({
  operation,
  delay,
  retries,
}: FetchWithRetryOptions): Promise<any> => {
  try {
    await wait(delay);
    return await operation();
  } catch (err) {
    if (retries > 1) {
      return fetchWithRetry({ operation, retries: retries - 1, delay });
    }

    return err;
  }
};
