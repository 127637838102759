import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import set from 'lodash/set';

import {
  ChecklistTemplateType,
  UpdateChecklistReportInput,
  CarAvailabilityStatus,
  BodyStyle,
} from 'src/api/globalTypes';

import { CreateChecklistReport_createChecklistReport_checklist } from '../../../../../graphql/mutation/__generated__/CreateChecklistReport';
import {
  checklistReportFailure,
  checklistReportStart,
  checklistReportSuccess,
  checklistReportUpdate,
  checklistReportWarningsUpdate,
  setChecklistReportIsCheckedEngine,
  setChecklistReportModalShown,
} from '../actions';
import { ChecklistReportReducer } from '../types';

const initialState: ChecklistReportReducer = {
  id: '',
  car: {
    id: '',
    licensePlate: '',
    make: '',
    model: '',
    isPool: false,
    bodyStyle: BodyStyle.OTHER,
    firstRegistrationDate: null,
    availability: CarAvailabilityStatus.AVAILABLE,
    __typename: 'ChecklistReportCarWithoutVINResult',
  },
  checklistTemplate: {
    id: '',
    name: '',
    checks: [],
    type: ChecklistTemplateType.REGULAR,
    warningMessage: null,
    __typename: 'ChecklistTemplateResult',
  },
  checks: [],
  warnings: [],
  __typename: 'ChecklistInfoResult',
  loading: false,
  error: false,
  isChanged: false,
  isCompleted: false,
  isTermsAndConditionAccepted: false,
  isCheckedEngine: false,
};

export const checklistReportReducer = createReducer(initialState, {
  [checklistReportSuccess.type]: (
    state,
    action: PayloadAction<{ data: CreateChecklistReport_createChecklistReport_checklist }>,
  ) => {
    return {
      ...state,
      ...action.payload.data,
      loading: false,
      error: false,
      isChanged: true,
    };
  },
  [checklistReportStart.type]: state => {
    return {
      ...state,
      loading: true,
      error: false,
    };
  },
  [checklistReportFailure.type]: state => {
    return {
      ...state,
      error: true,
      loading: false,
    };
  },
  [checklistReportUpdate.type]: (state, action: { payload: UpdateChecklistReportInput }) => {
    const { isCompleted, isTermsAndConditionAccepted, checks } = action.payload;

    return {
      ...state,
      isCompleted: Boolean(isCompleted),
      isTermsAndConditionAccepted: Boolean(isTermsAndConditionAccepted),
      isChanged: true,
      checks: Array.from(
        ([...(state.checks || []), ...(checks || [])] as any[])
          .reduce(
            (map, item) => map.set(item.name, merge({}, map.get(item.type) || {}, item)),
            new Map(),
          )
          .values(),
      ),
    };
  },
  [checklistReportWarningsUpdate.type]: (state, action) => {
    return {
      ...state,
      warnings: action.payload.warnings || [],
    };
  },
  [setChecklistReportIsCheckedEngine.type]: (state, action: PayloadAction<boolean>) => {
    return {
      ...state,
      isCheckedEngine: action.payload,
    };
  },
  [setChecklistReportModalShown.type]: (
    state,
    action: PayloadAction<{ name: string; isShown: boolean }>,
  ) => {
    set(state, `modals.${action.payload.name}`, action.payload.isShown);
  },
});
