import React from 'react';

import { DamageOverviewPage } from 'src/apps/DriverOverviewApp/pages/DamageOverviewPage/DamageOverviewPage';

export const DRIVER_OVERVIEW_APP_ROOT_ROUTE = 'driver-dashboard';
export const ROUTES = {
  DAMAGE_OVERVIEW: `/${DRIVER_OVERVIEW_APP_ROOT_ROUTE}/damage-overview`,
};

export const routes = [
  {
    key: ROUTES.DAMAGE_OVERVIEW,
    path: `${ROUTES.DAMAGE_OVERVIEW}`,
    component: DamageOverviewPage,
  },
];
