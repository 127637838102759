import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import {
  setUserSelectedLanguage,
  setUserData,
  setResetPasswordData,
  setChangePasswordData,
} from './actions';
import { ChangePasswordReducerData, ResetPasswordReducerData, UserReducer } from '../../../types';

export const initialState: UserReducer = {
  loading: false,
  error: null,
  role: null,
  data: {
    id: null,
    email: null,
    firstName: null,
    lastName: null,
    displayName: null,
    location: [],
  },
  operations: [],
  configuration: null,
  isUserSelectedLanguage: false,
  resetPassword: {},
  changePassword: {},
};

export const userReducer = createReducer(initialState, {
  [setUserData.type]: (state: UserReducer, action: PayloadAction<Partial<UserReducer>>) => {
    return merge(state, action.payload);
  },
  [setUserSelectedLanguage.type]: (state, action: PayloadAction<boolean>) => {
    state.isUserSelectedLanguage = action.payload;
  },
  [setResetPasswordData.type]: (
    state,
    action: PayloadAction<Partial<ResetPasswordReducerData>>,
  ) => {
    state.resetPassword = { ...state.resetPassword, ...action.payload };
  },
  [setChangePasswordData.type]: (
    state,
    action: PayloadAction<Partial<ChangePasswordReducerData>>,
  ) => {
    state.changePassword = { ...state.changePassword, ...action.payload };
  },
});
