import { ReactComponent as HandbrakeIcon } from 'src/apps/NewDriverApp/assets/icons/px-handbrake-illustration.svg';
import { QuestionOptionIconName } from 'src/apps/NewDriverApp/types';
import lazyWithPreload from 'src/apps/NewDriverApp/utils/lazyWithPreload';

const ABSIcon = lazyWithPreload(() => import(/* webpackChunkName: "ABSIcon" */ './icons/ABSIcon'));
const BatteryIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "BatteryIcon" */ './icons/BatteryIcon'),
);
const BreakSystemIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "BreakSystemIcon" */ './icons/BreakSystemIcon'),
);
const BreakWearIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "BreakWearIcon" */ './icons/BreakWearIcon'),
);
const MotorControlIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "MotorControlIcon" */ './icons/MotorControlIcon'),
);
const OilLevelIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "OilLevelIcon" */ './icons/OilLevelIcon'),
);
const TyrePressureIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "TyrePressureIcon" */ './icons/TyrePressureIcon'),
);
const DifferentialIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "DifferentialIcon" */ './icons/DifferentialIcon'),
);
const SystemMalfunctionIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "SystemMalfunctionIcon" */ './icons/SystemMalfunctionIcon'),
);
const CoolantIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "CoolantIcon" */ './icons/CoolantIcon'),
);
const AirbagIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "AirbagIcon" */ './icons/AirbagIcon'),
);
const StopIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "StopIcon" */ './icons/StopIcon'),
);
const InspectionDueIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "InspectionDueIcon" */ './icons/InspectionDueIcon'),
);
const ADBlueIcon = lazyWithPreload(
  () => import(/* webpackChunkName: "ADBlueIcon" */ './icons/ADBlueIcon'),
);

export const iconConfirmationSet = {
  [QuestionOptionIconName.BREAK_SYSTEM]: HandbrakeIcon,
  [QuestionOptionIconName.ABS]: null,
  [QuestionOptionIconName.BATTERY]: null,
  [QuestionOptionIconName.BREAK_WEAR]: null,
  [QuestionOptionIconName.MOTOR_CONTROL]: null,
  [QuestionOptionIconName.OIL_LEVEL]: null,
  [QuestionOptionIconName.TYRE_PRESSURE]: null,
  [QuestionOptionIconName.DIFFERENTIAL]: null,
  [QuestionOptionIconName.SYSTEM_MALFUNCTION]: null,
  [QuestionOptionIconName.COOLANT]: null,
  [QuestionOptionIconName.AIRBAG]: null,
  [QuestionOptionIconName.STOP]: null,
  [QuestionOptionIconName.INSPECTION_DUE]: null,
  [QuestionOptionIconName.AD_BLUE]: null,
};

export const iconSet = {
  [QuestionOptionIconName.ABS]: ABSIcon,
  [QuestionOptionIconName.BATTERY]: BatteryIcon,
  [QuestionOptionIconName.BREAK_SYSTEM]: BreakSystemIcon,
  [QuestionOptionIconName.BREAK_WEAR]: BreakWearIcon,
  [QuestionOptionIconName.MOTOR_CONTROL]: MotorControlIcon,
  [QuestionOptionIconName.OIL_LEVEL]: OilLevelIcon,
  [QuestionOptionIconName.TYRE_PRESSURE]: TyrePressureIcon,
  [QuestionOptionIconName.DIFFERENTIAL]: DifferentialIcon,
  [QuestionOptionIconName.SYSTEM_MALFUNCTION]: SystemMalfunctionIcon,
  [QuestionOptionIconName.COOLANT]: CoolantIcon,
  [QuestionOptionIconName.AIRBAG]: AirbagIcon,
  [QuestionOptionIconName.STOP]: StopIcon,
  [QuestionOptionIconName.INSPECTION_DUE]: InspectionDueIcon,
  [QuestionOptionIconName.AD_BLUE]: ADBlueIcon,
};
