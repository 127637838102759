import { createReducer } from '@reduxjs/toolkit';

import { AccidentThirdPartyInput } from 'src/api/globalTypes';
import {
  setOtherAccidentPartyData,
  updateOtherAccidentPartyData,
} from 'src/apps/NewDriverApp/flows/fleet/accident/redux/actions';

export interface OtherAccidentPartyReducer {
  otherAccidentPartyInformation: AccidentThirdPartyInput;
}

const initialState: OtherAccidentPartyReducer = {
  otherAccidentPartyInformation: {
    licensePlate: '',
  },
};

export const otherAccidentPartyReducer = createReducer(initialState, {
  [updateOtherAccidentPartyData.type]: (state, { payload }) => {
    state.otherAccidentPartyInformation = payload.data;
  },
  [setOtherAccidentPartyData.type]: (state, { payload }) => {
    state.otherAccidentPartyInformation = {
      ...state.otherAccidentPartyInformation,
      ...payload,
    };
  },
});
