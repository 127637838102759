import { createReducer } from '@reduxjs/toolkit';

import { updateDateAndLocationData } from 'src/apps/NewDriverApp/redux/actions';
import { DateAndLocationReducer } from 'src/apps/NewDriverApp/redux/types/Types';

const initialState: DateAndLocationReducer = {
  datetime: '',
  location: '',
};

export const dateAndLocationReducer = createReducer(initialState, {
  [updateDateAndLocationData.type]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
});
