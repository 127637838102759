import { AppMode } from './apps/NewDriverApp/redux/types/AppState';

function getMode() {
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get('mode');

  if (mode) {
    return mode;
  }

  const appInitParams = sessionStorage.getItem('app_init_params');

  if (appInitParams) {
    try {
      const params = JSON.parse(appInitParams);

      return params.mode;
    } catch (e) {
      // do nothing
    }
  }

  return null;
}

/**
 * This function loads cookie script if query param does not have mode=embedded
 */
export function loadCookieScript() {
  const mode = getMode();

  if (mode === AppMode.EMBEDDED) {
    return;
  }

  // FIXME: (roman) cookie script id should be configurable
  const script = document.createElement('script');
  script.src = '//cdn.cookie-script.com/s/d5e6ce6ceb04eb780803d85b209e75b1.js';
  script.type = 'text/javascript';

  document.body.appendChild(script);
}
