import React from 'react';

import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackIcon } from 'src/img/other-icons/px-nav-arrow-left.svg';

import styles from './HeaderBackButton.module.scss';

interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
}

const HeaderBackButton: React.FC<Props> = ({ children, onClick }) => {
  const navigate = useNavigate();

  const handleClickByDefault = () => {
    return navigate(-1);
  };

  const content = children || <BackIcon />;

  return (
    <button className={styles.container} onClick={onClick || handleClickByDefault}>
      {content}
    </button>
  );
};

export { HeaderBackButton };
