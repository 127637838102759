import React from 'react';

import cx from 'classnames';

import { ReportProgress } from 'src/apps/NewDriverApp/ui-components/ReportProgress';
import Text from 'src/apps/NewDriverApp/ui-components/Text';

import styles from './Header.module.scss';
import { HeaderBackButton } from '../HeaderBackButton';
import { LanguageToggle } from '../LanguageToggle';

interface Props {
  title?: React.ReactNode;
  className?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  withProgress?: boolean;
  bottomAdornment?: React.ReactNode;
  isHeaderCollapsed?: boolean;
}

const Header: React.FC<Props> = ({
  className,
  title,
  withProgress,
  startAdornment = <HeaderBackButton />,
  endAdornment = <LanguageToggle />,
  bottomAdornment,
  isHeaderCollapsed,
}) => {
  return (
    <header className={cx(styles.container, className)}>
      <div className={cx({ [styles.isCollapsed]: isHeaderCollapsed }, styles.content)}>
        <span className={styles.startAdornment}>{startAdornment}</span>
        <Text type="h3" className={styles.title}>
          {title}
        </Text>
        <span className={styles.endAdornment}>{endAdornment}</span>
      </div>
      {withProgress && <ReportProgress classNames={styles.progressBar} />}
      {bottomAdornment && <div>{bottomAdornment}</div>}
    </header>
  );
};

export default Header;
