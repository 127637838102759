import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import set from 'lodash/set';
import unset from 'lodash/unset';

import { getLogger } from 'src/services/app-insights';

import { RootState } from './root-reducer';
import { PersistType, StorageMapper } from './types/Types';

const PERSIST_SERVICE_LOGGER_CONTEXT_IDENTIFIER = 'persist-service';

const appInsights = getLogger(PERSIST_SERVICE_LOGGER_CONTEXT_IDENTIFIER);
export const REDUX_STORAGE_FIELD_NAME = 'redux';

const isEmptyValue = (value: unknown) => {
  if (isObject(value)) {
    return isEmpty(value);
  }

  return !value;
};

type PersistTypeWithoutIgnore = Exclude<PersistType, PersistType.IGNORE>;

export class PersistStorage {
  protected static storages: Record<PersistTypeWithoutIgnore, Storage> = {
    [PersistType.PERSIST]: localStorage,
    [PersistType.SESSION]: sessionStorage,
  };

  protected get cls(): typeof PersistStorage {
    return this.constructor as typeof PersistStorage;
  }

  static getStorageService(type: PersistTypeWithoutIgnore = PersistType.SESSION) {
    return this.storages[type];
  }

  public getStorageService(type: PersistTypeWithoutIgnore = PersistType.SESSION) {
    return this.cls.getStorageService(type);
  }

  private mapper: StorageMapper;

  private stateNamespace: string;

  constructor(mapper: StorageMapper, stateNamespace?: string) {
    this.mapper = mapper;
    this.stateNamespace = stateNamespace || REDUX_STORAGE_FIELD_NAME;
  }

  static reset(type: PersistTypeWithoutIgnore | PersistTypeWithoutIgnore[] = PersistType.SESSION) {
    const storageTypes = Array.isArray(type) ? type : [type];
    return storageTypes.map(storageType => this.getStorageService(storageType).clear());
  }

  public reset(type: PersistTypeWithoutIgnore | PersistTypeWithoutIgnore[] = PersistType.SESSION) {
    return this.cls.reset(type);
  }

  public dehydrate(state: RootState) {
    try {
      const mapEntries = Object.entries(this.mapper);
      const stateCopy = cloneDeep(state);

      mapEntries.forEach(entry => {
        const [propertyPath, storageConfig] = entry;
        const type = storageConfig.type || PersistType.SESSION;
        if (type === PersistType.IGNORE) {
          unset(stateCopy, propertyPath);
          return;
        }

        const pathToSave = storageConfig.path || propertyPath;
        const storage = this.getStorageService(type);
        const dataToSave = get(stateCopy, propertyPath);

        if (isEmptyValue(dataToSave)) {
          storage.removeItem(pathToSave);
        } else {
          const serializedData = JSON.stringify(dataToSave);
          storage.setItem(pathToSave, serializedData);
        }

        unset(stateCopy, propertyPath);
      });

      const storage = this.getStorageService();
      const serializedState = JSON.stringify(stateCopy);
      storage.setItem(this.stateNamespace, serializedState);
    } catch (err) {
      appInsights.trackException({
        exception: err as Error,
        properties: {
          service: 'PersistService.dehydrate',
        },
      });
    }
  }

  public hydrate() {
    try {
      const stateStorage = this.getStorageService();
      const stateFromPersistentStorage = stateStorage.getItem(this.stateNamespace) || '{}';
      const dehydratedState = JSON.parse(stateFromPersistentStorage);

      const mapEntries = Object.entries(this.mapper);

      mapEntries.forEach(entry => {
        const [propertyPath, storageConfig] = entry;
        const type = storageConfig.type || PersistType.SESSION;
        if (type === PersistType.IGNORE) {
          return;
        }

        const pathToExtract = storageConfig.path || propertyPath;
        const storage = this.getStorageService(type);
        const dataToParse = storage.getItem(pathToExtract);

        if (dataToParse) {
          const parsedData = JSON.parse(dataToParse);
          set(dehydratedState, propertyPath, parsedData);
        }
      });

      return dehydratedState;
    } catch (err) {
      appInsights.trackException({
        exception: err as Error,
        properties: {
          service: 'PersistService.hydrate',
        },
      });

      return {};
    }
  }
}
