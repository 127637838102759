import { MAX_ADDITIONAL_PHOTOS, PHOTO_FLOWS } from '../../constants';

export const ROUTE_UPDATE = 'update';
export const ROUTE_DAMAGE_DETAILS = 'damage-details';
export const ROUTE_DAMAGES = 'damages';
export const ROUTE_CONFIRMATION = 'confirmation';

export const ROUTE_DAMAGE_LIST = 'damage-list';
export const ROUTE_CAR_PARTS = 'car-parts';

export const DRIVER_COMMENT_TEXT_AREA_FIELD_NAME = 'driverComment';
export const FLEET_COMMENT_FIELD_NAME = 'fleetComment';
export const DAMAGE_TYPE_FIELD_NAME = 'damageCategories';
export const DAMAGE_MINORITY_FIELD_NAME = 'isMinor';
export const DAMAGE_STATUS_FIELD_NAME = 'status';

export const MAX_COMMENT_LENGTH_CHARACTERS = 1000;

export const REQUIRED_IMAGES_AMOUNT = PHOTO_FLOWS.DAMAGE_PHOTOS.length;

export const TOTAL_IMAGES_AMOUNT = REQUIRED_IMAGES_AMOUNT + MAX_ADDITIONAL_PHOTOS;
